import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {
  AppConfigService,
  DictionaryBaseService,
  DictionaryService,
  GrowlService,
  LoggedUserService,
} from '../../../../bonding_shared/services';
import {DeclarationService} from '../../../../bonding_shared/services/declaration.service';
import {TranslateService} from '@ngx-translate/core';
import {
  AbstractDeclarationEntryDto,
  DeclarationDto,
  DeclarationEntryNNDto,
  DictionaryBaseDto,
} from '../../../../bonding_shared';
import {
  CurrencyTable,
  DeclarationEntryNNRole,
  GlobalConditionsOfInsurance,
  PolicyContractType,
} from '../../../../bonding_shared/model/dictionary-ids';

@Directive()
export abstract class DeclarationAbstractSection {
  protected constructor(
    protected dictService: DictionaryBaseService,
    protected dictGlobalService: DictionaryService,
    protected growlService: GrowlService,
    protected appService: AppConfigService,
    protected loggedUserService: LoggedUserService,
    protected translateService: TranslateService,
    protected declarationService: DeclarationService
  ) {}

  readonly CurrencyTable = CurrencyTable;
  readonly PolicyContractType = PolicyContractType;
  readonly GlobalConditionsOfInsurance = GlobalConditionsOfInsurance;

  GENERAL_NN = DeclarationEntryNNRole.GENERAL_NN;
  DOMESTIC_NN = DeclarationEntryNNRole.DOMESTIC_NN;
  EXPORT_NN = DeclarationEntryNNRole.EXPORT_NN;

  @Input() showErrors = false;
  @Input() preview;

  @Input() set blockSection(block: boolean) {
    this.preview = block;
  }

  @Output() declarationEntrySelect = new EventEmitter<AbstractDeclarationEntryDto>();
  @Output() declarationEntryUnselect = new EventEmitter<void>();

  blockEntriesEdition = false;
  blockGeneralNNEntriesEdition = false;
  blockDomesticNNEntriesEdition = false;
  blockExportNNEntriesEdition = false;

  selectedCurrency: DictionaryBaseDto; // holds currently edited declaration entry currency
  currencyTableId: number;
  maxNumberOfNNEntries: number;
  multiCurrencyDeclaration: boolean;
  baseNNCurrency: DictionaryBaseDto;
  validNNRoles = [];

  valueGeneralNNEditableMap: Map<number, boolean>;
  valueDomesticNNEditableMap: Map<number, boolean>;
  valueExportNNEditableMap: Map<number, boolean>;

  declaration: DeclarationDto;

  protected setDeclaration(declarationDto: DeclarationDto) {
    this.declaration = declarationDto;
    this.setCurrencyTable();
    this.updateEditableMaps();
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  private removalRecurringCurrencies(
    decNNEntry: DeclarationEntryNNDto,
    allNNList: DeclarationEntryNNDto[],
    roleId: DeclarationEntryNNRole
  ) {
    decNNEntry.role = <DictionaryBaseDto>{id: roleId};
    decNNEntry.version = decNNEntry.version ? decNNEntry.version : 0;
    const sameEntriesCount = allNNList.filter(
      (entry) => entry.currency && decNNEntry.currency && entry.currency.id === decNNEntry.currency.id
    ).length;

    if (sameEntriesCount > 1) {
      // prevents insertion of two entries for the same currency
      this.growlService.error('Declaration entry NN with currency ' + decNNEntry.currency.name + ' already provided!');
      // in such case currently edited entry currency will be set to undefined
      decNNEntry.currency = undefined;
    }
  }

  isValidNNRole(nnRoleId: DeclarationEntryNNRole): boolean {
    return this.validNNRoles.findIndex((id) => id === nnRoleId) !== -1;
  }

  abstract getGeneralNNs(): DeclarationEntryNNDto[];
  abstract getDomesticNNs(): DeclarationEntryNNDto[];
  abstract getExportNNs(): DeclarationEntryNNDto[];

  private addNNPossible(nnArray: DeclarationEntryNNDto[]): boolean {
    return (
      !this.preview &&
      !this.blockEntriesEdition &&
      !this.blockGeneralNNEntriesEdition &&
      !this.blockDomesticNNEntriesEdition &&
      !this.blockExportNNEntriesEdition &&
      this.declaration &&
      this.declaration.nn &&
      (!this.portal || !this.declaration.amendment) &&
      (nnArray === undefined || nnArray.length < this.maxNumberOfNNEntries)
    );
  }
  addGeneralNNPossible(): boolean {
    return this.addNNPossible(this.getGeneralNNs());
  }
  addDomesticNNPossible(): boolean {
    return this.addNNPossible(this.getDomesticNNs());
  }
  addExportNNPossible(): boolean {
    return this.addNNPossible(this.getExportNNs());
  }

  onAddGeneralNN(decNNEntry: DeclarationEntryNNDto) {
    decNNEntry.currency = this.baseNNCurrency;
    this.declarationEntrySelect.emit(decNNEntry);
    this.blockEntriesEdition = true;
    this.blockDomesticNNEntriesEdition = true;
    this.blockExportNNEntriesEdition = true;
  }
  onAddDomesticNN(decNNEntry: DeclarationEntryNNDto) {
    decNNEntry.currency = this.baseNNCurrency;
    this.declarationEntrySelect.emit(decNNEntry);
    this.blockEntriesEdition = true;
    this.blockGeneralNNEntriesEdition = true;
    this.blockExportNNEntriesEdition = true;
  }
  onAddExportNN(decNNEntry: DeclarationEntryNNDto) {
    decNNEntry.currency = this.baseNNCurrency;
    this.declarationEntrySelect.emit(decNNEntry);
    this.blockEntriesEdition = true;
    this.blockGeneralNNEntriesEdition = true;
    this.blockDomesticNNEntriesEdition = true;
  }

  private onDoneNNsEdition(
    decNNEntry: DeclarationEntryNNDto,
    nnArray: DeclarationEntryNNDto[],
    nnRole: DeclarationEntryNNRole
  ) {
    this.removalRecurringCurrencies(decNNEntry, nnArray, nnRole);
    this.declarationEntryUnselect.emit();
    this.blockEntriesEdition = false;
    this.blockDomesticNNEntriesEdition = false;
    this.blockExportNNEntriesEdition = false;
    this.blockGeneralNNEntriesEdition = false;
  }
  onDoneGeneralNNs(decNNEntry: DeclarationEntryNNDto) {
    this.onDoneNNsEdition(decNNEntry, this.getGeneralNNs(), DeclarationEntryNNRole.GENERAL_NN);
  }
  onDoneDomesticNNs(decNNEntry: DeclarationEntryNNDto) {
    this.onDoneNNsEdition(decNNEntry, this.getDomesticNNs(), DeclarationEntryNNRole.DOMESTIC_NN);
  }
  onDoneExportNNs(decNNEntry: DeclarationEntryNNDto) {
    this.onDoneNNsEdition(decNNEntry, this.getExportNNs(), DeclarationEntryNNRole.EXPORT_NN);
  }

  onDeleteNN(decNNEntry: DeclarationEntryNNDto) {
    this.declarationEntrySelect.emit(decNNEntry);
    this.declarationEntryUnselect.emit();
  }

  onSelectDeclarationNNEntry(entry: DeclarationEntryNNDto) {
    if (!this.preview) {
      if (entry.role.id === DeclarationEntryNNRole.DOMESTIC_NN && !this.blockDomesticNNEntriesEdition) {
        this.declarationEntrySelect.emit(entry);
        this.selectedCurrency = entry ? entry.currency : undefined;
        this.blockEntriesEdition = true;
        this.blockGeneralNNEntriesEdition = true;
        this.blockExportNNEntriesEdition = true;
      }
      if (entry.role.id === DeclarationEntryNNRole.EXPORT_NN && !this.blockExportNNEntriesEdition) {
        this.declarationEntrySelect.emit(entry);
        this.selectedCurrency = entry ? entry.currency : undefined;
        this.blockEntriesEdition = true;
        this.blockGeneralNNEntriesEdition = true;
        this.blockDomesticNNEntriesEdition = true;
      }
      if (entry.role.id === DeclarationEntryNNRole.GENERAL_NN && !this.blockGeneralNNEntriesEdition) {
        this.declarationEntrySelect.emit(entry);
        this.selectedCurrency = entry ? entry.currency : undefined;
        this.blockEntriesEdition = true;
        this.blockDomesticNNEntriesEdition = true;
        this.blockExportNNEntriesEdition = true;
      }
    }
  }

  tooltipGeneralNNVisible(decEntryNN: DeclarationEntryNNDto): boolean {
    return !this.declaration?.id && !this.rowGeneralNNValueEditable(decEntryNN);
  }
  tooltipDomesticNNVisible(decEntryNN: DeclarationEntryNNDto): boolean {
    return !this.declaration?.id && !this.rowDomesticNNValueEditable(decEntryNN);
  }
  tooltipExportNNVisible(decEntryNN: DeclarationEntryNNDto): boolean {
    return !this.declaration?.id && !this.rowExportNNValueEditable(decEntryNN);
  }

  protected updateEditableMaps() {
    this.valueGeneralNNEditableMap = new Map();
    this.valueDomesticNNEditableMap = new Map();
    this.valueExportNNEditableMap = new Map();
    if (this.declaration && this.declaration.nn) {
      if (this.isValidNNRole(this.GENERAL_NN) && this.getGeneralNNs()) {
        this.getGeneralNNs().forEach((decEntryNN) => {
          this.valueGeneralNNEditableMap.set(decEntryNN.currency.id, this.valueNNEditable(decEntryNN));
        });
      }
      if (this.isValidNNRole(this.DOMESTIC_NN) && this.getDomesticNNs()) {
        this.getDomesticNNs().forEach((decEntryNN) => {
          this.valueDomesticNNEditableMap.set(decEntryNN.currency.id, this.valueNNEditable(decEntryNN));
        });
      }
      if (this.isValidNNRole(this.EXPORT_NN) && this.getExportNNs()) {
        this.getExportNNs().forEach((decEntryNN) => {
          this.valueExportNNEditableMap.set(decEntryNN.currency.id, this.valueNNEditable(decEntryNN));
        });
      }
    }
  }

  tooltipNN(decEntryNN: DeclarationEntryNNDto): string {
    return this.editionNNErrorMsg(decEntryNN);
  }

  anyRowElementGeneralNNEditable(decEntryNN: DeclarationEntryNNDto): boolean {
    return this.rowGeneralNNValueEditable(decEntryNN);
  }
  anyRowElementDomesticNNEditable(decEntryNN: DeclarationEntryNNDto): boolean {
    return this.rowDomesticNNValueEditable(decEntryNN);
  }
  anyRowElementExportNNEditable(decEntryNN: DeclarationEntryNNDto): boolean {
    return this.rowExportNNValueEditable(decEntryNN);
  }

  rowGeneralNNValueEditable(decEntryNN: DeclarationEntryNNDto): boolean {
    if (!this.portal || decEntryNN.duplicatedEntryTransientFlag) {
      return true;
    }
    if (this.declaration.amendment) {
      return !!decEntryNN.value && this.valueGeneralNNEditableMap.get(decEntryNN.currency.id);
    } else {
      return true;
    }
  }
  rowDomesticNNValueEditable(decEntryNN: DeclarationEntryNNDto): boolean {
    if (!this.portal || decEntryNN.duplicatedEntryTransientFlag) {
      return true;
    }
    if (this.declaration.amendment) {
      return !!decEntryNN.value && this.valueDomesticNNEditableMap.get(decEntryNN.currency.id);
    } else {
      return true;
    }
  }
  rowExportNNValueEditable(decEntryNN: DeclarationEntryNNDto): boolean {
    if (!this.portal || decEntryNN.duplicatedEntryTransientFlag) {
      return true;
    }
    if (this.declaration.amendment) {
      // !(zero or null value) AND decEntry value was initially editable
      return !!decEntryNN.value && this.valueExportNNEditableMap.get(decEntryNN.currency.id);
    } else {
      return true;
    }
  }

  protected valueNNEditable(decEntryNN: DeclarationEntryNNDto): boolean {
    return !this.editionNNErrorMsg(decEntryNN);
  }

  cancelNNRowEdition() {
    this.declarationEntryUnselect.emit();
    this.blockEntriesEdition = false;
    this.blockGeneralNNEntriesEdition = false;
    this.blockDomesticNNEntriesEdition = false;
    this.blockExportNNEntriesEdition = false;
  }

  currencyNNRequired(decEntryNN: DeclarationEntryNNDto): boolean {
    return decEntryNN.duplicatedEntryTransientFlag !== true;
  }

  protected currencyNNEditable(decEntry: DeclarationEntryNNDto): boolean {
    return (
      this.multiCurrencyDeclaration &&
      (decEntry.duplicatedEntryTransientFlag ||
        !this.portal ||
        (this.portal && (this.declaration === undefined || (this.declaration && !this.declaration.amendment))))
    );
  }

  private editionNNErrorMsg(decEntryNN: DeclarationEntryNNDto): string {
    let errorMsg;
    const editable =
      decEntryNN.duplicatedEntryTransientFlag ||
      !this.portal ||
      (this.portal &&
        this.declaration !== undefined &&
        ((!this.declaration.amendment && !this.preview) || (this.declaration.amendment && !!decEntryNN.value)));
    if (!editable) {
      if (!decEntryNN.value) {
        errorMsg = 'declaration.section.emptyNNValue';
      }
    }
    return errorMsg;
  }

  get currencyTable(): number {
    return this.currencyTableId;
  }
  protected setCurrencyTable() {
    const productType = this.declaration && this.declaration.reportingPeriod.policyContractData.productType;
    this.currencyTableId = this.appService.kuke ? CurrencyTable.PLN : CurrencyTable.A;
    this.multiCurrencyDeclaration = false;

    if (this.declaration && this.declaration.reportingPeriod.policyContractData.productType) {
      const isEcgType = [PolicyContractType.GLOBAL_RISK, PolicyContractType.SELECTIVE_RISK].includes(productType.id);
      const spFkFe = [
        PolicyContractType.KUKE_KOM_SP,
        PolicyContractType.KUKE_KOM_FE,
        PolicyContractType.KUKE_KOM_FK,
      ].includes(productType.id);
      const opNotGci2018 =
        PolicyContractType.KUKE_KOM_OP === productType.id &&
        this.declaration.reportingPeriod.policyContractData.gci.id !== GlobalConditionsOfInsurance.KUKE_OP_2018_12;
      if (isEcgType || spFkFe || opNotGci2018) {
        this.currencyTableId = CurrencyTable.A;
        this.multiCurrencyDeclaration = true;
      }
    }
    this.setNNEntriesCurrencyParams();
  }
  private setNNEntriesCurrencyParams() {
    this.dictGlobalService.getDictionaryEntry('CurrencyTable', this.currencyTableId).subscribe((curTable) => {
      this.maxNumberOfNNEntries = curTable.relatedDictionaries['Currency'].length;
      if (this.maxNumberOfNNEntries === 1) {
        this.baseNNCurrency = curTable.relatedDictionaries['Currency'][0];
      } else {
        if (!this.appService.kuke) {
          this.baseNNCurrency = undefined;
        }
      }
    });
  }
}
