import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {
  DictionaryBaseDto,
  DictionaryDto,
  PolicyClauseDto,
  PolicyClauseParentDto,
  PolicyContractVersionBaseDto,
  PolicyContractVersionDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferSimpleDto,
} from '../../../../bonding_shared/model';
import {PolicyClauseService} from '../../../../bonding_shared/services/policy-clause.service';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {
  GrowlService,
  InquiryService,
  PolicyContractVersionService,
  RouterService,
} from '../../../../bonding_shared/services';
import {PolicyClauseCategory, PolicyElementaryRight} from '../../../../bonding_shared/model/dictionary-ids';
import {Observable} from 'rxjs';
import {StringUtils} from '../../../../bonding_shared/utils';
import {ConfirmDialogComponent} from '../../../../bonding_shared/components/confirm-dialog';

@Component({
  selector: 'policyclause-details',
  templateUrl: './policyclause-details.component.pug',
})
export class PolicyClauseDetailsComponent extends DetailsView implements OnInit {
  clause: PolicyClauseDto;
  newClausePolicy: PolicyContractVersionDto;
  newClauseOffer: PolicyInquiryOfferDto;
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  @ViewChild('deleteConfirm', {static: true}) deleteConfirmDialog: ConfirmDialogComponent;
  clausesForm = new UntypedFormGroup({});
  form = new UntypedFormGroup({});
  PolicyClauseCategory = PolicyClauseCategory;
  clausesTypes: DictionaryBaseDto[] = null;
  typeInitialized = true;
  viewParams: PolicyClauseViewParams;
  clauseFieldsObs: Observable<DictionaryDto[]>;

  readonly PolicyElementaryRight = PolicyElementaryRight;

  constructor(
    private _route: ActivatedRoute,
    private policyClauseService: PolicyClauseService,
    private policyVersionService: PolicyContractVersionService,
    private offerService: InquiryService,
    public router: RouterService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.newVersionButton.name = 'policyClause.details.newClause';
  }

  ngOnInit() {
    this.clearErrors();
    this.clause = <PolicyClauseDto>{category: <DictionaryBaseDto>{id: PolicyClauseCategory.STANDARD, name: ''}};
    this.form = this.ngForm.form;
    this.form.addControl('clausesForm', this.clausesForm);
    this.clausesForm.controls = {};
    this.commonInit<PolicyClauseViewParams>(this._route);
  }

  initViewWithId(id: number) {
    this.policyClauseService.getById<PolicyClauseDto>(id).subscribe({
      next: (c) => this.setClause(c),
      error: () => (this.objectNotFound = true),
    });
  }

  initViewWithCustomParams(q: PolicyClauseViewParams) {
    this.viewParams = q;
    this.setClauseParent();
  }

  setClauseParent() {
    if (this.viewParams.offerId) {
      this.offerService.getOffer(+this.viewParams.offerId).subscribe((o) => {
        this.newClauseOffer = o.offer;
        this.initializeClause();
      });
    } else if (this.viewParams.policyVersionId) {
      this.policyVersionService.getPolicyContractVersion(+this.viewParams.policyVersionId).subscribe((p) => {
        this.newClausePolicy = p;
        this.initializeClause();
      });
    }
  }

  onCancel() {
    if (!this.clause.id) {
      this.clause.type = undefined;
    } else {
      this.initViewWithId(this.clause.id);
    }
  }

  setClauseTypes() {
    if (!this.gci) {
      this.addFrontendError('policyClause.details.missingGciWarning');
      return;
    }
    this.clearErrors();
    this.policyClauseService.getClauseTypes(this.gci.id).subscribe((types) => (this.clausesTypes = types));
  }

  get policy(): PolicyContractVersionBaseDto {
    return this.clause && this.clause.id ? this.clause.policyVersion : this.newClausePolicy;
  }

  get offer(): PolicyInquiryOfferSimpleDto {
    return this.clause && this.clause.id ? this.clause.policyOffer : this.newClauseOffer;
  }

  get clauseParent(): PolicyClauseParentDto {
    return this.policy ? this.policy : this.offer;
  }

  get gci() {
    return this.clauseParent?.globalConditionsOfInsurance;
  }

  initializeClause() {
    this.policyClauseService
      .initialVersion(
        +this.viewParams.policyVersionId,
        +this.viewParams.offerId,
        this.clause.category.id,
        this.clause.type ? this.clause.type.id : undefined
      )
      .subscribe({
        next: (c) => this.setClause(c),
        error: (error) => {
          this.handleServerError(error);
          this.handleButtons();
        },
      });
  }

  categorySelected(category: DictionaryDto) {
    if (category.id === PolicyClauseCategory.ADDITIONAL) {
      this.typeSelected(null);
    }
  }

  typeSelected(type: DictionaryDto) {
    if (!type || typeof type === 'object') {
      this.clausesForm.controls = {};
      this.clause.type = type;
      this.typeInitialized = false;
      this.setFieldsObs();
      this.initializeClause();
    }
  }

  setClause(c: PolicyClauseDto) {
    this.clause = c;
    this.setClauseTypes();
    this.setFieldsObs();
    this.typeInitialized = true;
    this.handleButtons();
    this.updateErrors(c.warnings);
  }

  onCloseEditorModeChange(event: any) {}

  onSave() {
    console.log('onSave clause::  form = ', this.form);
    // console.log('save:', this.clause);
    this.clearErrors();
    this.inProgress = true;
    if (this.formValidates() && this.editable) {
      this.policyClauseService.save(this.clause).subscribe({
        next: (tp) => {
          this.afterSave(tp);
        },
        error: (error) => this.handleServerError(error),
      });
    }
  }

  afterSave(c: PolicyClauseDto) {
    this.clause = c;
    this.showSavedMsg();
    this.clearErrors();
    this.updateErrors(c.warnings);
    console.log('policy clause saved:', c);
    this.router.toPolicyClauseDetails(c.id);
  }

  isAdditional() {
    return this.clause.category && this.clause.category.id === PolicyClauseCategory.ADDITIONAL;
  }

  handleButtons() {
    this.saveButton.hidden =
      !this.clause ||
      !this.clause.category ||
      (!this.isAdditional() && (!this.clause.type || !this.typeInitialized)) ||
      !this.editable;
    this.cancelButton.hidden = !this.clause || !this.editable;
    this.deleteButton.hidden = !this.clause || !this.clause.id || !this.clauseParent.clauseRemovingPossible;
    this.newVersionButton.hidden = !this.clause || !this.clause.id || !this.clauseParent.clauseCreationPossible;
  }

  onDelete() {
    if (this.clauseParent.clauseRemovingPossible) {
      this.deleteConfirmDialog.openAndExecuteOnConfirm(
        'policyClause.details.delPopup.title',
        'policyClause.details.delPopup.msg',
        this.doDelete.bind(this)
      );
    }
  }

  doDelete() {
    this.policyClauseService.deleteObj(this.clause.id).subscribe({
      next: () => this.toClauseParent(),
      error: (error) => this.handleServerError(error),
    });
  }

  toClauseParent() {
    if (this.policy) {
      this.router.toPolicyContractDetails(this.policy.id);
    } else {
      this.router.toKukeInquiryOfferDetails(this.offer.id);
    }
  }

  setFieldsObs() {
    this.clauseFieldsObs =
      (this.policy || this.offer) && this.clause.type && this.clause.type.id
        ? this.policyClauseService.getFields(this.policy, this.offer, this.clause.type.id)
        : undefined;
  }

  get clauseName() {
    if (this.clause.type) {
      return this.clause.type.name;
    } else {
      return this.clause.name;
    }
  }

  get editable() {
    return this.clauseParent?.clauseEditionPossible;
  }

  onCreateNewVersion() {
    this.typeInitialized = false;
    if (this.offer) {
      this.router.toPolicyOfferClauseNew(this.offer.id);
    } else {
      this.router.toPolicyClauseNew(this.policy.id);
    }
  }

  typeName(d: DictionaryBaseDto): string {
    return d ? StringUtils.shortCode(d.code) + ' - ' + d.name : '';
  }
}

export class PolicyClauseViewParams {
  // string when reading
  policyVersionId: string | number;
  offerId: string | number;
}
