import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {DeclarationCriteriaComponent} from './components/declaration-criteria.component';
import {DeclarationSectionComponent} from './components/declaration-section.component';
import {DeclarationSingleSectionComponent} from './components/declaration-single-section.component';
import {DeclarationHistorySectionComponent} from './components/declaration-history-section.component';

@NgModule({
  imports: [RouterModule, BrowserModule, SharedComponentsModule, FormsModule, ReactiveFormsModule, CollapseModule],
  declarations: [
    DeclarationSectionComponent,
    DeclarationSingleSectionComponent,
    DeclarationHistorySectionComponent,
    DeclarationCriteriaComponent,
  ],
  exports: [
    DeclarationSingleSectionComponent,
    DeclarationSectionComponent,
    DeclarationHistorySectionComponent,
    DeclarationCriteriaComponent,
  ],
  providers: [],
})
export class DeclarationSharedModule {}
