import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {PolicyCessionPreviewComponent} from './policy-cession-preview-component';
import {PolicyCessionListComponent} from './components/shared/policy-cession-list.component';
import {PolicyCessionDetailsComponent} from './policy-cession-details.component';
import {PolicyCessionInfoComponent} from './components/shared/policy-cession-info.component';
import {PolicyCessionVersionService} from '../../bonding_shared/services/policy-cession-version.service';
import {DocumentModule} from '../document/document.module';
import {PolicyCessionVersionListComponent} from './components/shared/policy-cession-version-list.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    CompanySharedModule,
    BsDropdownModule,
    DocumentModule,
  ],
  declarations: [
    PolicyCessionListComponent,
    PolicyCessionPreviewComponent,
    PolicyCessionDetailsComponent,
    PolicyCessionInfoComponent,
    PolicyCessionVersionListComponent,
  ],
  exports: [PolicyCessionInfoComponent, PolicyCessionListComponent, PolicyCessionDetailsComponent],
  providers: [PolicyCessionVersionService],
})
export class PolicyCessionModule {}
