import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  BrokerContractVersionDto,
  BusinessUnitDto,
  BusinessUnitIdDto,
  BusObjProductLimitListRefDto,
  CompanySimpleDto,
  ContractVersionDto,
  Criteria,
  DictionaryBaseDto,
  HasNetAndTaxAmount,
  InvoiceAmountDto,
  InvoiceAmountTaxDto,
  InvoiceDto,
  InvoiceItemCriteriaDto,
  InvoiceItemDto,
  InvoiceItemInvoiceCriteriaDto,
  InvoiceItemSimpleDto,
  InvoiceNumberingBagBaseDto,
  InvoiceNumberingBagDto,
  InvoicePaymentDto,
  InvoicePositionDto,
  InvoicePositionTaxDto,
  PolicyContractVersionDto,
  SearchResult,
  TaxIfc,
} from '../../../bonding_shared/model/dtos';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {InvoiceService} from '../../../bonding_shared/services/invoice.service';
import {CellChangeEvent} from '../../../bonding_shared/components/aku-table/column.component';
import {
  AppProperty,
  BusinessObjectType,
  ElementaryRight,
  InvoiceCategory,
  InvoiceItemSubtype,
  InvoicePaymentStatus,
  InvoiceStatus,
  InvoiceType,
  TaxPayer,
} from '../../../bonding_shared/model/dictionary-ids';
import {InvoiceItemGuiService} from '../../invoice-item/services/invoice-item-gui.service';
import {InvoiceItemService} from '../../../bonding_shared/services/invoice-item.service';
import {InvoiceCoreService} from '../../../bonding_shared/services/invoice-core.service';
import {InvoiceItemListComponent} from '../../invoice-item/components/invoice-item-list.component';
import {BusinessObjectService} from '../../../bonding_shared/services/business-object.service';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {NumberUtils} from '../../../bonding_shared/utils/number-utils';
import {GrowlService} from '../../../bonding_shared/services/growl/growl.service';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';
import {DocumentListComponent} from '../../document/components';
import {DictionaryUtils} from '../../../bonding_shared/utils/dictionary-utils';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {BusinessObjectUtils} from '../../../bonding_shared/utils/business-object-utils';
import {DtoClass} from '../../../bonding_shared/model/classes';
import {DateUtils} from '../../../bonding_shared/utils/date-utils';
import {InvoiceUtils} from '../../../bonding_shared/utils/invoice-utils';
import {StringUtils} from '../../../bonding_shared/utils';
import {Mapper} from '../../../bonding_shared/utils/mapper';
import {BackendError} from '../../../bonding_shared/model';
import {AppConfigService, PropertyService} from '../../../bonding_shared';
import {InvoiceNumberingBagService} from '../../../bonding_shared/services/invoice-numbering-bag.service';

@Component({
  selector: 'invoice-data',
  templateUrl: 'invoice-data.component.html',
})
export class InvoiceDataComponent implements OnInit {
  _invoiceItemListComponent: InvoiceItemListComponent;
  _documentListComponent: DocumentListComponent;

  @ViewChild(InvoiceItemListComponent)
  set invoiceItemListComponent(invoiceItemListComponent: InvoiceItemListComponent) {
    this._invoiceItemListComponent = invoiceItemListComponent;
  }

  get invoiceItemListComponent(): InvoiceItemListComponent {
    return this._invoiceItemListComponent;
  }

  @ViewChild(DocumentListComponent)
  set documentListComponent(documentListComponent: DocumentListComponent) {
    this._documentListComponent = documentListComponent;
  }

  get documentListComponent(): DocumentListComponent {
    return this._documentListComponent;
  }

  @Input() categoryId: InvoiceCategory;
  @Input() disabled: any;
  _invoice: InvoiceDto;
  @Input() header: string;
  @Input() invoiceAutomatically: boolean;

  @Output() logError = new EventEmitter<BackendError>();
  @Output() initialisationFinished = new EventEmitter<BackendError>();

  listEmitters: ListEmitters = new ListEmitters();

  invoiceAutomaticallyFinished = false;

  showInstallments: boolean;
  showSubtype: boolean;

  invoiceItemDataProvider: SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemSimpleDto>;

  chosenInvoicePosition: InvoicePositionDto;
  chosenInvoicePositionSelect: InvoicePositionDto;
  // TODO to be removed, related functionality is no longer needed here
  chosenInvoiceItem: InvoiceItemSimpleDto;
  form: UntypedFormGroup;

  public errorMessage: string;
  showErrors = false;

  readonly InvoiceType = InvoiceType;
  readonly InvoiceCategory = InvoiceCategory;
  readonly AppProperty = AppProperty;
  readonly ElementaryRight = ElementaryRight;

  protected taxCategoryMap: {[index: string]: DictionaryBaseDto} = {};
  appProperties: {[index: string]: any};

  invoiceCriteria: InvoiceItemInvoiceCriteriaDto;
  sourceInvoicePaid: boolean;
  invoiceItems: InvoiceItemSimpleDto[] = [];
  invoiceItemTypesStr: string;
  installmentsStr: string;
  correctionReasons: DictionaryBaseDto[];
  filteredPayments: InvoicePaymentDto[];

  private waitingForRelatedContractVersion = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public router: RouterService,
    private invoiceService: InvoiceService,
    private invoiceNumberingBagService: InvoiceNumberingBagService,
    private invoiceCoreService: InvoiceCoreService,
    private businessObjectService: BusinessObjectService,
    private invoiceItemGuiService: InvoiceItemGuiService,
    private invoiceItemService: InvoiceItemService,
    protected growlService: GrowlService,
    private loggedUserService: LoggedUserService,
    public appService: AppConfigService,
    private propertyService: PropertyService
  ) {
    this.listEmitters.selectorNameList = ['Client'];
    this.listEmitters.initializeSelectorEmitters(true);
  }

  ngOnInit() {
    this.invoiceItemDataProvider = this.invoiceItemGuiService.trasnsferDataProvider;
    this.loadAppProperties();
    this.searchInvoiceItemsWithServiceCriteriaAndAddToPositions();
    if (this.categoryId === InvoiceCategory.BROKER) {
      this.sourceInvoicePaid = true;
    }
  }

  private loadAppProperties() {
    this.appProperties = this.propertyService.properties;
  }

  @ViewChild(NgForm, {static: false})
  set parentForm(ngForm: NgForm) {
    if (ngForm) {
      this.form = ngForm.form;
    }
  }

  get category() {
    return InvoiceCategory[this.categoryId];
  }

  get invoice(): InvoiceDto {
    return this._invoice;
  }

  public get showIntermediaryCollectsPremium(): boolean {
    return (
      this.appService.credendo &&
      this.invoice.contractLink &&
      this.invoice.contractLink.linkType &&
      this.invoice.contractLink.linkType.id === BusinessObjectType.POLICY
    );
  }

  public sourceInvoicePaidChanged(sourceInvoicePaid: boolean) {
    this.sourceInvoicePaid = sourceInvoicePaid;
    this.invoiceItemListComponent.sourceInvoicePaid = sourceInvoicePaid;
    this.invoiceItemListComponent.search();
  }

  intermediaryCollectionDateChanged(intermediaryCollectsPremium: boolean) {
    if (!intermediaryCollectsPremium) {
      this.invoice.intermediaryCollectionDate = undefined;
    }
  }

  @Input() set invoice(input: InvoiceDto) {
    this._invoice = input;
    this.filteredPayments = this.filterPayments(this._invoice.payments);
    this.chosenInvoicePosition = null;
    this.createTaxCategoryMap();
    if (this.correctionReasonVisible) {
      this.loadCorrectionReasons();
    }

    if (this._invoice.invoicePositions) {
      this.invoiceItems = this._invoice.invoicePositions
        .map((p) => p.invoiceItems)
        .reduce((p1, p2) => p1.concat(p2), []);
      this.recalculateInvoiceItemType();
      this.recalculateInstallmentsCountStr();
    }
    this.invoiceCriteria = this.createInvoiceCriteria(input);

    if (this.invoiceItemListComponent) {
      this.invoiceItemListComponent.search();
    }
    this.showInstallments = !DictionaryUtils.in(
      this._invoice.subtype,
      InvoiceItemSubtype.INSURANCE_PREMIUM,
      InvoiceItemSubtype.PREMIUM_DOMESTIC_NN,
      InvoiceItemSubtype.PREMIUM_EXPORT_NN
    );
    this.showSubtype = this.showInstallments;
  }

  private filterPayments(payments: InvoicePaymentDto[]): InvoicePaymentDto[] {
    if (!payments) {
      return undefined;
    }
    return payments.filter((p) => p.payment);
  }

  private createInvoiceCriteria(invoice: InvoiceDto): InvoiceItemInvoiceCriteriaDto {
    const criteria = <InvoiceItemInvoiceCriteriaDto>{
      inInvoice: false,
    };
    if (invoice.id) {
      criteria.invoiceIds = <Criteria<number[]>>{
        negation: true,
        value: [invoice.id],
      };
    }
    return criteria;
  }

  get taxCategories(): DictionaryBaseDto[] {
    return Object.values(this.taxCategoryMap);
  }

  public editionBlocked(allowServiceEdition = true) {
    return (
      !(this._invoice && this._invoice.status.id === InvoiceStatus.DRAFT) &&
      !(allowServiceEdition && this.loggedUserService.hasRight(ElementaryRight.INVOICE_SERVICE_EDITION))
    );
  }

  public serviceEditionInDraft() {
    return (
      this._invoice &&
      this._invoice.status.id === InvoiceStatus.DRAFT &&
      this.loggedUserService.hasRight(ElementaryRight.INVOICE_SERVICE_EDITION)
    );
  }

  public get numberingBagEditionBlocked(): boolean {
    return !!this.invoice.number;
  }

  searchInvoiceItemsWithServiceCriteriaAndAddToPositions() {
    if (this.invoiceAutomatically) {
      console.log('invoiceWithServiceCriteria');
      this.invoiceItemDataProvider = new SearchDataProvider<InvoiceItemCriteriaDto, InvoiceItemSimpleDto>(
        this.invoiceItemService
      );
      this.invoiceItemDataProvider.textSearch = false;
      this.invoiceItemDataProvider.searchCriteria.criteria =
        this.invoiceItemGuiService.trasnsferDataProvider.searchCriteria.criteria;

      this.invoiceItemDataProvider.searchAllInLoop(undefined).subscribe(
        (searchResult) => {
          const invoiceItemsForAutomaticInvoicing = (<SearchResult<InvoiceItemSimpleDto>>searchResult).result;
          console.log('automatic invoicing has ' + invoiceItemsForAutomaticInvoicing.length + ' items.');
          this.invoiceAll(invoiceItemsForAutomaticInvoicing);
          this.invoiceAutomaticallyFinished = true;
          this.initialisationFinished.emit();
        },
        (error) => {
          console.log('Error in invoice items search for the items to be invoiced.');
          this.logError.emit(error);
        }
      );
    }
  }

  onSelectInvoicePosition(invoicePosition: InvoicePositionDto) {
    // console.log('InvoicePosition chosen, id: ' + invoicePosition.id);
    this.chosenInvoicePosition = invoicePosition;
  }

  onSelectInvoiceItem(invoiceItem: InvoiceItemSimpleDto) {
    // console.log('InvoiceItem chosen, id: ' + invoiceItem.id);
    this.chosenInvoiceItem = invoiceItem;
  }

  invoiceAll(invoiceItems: InvoiceItemSimpleDto[]) {
    console.log('Invoice all');
    console.log('invoiceItems length: ' + invoiceItems.length);

    let warn = false;

    for (const invoiceItem of invoiceItems) {
      this.initInvoiceFromItem(invoiceItem);
      if (this.canAddInvoiceItem(invoiceItem)) {
        if (this.addInvoiceItem(invoiceItem)) {
          this.invoiceItems.push(invoiceItem);
        }
      } else {
        warn = true;
      }
    }

    if (warn) {
      this.growlService.warning('invoice.shared.data.invoiceAllWarn');
    }

    this.recalculateInvoice();

    if (this.invoiceItemListComponent) {
      this.invoiceItemListComponent.search();
    }
  }

  onAddInvoiceItem(invoiceItem: InvoiceItemDto) {
    console.log('on Add invoiceItem: ', invoiceItem);
    this.initInvoiceFromItem(invoiceItem);
    if (this.canAddInvoiceItem(invoiceItem)) {
      if (this.addInvoiceItem(invoiceItem)) {
        this.invoiceItems.push(invoiceItem);
        this.recalculateInvoice();
        this.invoiceItemListComponent.search();
      }
    } else {
      this.growlService.error('invoice.shared.data.onAddInvoiceItemError');
    }
  }

  canAddInvoiceItem(invoiceItem: InvoiceItemSimpleDto) {
    if (!invoiceItem) {
      return false;
    } else {
      if (!DictionaryUtils.equalsDict(this.invoice.currency, invoiceItem.currency)) {
        console.log('The invoice item has different currency than the invoice');
        return false;
      }

      if (this.invoice.contractLink) {
        // if contract link is set, allow to add only items from the same contract
        const c1 = this.invoice.contractLink;
        const c2 = invoiceItem.contractLink;
        if (!c2) {
          console.log('No contract link in invoice item');
          return false;
        }
        if (!DictionaryUtils.equalsDict(c1.linkType, c2.linkType)) {
          console.log('Invoice item has different contract type');
          return false;
        }
        const nr1 = BusinessUtils.getNumberFromContractLink(c1);
        const nr2 = BusinessUtils.getNumberFromContractLink(c2);

        if (nr1 !== nr2) {
          console.log('Invoice item contract number ' + nr2 + ' is not equal to ' + nr1);
          return false;
        }

        return true;
      } else {
        // if contract link is not set
        // - allow items without contract link
        // - allow any item only when no other items have been added
        return !invoiceItem.contractLink || this.invoice.invoicePositions.length === 0;
      }
    }
  }

  getPositionNumber(): number {
    return this.invoice.invoicePositions.length;
  }

  goToBusinessObject(item: InvoiceItemDto) {
    console.log('goToBusinessObject');
    if (item.businessObject.relatedTo.id && item.businessObject.relatedToId) {
      if (item.businessObject.relatedTo.id === BusinessObjectType.LIMIT_LIST) {
        const ref = <BusObjProductLimitListRefDto>item.businessObject.reference;
        this.router.toPolicyLimitListDetails(ref.lastVersionId);
      } else {
        this.router.goToBusinessObject(item.businessObject.relatedTo.id, item.businessObject.relatedToId);
      }
    } else {
      this.growlService.error('invoice.shared.data.goToBusinessObjectError');
    }
  }

  onCompanySelect(company: CompanySimpleDto) {
    console.log('Invoice Data: Company chosen id: ', company.id);
    // initialize invoice with backend defaults when new invoice created
    if ((!this.invoice.id || this.invoice.id === 0) && !this.invoice.client) {
      this.invoiceService.getInvoiceInitialVersion(company.address.country.id, company.id, this.categoryId).subscribe(
        (invoice) => {
          this.invoice = invoice;
        },
        (error) => {
          console.log('onCompanySelect service error');
          this.logError.emit(error);
        }
      );
    } else if (!this.invoice.id || this.invoice.id === 0) {
      // id = 0, change client, update invoice
      // set new client info for the invoice during update
      this.invoice.client = Mapper.companyToBaseDto(company);
      this.invoice.clientName = company.registrationName;
      this.invoice.address = company.address;
      this.invoice.nationalId = company.nationalId;
      this.invoice.vatNumber = company.vatNumber;
      if (this.invoice.iban && this.invoice.iban.indexOf('Validate') < 0) {
        this.invoice.bic = 'Validate: '.concat(this.invoice.bic);
        this.invoice.iban = 'Validate: '.concat(this.invoice.iban);
      }
    } else {
      // id > 0, invoice already persistef, do not update locked values for client and address
      this.invoice.client = company; // only client changes
    }
  }

  onAddInvoicePosition(invoicePosition: InvoicePositionDto) {
    console.log('onAddInvoicePosition ');
    invoicePosition.positionNumber = this.getPositionNumber();
    invoicePosition.netAmount = 0;
    invoicePosition.quantity = 1;
    invoicePosition.unitPrice = 0;
    invoicePosition.taxes = {};
  }

  onDeleteInvoicePosition(invoicePosition: InvoicePositionDto) {
    console.log('onDeleteInvoicePosition: ' + invoicePosition.title);

    let counter = 1;
    for (const invPosition of this.invoice.invoicePositions) {
      if (
        invoicePosition.positionNumber &&
        invPosition.positionNumber &&
        invoicePosition.positionNumber === invPosition.positionNumber
      ) {
        this.invoice.invoicePositions.splice(counter - 1, 1);
      }
      counter++;
    }

    this.recalculatePositionNumbers();

    if (this.invoice.invoicePositions.length === 0 && !this.invoice.correctedInvoice) {
      delete this.invoice.contractNumber;
      delete this.invoice.dueDate;
      this.invoice.contractLink = undefined;
    }

    for (const invoiceItem of invoicePosition.invoiceItems) {
      if (invoiceItem.id && (!invoiceItem.corrected || !invoiceItem.corrected.id)) {
        // We do not detach corrected items.
        this.removeInvoiceItem(invoiceItem);
      }
    }
    this.invoiceItemListComponent.search();

    this.chosenInvoicePosition = undefined;
  }

  onDeleteInvoiceItem(invoiceItem: InvoiceItemDto) {
    console.log('Deleting invoice item', invoiceItem);
    if (!this.chosenInvoicePosition) {
      console.log('Invoice position is not selected!');
      return;
    }
    const invoiceItems = this.chosenInvoicePosition.invoiceItems;
    const itemIdx = invoiceItems.indexOf(invoiceItem);
    if (itemIdx < 0) {
      console.log('Invoice item was not found in invoice position!');
      return;
    }
    invoiceItems.splice(itemIdx, 1);
    this.recalculatePositionFromItems(this.chosenInvoicePosition);

    if (invoiceItem.id && (!invoiceItem.corrected || !invoiceItem.corrected.id)) {
      // We do not detach corrected items.
      this.removeInvoiceItem(invoiceItem);
    }
    this.invoiceItemListComponent.search();

    this.chosenInvoiceItem = undefined;
  }

  onUnitPriceChange(event: CellChangeEvent<InvoicePositionDto>) {
    // Quantity to 1. Business do not need quantity. Comment if it will be needed
    event.item.quantity = 1;
    this.recalculateItemsFromPosition(event.item);
  }
  onQuantityChange(event: CellChangeEvent<InvoicePositionDto>) {
    this.recalculateItemsFromPosition(event.item);
  }

  onAddUpdateDelete(position: InvoicePositionDto) {
    this.recalculateInvoice();
  }

  onItemAddUpdateDelete(updatedItem: InvoiceItemDto) {
    for (const position of this.invoice.invoicePositions) {
      if (position.invoiceItems.indexOf(updatedItem) >= 0) {
        this.recalculatePositionFromItems(position);
        this.recalculateInvoiceAmounts();
        return;
      }
    }
  }

  onBusinessUnitChange(businessUnit: BusinessUnitDto) {
    const businessUnitId = <BusinessUnitIdDto>{};
    businessUnitId.id = businessUnit.id;
    businessUnitId.countryCode = businessUnit.countryCode;

    for (const position of this.invoice.invoicePositions) {
      for (const item of position.invoiceItems) {
        item.businessUnit = businessUnitId;
      }
    }

    this.invoice.numberingBag = <InvoiceNumberingBagBaseDto>{};
  }

  onInvoiceNumberingBagDtoChange(numberingBag: InvoiceNumberingBagDto) {
    this.invoice.calculateInterests = numberingBag.calculateInterests;
  }

  onAddPayment(payment: InvoicePaymentDto) {
    payment.debit = false;
    payment.artificial = false;
    payment.payment = true;
    payment.invoiceNumber = this.invoice.number;
    payment.status = <DictionaryBaseDto>{id: InvoicePaymentStatus.CREATED_MANUALLY};
    this.invoice.payments.push(payment);
  }

  onDeletePayment(payment: InvoicePaymentDto) {
    this.remove(payment, this.invoice.payments);
    this.remove(payment, this.filteredPayments);
  }

  remove<T>(item: T, items: T[]): boolean {
    const itemIdx = items.indexOf(item);
    if (itemIdx < 0) {
      return false;
    }
    items.splice(itemIdx, 1);
    return true;
  }

  formatTax(dto: TaxIfc): string {
    return this.invoiceCoreService.formatTax(dto);
  }

  formatTaxAmount(position: HasNetAndTaxAmount, taxCategory: DictionaryBaseDto): string {
    const tax = position.taxes[taxCategory.id];
    return tax ? StringUtils.formatNumber(tax.taxAmount) : '';
  }

  formatTaxType(item: InvoiceItemDto, taxCategory: DictionaryBaseDto): string {
    const tax = item.taxes[taxCategory.id];
    return tax ? tax.taxType.name : '';
  }

  protected get correctionReasonVisible(): boolean {
    return (
      this._invoice &&
      (DictionaryUtils.equalsDictAndId(this._invoice.invoiceType, InvoiceType.CREDIT_NOTE) ||
        !!this._invoice.invoiceToNullify)
    );
  }

  protected get correction(): boolean {
    return !!(this._invoice && this._invoice.correctedInvoice);
  }

  private initInvoiceFromItem(invoiceItem: InvoiceItemSimpleDto) {
    if (this.invoiceItems.length === 0) {
      this._invoice.currency = invoiceItem.currency;
    }
  }

  private loadCorrectionReasons() {
    const numberingBagId = this._invoice.numberingBag ? this._invoice.numberingBag.id : undefined;
    const newClient = !!(this._invoice.invoiceToNullify || this._invoice.followedByInvoice);
    this.invoiceNumberingBagService.getCorrectionReasons(numberingBagId, newClient).subscribe(
      (correctionReasons) => {
        this.correctionReasons = correctionReasons;
        console.log('correctionReasons', correctionReasons);
      },
      (error) => {
        console.log('Error during invoiceNumberingBagService.getCorrectionReasons');
        this.logError.emit(error);
      }
    );
  }

  private createTaxCategoryMap() {
    this.taxCategoryMap = {};
    if (this._invoice.invoicePositions) {
      for (const position of this._invoice.invoicePositions) {
        for (const positionTax of Object.values(position.taxes)) {
          this.taxCategoryMap[positionTax.taxCategory.id] = positionTax.taxCategory;
          for (const item of position.invoiceItems) {
            this.addTaxCategoryFromItem(item);
          }
        }
      }
    }
  }

  private addTaxCategoryFromItem(item: InvoiceItemSimpleDto) {
    for (const itemTax of Object.values(item.taxes)) {
      this.taxCategoryMap[itemTax.taxCategory.id] = itemTax.taxCategory;
    }
  }

  private recalculatePositionFromItems(position: InvoicePositionDto) {
    let netAmountSum = 0;
    for (const item of position.invoiceItems) {
      netAmountSum += item.netAmount;
    }
    position.unitPrice = netAmountSum;
    this.recalculatePositionFields(position);
    console.log('Updated position ' + netAmountSum);
  }

  private recalculatePositionNumbers() {
    let counter = 1;

    for (const invPosition of this.invoice.invoicePositions) {
      invPosition.positionNumber = counter;
      counter++;
    }
  }

  private addInvoiceItem(invoiceItem: InvoiceItemSimpleDto): boolean {
    // console.log('Received InvoiceItem with id = ' + invoiceItem.id);
    if (!this.chosenInvoicePositionSelect) {
      const position: InvoicePositionDto = <InvoicePositionDto>{};
      position.taxes = {};

      position.netAmount = invoiceItem.netAmount;
      position.quantity = 1;
      position.unitPrice = invoiceItem.netAmount;

      for (const itemTax of Object.values(invoiceItem.taxes)) {
        const positionTax = <InvoicePositionTaxDto>{};
        positionTax.taxCategory = itemTax.taxCategory;
        positionTax.taxRateValue = itemTax.taxRateValue;
        positionTax.taxNotApplicable = itemTax.taxNotApplicable;
        positionTax.taxAmount = (position.netAmount * itemTax.taxRateValue) / 100;
        position.taxes[positionTax.taxCategory.id] = positionTax;
      }

      position.invoiceItems = new Array();
      position.invoiceItems.push(<InvoiceItemDto>invoiceItem);
      if (!this.invoice.invoicePositions) {
        this.invoice.invoicePositions = new Array();
      }
      position.positionNumber = this.invoice.invoicePositions.length + 1;
      position.title = invoiceItem.title;

      this.addTaxCategoryFromItem(invoiceItem);
      this.invoice.invoicePositions.push(position);

      this.updateContract(invoiceItem);
      this.recalculatePositionNumbers();

      return true;
    } else {
      for (const position of this.invoice.invoicePositions) {
        if (this.chosenInvoicePositionSelect.positionNumber === position.positionNumber) {
          if (!InvoiceUtils.equalsTaxRates(position.taxes, invoiceItem.taxes)) {
            this.growlService.error('invoice.shared.data.addInvoiceItemError');
            return false;
          }
          this.addTaxCategoryFromItem(invoiceItem);
          position.invoiceItems.push(<InvoiceItemDto>invoiceItem);
          this.recalculatePositionFromItems(position);
        }
      }
      return true;
    }
  }

  private recalculatePositionFields(position: InvoicePositionDto) {
    if (position.quantity && position.unitPrice) {
      position.netAmount = NumberUtils.roundMoney(position.quantity * position.unitPrice);
      for (const positionTax of Object.values(position.taxes)) {
        positionTax.taxAmount = NumberUtils.roundMoney((position.netAmount * positionTax.taxRateValue) / 100);
      }
    } else {
      position.netAmount = 0;
      for (const positionTax of Object.values(position.taxes)) {
        positionTax.taxAmount = 0;
      }
    }
    console.log('recalculatePositionFields: ', position.netAmount);
  }

  private recalculateItemsFromPosition(position: InvoicePositionDto) {
    this.recalculatePositionFields(position);

    if (!position.corrected && position.invoiceItems && position.invoiceItems.length > 0) {
      let itemsNetAmountSum = 0;
      for (const item of position.invoiceItems) {
        itemsNetAmountSum += item.netAmount;
      }
      console.log('recalculate invoice items from ' + itemsNetAmountSum + ' to ' + position.netAmount);
      if (itemsNetAmountSum !== 0) {
        const corrFactor = position.netAmount / itemsNetAmountSum;
        for (const item of position.invoiceItems) {
          item.netAmount = item.netAmount * corrFactor;
        }
      } else {
        const itemsNetAmount = position.netAmount / position.invoiceItems.length;
        for (const item of position.invoiceItems) {
          item.netAmount = itemsNetAmount;
        }
      }
    }
  }

  private recalculateInvoice() {
    this.recalculateInvoiceAmounts();
    this.recalculateDates();
    this.recalculateBusinessObjectNumber();
    this.recalculateInvoiceItemType();
    this.recalculateInstallmentsCountStr();
    this.recalculateInvoiceItemSubtype();
  }

  private recalculateInvoiceAmounts() {
    this.invoice.invoiceAmounts.length = 0;
    for (const position of this.invoice.invoicePositions) {
      if (position.netAmount) {
        const amount = this.findOrCreateAmount(position.taxes);
        amount.netAmount += position.netAmount;
        for (const positionTax of Object.values(position.taxes)) {
          if (positionTax.taxRateValue) {
            const amountTax = amount.taxes[positionTax.taxCategory.id];
            amountTax.taxAmount = (amount.netAmount * amountTax.taxRateValue) / 100;
          }
        }
      }
    }

    let netAmountSum = 0;
    let taxAmountSum = 0;
    for (const amount of this.invoice.invoiceAmounts) {
      amount.netAmount = NumberUtils.roundMoney(amount.netAmount);
      netAmountSum += amount.netAmount;
      for (const amountTax of Object.values(amount.taxes)) {
        amountTax.taxAmount = NumberUtils.roundMoney(amountTax.taxAmount);
        if (DictionaryUtils.equalsDictAndId(amountTax.taxPayer, TaxPayer.CLIENT)) {
          taxAmountSum += amountTax.taxAmount;
        }
      }
    }

    this.invoice.netAmount = netAmountSum;
    this.invoice.grossAmount = netAmountSum + taxAmountSum;
  }

  private recalculateDates() {
    this.invoice.dateFrom = undefined;
    this.invoice.dateTo = undefined;
    for (const position of this.invoice.invoicePositions) {
      if (position.invoiceItems) {
        for (const item of position.invoiceItems) {
          if (item.dateFrom) {
            if (!this.invoice.dateFrom || this.invoice.dateFrom > item.dateFrom) {
              this.invoice.dateFrom = item.dateFrom;
            }
          }
          if (item.dateTo) {
            if (!this.invoice.dateTo || this.invoice.dateTo < item.dateTo) {
              this.invoice.dateTo = item.dateTo;
            }
          }
        }
      }
    }
  }

  private recalculateBusinessObjectNumber() {
    this.invoice.businessObjectNumber = '';
    for (const position of this.invoice.invoicePositions) {
      if (position.invoiceItems) {
        for (const invoiceItem of position.invoiceItems) {
          const boNumber = this.getItemBusinessObjectNumber(invoiceItem);
          if (
            boNumber &&
            (!this.invoice.businessObjectNumber || this.invoice.businessObjectNumber.indexOf(boNumber) < 0)
          ) {
            if (this.invoice.businessObjectNumber) {
              this.invoice.businessObjectNumber += ', ';
            } else {
              this.invoice.businessObjectNumber = '';
            }
            this.invoice.businessObjectNumber += boNumber;
            if (this.invoice.businessObjectNumber.length > 255) {
              return;
            }
          }
        }
      }
    }
  }

  private recalculateInvoiceItemType() {
    this.invoiceItemTypesStr = Array.from(
      new Set(
        this.invoice.invoicePositions
          .map((position) => position.invoiceItems)
          .reduce((items1, items2) => items1.concat(items2), [])
          .map((item) => item.type)
          .filter((type) => type)
          .map((type) => type.name)
      )
    ).join('; ');
  }

  private recalculateInstallmentsCountStr() {
    this.installmentsStr = InvoiceUtils.getInstallmentsCountStr(this.invoice);
  }

  private recalculateInvoiceItemSubtype() {
    this.invoice.subtypes = '';
    for (const position of this._invoice.invoicePositions) {
      if (position.invoiceItems) {
        for (const invoiceItem of position.invoiceItems) {
          if (invoiceItem.subtype && this.invoice.subtypes.indexOf(invoiceItem.subtype.name) < 0) {
            if (this.invoice.subtypes) {
              this.invoice.subtypes += ', ';
            } else {
              this.invoice.subtypes = '';
            }
            this.invoice.subtypes += invoiceItem.subtype.name;
          }
        }
      }
    }
  }

  // prettier-ignore
  private findOrCreateAmount(taxes: { [index: string]: InvoicePositionTaxDto}): InvoiceAmountDto {
    console.log('findOrCreateAmount: ' + this.invoiceCoreService.formatTaxes(taxes));
    for (const amount of this.invoice.invoiceAmounts) {
      console.log('checking : ' + this.invoiceCoreService.formatTaxes(amount.taxes));
      if (InvoiceUtils.equalsTaxRates(amount.taxes, taxes)) {
        return amount;
      }
    }
    const newAmount = <InvoiceAmountDto>{};
    newAmount.taxes = {};
    for (const positionTax of Object.values(taxes)) {
      const amountTax = <InvoiceAmountTaxDto>{};
      amountTax.taxCategory = positionTax.taxCategory;
      amountTax.taxPayer = positionTax.taxPayer;
      amountTax.taxNotApplicable = positionTax.taxNotApplicable;
      amountTax.taxRateValue = positionTax.taxRateValue;
      amountTax.taxAmount = 0;
      newAmount.taxes[amountTax.taxCategory.id] = amountTax;
    }
    newAmount.netAmount = 0;
    console.log('created new : ' + this.invoiceCoreService.formatTaxes(newAmount.taxes));

    this.invoice.invoiceAmounts.push(newAmount);
    return newAmount;
  }

  private updateContract(invoiceItem: InvoiceItemSimpleDto) {
    if (!this.waitingForRelatedContractVersion && !this.invoice.contractNumber && invoiceItem.businessObject) {
      this.waitingForRelatedContractVersion = true;
      if (!this.invoice.contractLink && invoiceItem.contractLink) {
        this.invoice.contractLink = invoiceItem.contractLink;
      }
      const businessObject = invoiceItem.businessObject;

      if (!businessObject) {
        return;
      }

      this.businessObjectService
        .getRelatedContractVersion(businessObject.relatedTo.id, businessObject.relatedToId)
        .subscribe(
          (legalVersion) => {
            if (legalVersion) {
              if (legalVersion.clazz === DtoClass.ContractVersionDto) {
                const contractVersion = <ContractVersionDto>legalVersion;
                if (contractVersion.contract && contractVersion.contract.number) {
                  this.invoice.contractNumber = contractVersion.contract.number;
                }
                this.calculateDueDate(contractVersion.invoicePaymentTerm);
              } else if (legalVersion.clazz === DtoClass.PolicyContractVersionDto) {
                const policyContractVersion = <PolicyContractVersionDto>legalVersion;
                if (policyContractVersion.policyContract && policyContractVersion.policyContract.number) {
                  this.invoice.contractNumber = policyContractVersion.policyContract.number;
                }
                this.calculateDueDate(policyContractVersion.paymentDays);
              } else if (legalVersion.clazz === DtoClass.BrokerContractVersionDto) {
                const brokerContractVersion = <BrokerContractVersionDto>legalVersion;
                this.invoice.contractNumber = brokerContractVersion.brokerContract.number;
              }
            }
            this.waitingForRelatedContractVersion = false;
          },
          (error) => {
            this.waitingForRelatedContractVersion = false;
          }
        );
    }
  }

  private calculateDueDate(paymentDays: number) {
    if (paymentDays && this.invoice.issueDate) {
      const issueDate = DateUtils.maxNotNull(this.invoice.issueDate, new Date());
      this.invoice.dueDate = new Date();
      this.invoice.dueDate.setDate(issueDate.getDate() + paymentDays);
    }
  }

  private getItemBusinessObjectNumber(item: InvoiceItemSimpleDto) {
    if (!item || !item.businessObject) {
      return '';
    }
    return BusinessObjectUtils.getNumber(item.businessObject);
  }

  private getPositionBusinessObjectNumber(position: InvoicePositionDto): string {
    for (const item of position.invoiceItems) {
      if (item.businessObject && item.businessObject.reference && item.businessObject.description) {
        return item.businessObject.description;
      }
    }
    return '';
  }

  private removeInvoiceItem(item: InvoiceItemSimpleDto) {
    const index = this.invoiceItems.indexOf(item);
    if (index > -1) {
      this.invoiceItems.splice(index, 1);
    }
  }

  get correctionReasonRegexp() {
    return this.categoryId === InvoiceCategory.INTEREST
      ? undefined
      : /^(?!WRITTEN_OFF_AFTER_3_YEARS|CORRECTION_ON_CLIENT_REQUEST).*$/;
  }
}
