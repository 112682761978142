import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {CompanyService} from '../../../../bonding_shared/services/company.service';
import {
  ClientCompanyBaseDto,
  ClientInfoDto,
  CompanyBaseDto,
  CompanyDto,
  CompanyGroupVersionSimpleDto,
  CompanyIdDto,
  CompanyIdentifierDto,
  CompanyPortalDto,
  CompanyRatingBaseDto,
  CompanyRatingVersionCriteriaDto,
  CompanyRatingVersionDto,
  CompanyRawDto,
  CountryRatingVersionDto,
  DictionaryBaseDto,
  LimitExposureDto,
  TaskSimpleDto,
} from '../../../../bonding_shared/model/dtos';
import {LoggedUserService} from '../../../../bonding_shared/services/logged-user.service';
import {StringUtils} from '../../../../bonding_shared/utils';
import {CompanyRatingService} from '../../../../bonding_shared/services/company-rating.service';
import {AppConfigService, CountryRatingService, FormatService, LimitService} from '../../../../bonding_shared/services';
import {
  CompanyIdentifierType,
  CompanyResponsiblePersonRole,
  CompanyStatus,
  ContactType,
  LegalForm,
  RatingCategory,
  RatingStatus,
  RatingType,
} from '../../../../bonding_shared/model/dictionary-ids';
import {TaskGuiService} from '../../../task';
import {ClientCompanyService} from '../../../../bonding_shared/services/client-company.service';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import * as _ from 'lodash';
import {map} from 'rxjs/operators';
import {BondingContractOfferProposedCommissionService} from '../../../bonding-contract/offer/services/bonding-contract-offer-proposed-commission.service';
import {ClientCompanyViewParams} from '../../../client-company/components/client-company-details.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'company-info',
  templateUrl: './company-info.component.pug',
  styles: ['label { font-weight: 700; margin-bottom: 2px; }'],
})
export class CompanyInfoComponent implements OnInit {
  @Input() showLink = false;
  @Input() fullDetails = false;

  /* the flags below work only in fullDetails mode*/
  @Input() showScore = false;
  @Input() showRating = false; // bonding rating
  @Input() showBondingRatingKOM = false; // bonding rating KOM (kuke)
  @Input() showBondingRatingGSP = false; // bonding rating GSP (kuke)
  @Input() ratingOnDate: Date;
  @Input() showCreditStatus = false;
  @Input() showInsuranceRating = false;
  @Input() showLimitRating = false;
  @Input() showCountryRating = false;
  @Input() showPkd = true;
  @Input() showNaceCodes = false;
  @Input() showStartOfBusiness = false;
  @Input() showIndustry = false;
  @Input() showSegment = false;
  @Input() showNationalId = true;
  @Input() showVatNumber = true;
  @Input() showStatNumber = true;
  @Input() showResponsible = false; // CompanyResponsiblePersonRole.UNDERWRITER for credendo, CompanyResponsiblePersonRole.POLICY_MNAGER for kuke
  @Input() showBondingManager = false; // CompanyResponsiblePersonRole.BONDING_MANAGER
  @Input() showCompanyGroup = true;
  @Input() showCompanyGroupHead = true;
  @Input() showExpectsFinancialData = false;
  @Input() showInternalNumber = false;
  @Input() showSegmentVip = false;
  @Input() showLastReportFetchingDate = false;
  @Input() showGroupRelevant = false;
  @Input() showPhysicalPerson = true;
  @Input() showPartnerNo = true;
  @Input() showType = true;
  @Input() showBusinessPartner = false;
  @Input() hideAddress = false;
  @Input() showPhone = false;
  @Input() showRawCompanyStatus = true;
  @Input() showCompanyId = true;
  @Input() showLimitExposure = false;
  @Input() viewId: number; // useful when return to the current view is needed after redirection to another view
  @Input() parentObjectId: number; // useful when return to the current view is needed after redirection to another view

  // rawCompany should be true when CompanyRawDto, CompanyPortalDto is injected
  @Input() rawCompany = false;
  @Input() fetchingRating = false;
  @Input() clientIdForInternalNumber: number; // to show client's internal number of the company
  @Input() thirdPartyId: number;
  @Output() internalNumberClicked = new EventEmitter<void>();

  longLabel = false;

  _companyId: number;
  _status: ClientInfoDto;
  _ratingVersion: CompanyRatingVersionDto = null;
  _countryRatingVersion: CountryRatingVersionDto;
  _company: CompanyDto | CompanyBaseDto | CompanyPortalDto | CompanyRawDto;
  clientCompany: ClientCompanyBaseDto;
  _responsible: string;
  bondingManagerName: string;
  _expectsFinancialDataTask: TaskSimpleDto;
  portalMode = false;
  kuke = false;
  credendo = false;
  limitRating: CompanyRatingVersionDto;
  limitExposure: LimitExposureDto;

  companyRatingVersionCriteria = BusinessUtils.createSearchCriteria<CompanyRatingVersionCriteriaDto>();

  readonly RatingStatus = RatingStatus;
  readonly CompanyStatus = CompanyStatus;

  constructor(
    private appConfigService: AppConfigService,
    private companyService: CompanyService,
    public routerService: RouterService,
    private userService: LoggedUserService,
    private companyRatingService: CompanyRatingService,
    private countryRatingService: CountryRatingService,
    private clientCompanyService: ClientCompanyService,
    private formatService: FormatService,
    private limitService: LimitService,
    private taskGuiService: TaskGuiService,
    private translateService: TranslateService,
    @Optional() private bondingContractOfferProposedCommissionService: BondingContractOfferProposedCommissionService
  ) {}

  ngOnInit(): void {
    this.longLabel = this.userService.longLabelLanguage();
    this.portalMode = this.userService.portal;
    this.kuke = this.appConfigService.kuke;
    this.credendo = this.appConfigService.credendo;
  }

  @Input()
  set company(c: CompanyDto | CompanyBaseDto | CompanyPortalDto | CompanyRawDto) {
    this._company = c;
    this._companyId = this.getCompanyId();
    if (this._companyId) {
      this.downloadAdditionalData();
    }
  }

  @Input()
  set companyId(id: number) {
    this._companyId = id;
    if (id > 0) {
      this.companyService.getCompany(id).subscribe((company) => (this._company = company));
    } else {
      this._company = undefined;
    }
  }

  getCompanyId(): number {
    if (this._company && !this.rawCompany) {
      return (<CompanyDto>this._company).id;
    } else {
      return undefined;
    }
  }

  prettyAddress() {
    return BusinessUtils.prettyAddress(this._company);
  }

  goToClient(id: number) {
    if (this.showLink && !this.rawCompany) {
      this.routerService.toCompanyPreview(id);
    }
  }

  downloadAdditionalData() {
    if (this.showSegment) {
      this.companyService.getClientInfo(this._companyId).subscribe(
        (s) => (this._status = s),
        (error) => console.error('company status download failed:', error)
      );
    }
    // import because MDG number is inside CompanyDto
    if (this.credendo) {
      this.companyService.getCompany(this._companyId).subscribe((company) => (this._company = company));
    }

    if (this.showRating || this.showInsuranceRating || this.showBondingRatingGSP || this.showBondingRatingKOM) {
      this.companyRatingVersionCriteria.criteria.parent = <CompanyRatingBaseDto>{};
      this.companyRatingVersionCriteria.criteria.parent.company = <CompanyIdDto>{id: this._companyId};
      // without cutTimeZone "2021-09-27" is sent to backend as "2021-09-26T22:00:00.000Z"
      this.companyRatingVersionCriteria.criteria.onDate = this.formatService.cutTimeZone(this.ratingOnDate);
      this.companyRatingVersionCriteria.criteria.last = this.ratingOnDate === undefined ? true : undefined;
      if (this.showInsuranceRating) {
        this.companyRatingVersionCriteria.criteria.ratingType = <DictionaryBaseDto>{id: RatingType.ST_INSURANCE};
      } else {
        if (this.kuke) {
          this.companyRatingVersionCriteria.criteria.ratingType = <DictionaryBaseDto>{
            id: this.showBondingRatingGSP ? RatingType.GSP : RatingType.BONDING,
          };
        } else {
          this.companyRatingVersionCriteria.criteria.parent.category = <DictionaryBaseDto>{id: RatingCategory.MANUAL};
        }
      }
      this.companyRatingService
        .searchByCriteria<CompanyRatingVersionCriteriaDto, CompanyRatingVersionDto>(this.companyRatingVersionCriteria)
        .subscribe(
          (r) => this.setRatingVersion(r.size > 0 ? r.result[0] : undefined),
          (error) => {
            console.error('company rating download failed:', error);
            this.setRatingVersion(undefined);
          }
        );
    }
    if (this.showLimitRating) {
      this.companyRatingService.getLimitRatingVersion(this._companyId).subscribe((r) => (this.limitRating = r));
    }
    if (this.showCountryRating) {
      this.countryRatingService.getLastVersionForCompany(this._companyId).subscribe(
        (s) => (this._countryRatingVersion = s),
        (error) => console.error('country rating download failed:', error)
      );
    }

    if (this.showResponsible) {
      this.getResponsiblePersonName(
        this.kuke ? CompanyResponsiblePersonRole.POLICY_MANAGER : CompanyResponsiblePersonRole.UNDERWRITER
      ).subscribe((p) => (this._responsible = p));
    }

    if (this.showBondingManager) {
      this.getResponsiblePersonName(CompanyResponsiblePersonRole.BONDING_MANAGER).subscribe(
        (p) => (this.bondingManagerName = p)
      );
    }

    if (!this.rawCompany && this.showInternalNumber && (this.portalMode || this.clientIdForInternalNumber)) {
      this.clientCompanyService.getClientCompany(this.clientIdForInternalNumber, this._companyId).subscribe(
        (p) => {
          this.clientCompany = p;
        },
        (error) => console.error('client company download failed:', error)
      );
    }

    if (this.showExpectsFinancialData) {
      this.companyService
        .getExpectsFinancialData(this._companyId)
        .subscribe((p) => (this._expectsFinancialDataTask = p));
    }

    if (this.showLimitExposure) {
      this.limitService.exposureForBuyer(this._companyId).subscribe((exp) => (this.limitExposure = exp));
    }
  }

  public get companyGroup(): CompanyGroupVersionSimpleDto {
    if (this._company.hasOwnProperty('companyGroupVersions')) {
      // 'instanceof' CompanyDto for typescript interface
      const company = this._company as CompanyDto;
      return _(company.companyGroupVersions)
        .filter((group) => group.last)
        .head();
    }
    return null;
  }

  get mainCompanyPhone(): string {
    if (this._company['contacts']) {
      const mainPhone = this._company['contacts'].filter((c) => c.main && c.type.id === ContactType.PHONE);
      return mainPhone.length === 1 ? mainPhone[0].value : '';
    }
  }

  get mainNaceCode(): DictionaryBaseDto {
    if (this._company['naceCodes']) {
      const mainCodes = this._company['naceCodes'].filter((nc) => nc.main).map((nc) => nc.naceCode);
      return mainCodes.length === 1 && mainCodes[0];
    }
    return this._company['naceCode'];
  }

  getResponsiblePersonName(roleId: number) {
    return this.companyService
      .getUnderwriter(this._companyId, roleId)
      .pipe(map((p) => (p ? StringUtils.userName(p.user) : undefined)));
  }

  isPhysicalPerson(): boolean {
    if (this.kuke) {
      return !!this._company.legalForm && this._company.legalForm.id === LegalForm.FP010;
    }
    return this._company.physicalPerson && this._company.physicalPersonIndicator;
  }

  private setRatingVersion(v) {
    this._ratingVersion = v;
    const bs = this.bondingContractOfferProposedCommissionService;
    if (bs) {
      bs.setRatingVersion(this._ratingVersion);
    }
  }

  toClientCompanyNew() {
    this.internalNumberClicked.emit();
    this.routerService.toClientCompanyDetailsWithParams(<ClientCompanyViewParams>{
      companyId: this._companyId,
      returnViewId: this.viewId,
      returnObjectId: this.parentObjectId,
    });
  }

  toClientCompany() {
    this.internalNumberClicked.emit();
    this.routerService.toClientCompanyDetailsWithParams(<ClientCompanyViewParams>{
      id: this.clientCompany.id,
      returnViewId: this.viewId,
      returnObjectId: this.parentObjectId,
    });
  }

  get MDGNumber(): string {
    const company = this._company as CompanyDto;
    if (company.identifiers) {
      return _(company.identifiers)
        .filter((identifier: CompanyIdentifierDto) => identifier.type.id === CompanyIdentifierType.MDG_NUMBER)
        .map((identifier: CompanyIdentifierDto) => identifier.identifier)
        .head();
    }
    return null;
  }

  get dataConsistency(): string {
    const company = this._company as CompanyDto;
    return this.translateService.instant(
      company.status.id === CompanyStatus.DATA_CONSISTENT
        ? 'company.shared.info.consistent'
        : 'company.shared.info.inconsistent'
    );
  }

  get businessPartner(): string {
    const company = this._company as CompanyDto;
    return this.translateService.instant(company.businessPartner ? 'common.yes' : 'common.no');
  }
}
