<details-view [title]="'policy.technicalDetails.title'" objectName="Policy" [parentComponent]="self"
              [versionListHidden]="true" [fixedBlockHidden]="false" [objectCreated]="objectCreated"
              [updateRightOrFunc]="PolicyElementaryRight.POLICY_SERVICE_DETAILS_UPDATE" [object]="technicalDetails"
              >
  <div class="details-content">
    <form #ngForm="ngForm">
      <div class="bon-label-190">
        <bon-section labelKey="policy.technicalDetails.attendants">
          <div class="bon-card-group">
            <div class="bon-card-inner bon-label-270">
              <ng-template #spinner>
                <i class="fa fa-spinner fa-spin"></i>
              </ng-template>

              <form-row labelKey="policy.technicalDetails.bokAttendant"  [showErrors]="showErrors" *ngIf="kuke">
                <item-combo class="bon-input-size" *ngIf="bokUsers; else spinner" [items]="bokUsers" [(ngModel)]="technicalDetails.attendants.bokAttendant" name="bokAttendant"
                            [presentationMode]="portal"
                            nullLabel=" ">
                  <ng-template let-item="item">{{item | username}}</ng-template>
                </item-combo>
              </form-row>
              <form-row labelKey="policy.technicalDetails.blsAttendant"  [showErrors]="showErrors" *ngIf="kuke">
                <item-combo class="bon-input-size" *ngIf="blsUsers; else spinner" [items]="blsUsers" [(ngModel)]="technicalDetails.attendants.blsAttendant" name="blsAttendant"
                            [presentationMode]="portal"
                            nullLabel=" ">
                  <ng-template let-item="item">{{item | username}}</ng-template>
                </item-combo>
              </form-row>
              <form-row labelKey="policy.technicalDetails.leadAnalyst"  [showErrors]="showErrors" *ngIf="kuke">
                <item-combo class="bon-input-size" *ngIf="leadAnalystUsers; else spinner" [items]="leadAnalystUsers" [(ngModel)]="technicalDetails.attendants.leadAnalyst" name="leadAnalyst"
                            [presentationMode]="portal"
                            nullLabel=" ">
                  <ng-template let-item="item">{{item | username}}</ng-template>
                </item-combo>
              </form-row>
              <form-row labelKey="policy.technicalDetails.dwiAttendant"  [showErrors]="showErrors" *ngIf="kuke">
                <item-combo class="bon-input-size" *ngIf="dwiUsers; else spinner" [items]="dwiUsers" [(ngModel)]="technicalDetails.attendants.dwiAttendant" name="dwiAttendant"
                            [presentationMode]="portal"
                            nullLabel=" ">
                  <ng-template let-item="item">{{item | username}}</ng-template>
                </item-combo>
              </form-row>
              <form-row labelKey="policy.technicalDetails.underwriter"  [showErrors]="showErrors" *ngIf="!kuke">
                <item-combo class="bon-input-size" *ngIf="underwriters; else spinner" [items]="underwriters" [(ngModel)]="technicalDetails.attendants.underwriter" name="underwriter"
                            [presentationMode]="portal"
                            nullLabel=" ">
                  <ng-template let-item="item">{{item | username}}</ng-template>
                </item-combo>
              </form-row>
              <form-row labelKey="policy.technicalDetails.manager"  [showErrors]="showErrors" *ngIf="!kuke">
                <item-combo class="bon-input-size" *ngIf="managers; else spinner" [items]="managers" [(ngModel)]="technicalDetails.attendants.manager" name="manager"
                            [presentationMode]="portal"
                            nullLabel=" ">
                  <ng-template let-item="item">{{item | username}}</ng-template>
                </item-combo>
              </form-row>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.lifecycleStage" *ngIf="this.appService.credendo">
          <div class="bon-card-group">
            <div class="bon-card-inner bon-label-270">
              <form-row labelKey="policy.technicalDetails.lifecycleStage"  [showErrors]="showErrors">
                <dict-combo [(ngModel)]="technicalDetails.lifecycleStage" dictionary="PolicyLifecycleStage" class="bon-input-size"
                            nullLabel=" " [presentationMode]="portal" name="lifecycleStage"></dict-combo>
              </form-row>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.lifecycleStageRevision" *ngIf="appService.credendo">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <a-table [items]="revisions"  [canUnselect]="false" [spacer]="false">
                <column property="changeTimestamp" labelKey="common.changeTime" type="datetime" initialSort="down"></column>
                <column labelKey="common.changedBy">
                  <ng-template let-item="item">
                    <span>{{item.user | username}}</span>
                  </ng-template>
                </column>
                <column property="lifecycleStage" labelKey="policy.technicalDetails.lifecycleStage" dictionary="PolicyLifecycleStage"></column>
              </a-table>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.brokerContracts">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <broker-contract-relation-list *ngIf="technicalDetails" [relatedToId]="technicalDetails.policyContract.masterPolicyContract.id" [relatedToTypeId]="'19000021'" [showBrokerColumns]="true">
              </broker-contract-relation-list>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.salesRep">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <sales-rep-relation-policy-list *ngIf="technicalDetails" [relatedToId]="technicalDetails.policyContract.masterPolicyContract.id">
              </sales-rep-relation-policy-list>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.cessions" *ngIf="showCession()">
          <div class="bon-card-group">
            <div class="bon-card-inner">
                <policy-cession-list [masterPolicyContractId]="technicalDetails.policyContract.masterPolicyContract.id" [policyContractId]="technicalDetails.policyContract.id"></policy-cession-list>
            </div>
          </div>
          <div *ngIf="showCrossborder()" class="bon-card-group">
            <div class="bon-card-inner">
              <checkbox-row labelKey="policy.technicalDetails.crossborder" [(ngModel)]="technicalDetails.crossborder"
                            name="crossborder" [presentationMode]="portal">
              </checkbox-row>
            </div>
          </div>
        </bon-section>
        <bon-section labelKey="policy.monitoring.monitoring" *ngIf="showMonitoring()">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <policy-monitoring-list [policyContractId]="technicalDetails.policyContract.id" [productTypeId]="technicalDetails.policyContract.masterPolicyContract.productType.id"></policy-monitoring-list>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.debtCollection" *ngIf="kuke">
          <div class="bon-card-group">
            <div class="bon-card-inner bon-label-270">

              <checkbox-row labelKey="policy.technicalDetails.ownCollection" [(ngModel)]="technicalDetails.collection.ownCollection"
                            name="ownCollection" [presentationMode]="portal">
              </checkbox-row>
              <checkbox-row labelKey="policy.technicalDetails.powerForDebtCollection" [(ngModel)]="technicalDetails.collection.powerToCollect"
                name="powerToCollect" [presentationMode]="portal">
              </checkbox-row>
              <checkbox-row labelKey="policy.technicalDetails.agreementForReceivableTransfer" [(ngModel)]="technicalDetails.collection.agreementForReceivableTransfer"
                name="agreementForReceivableTransfer" [presentationMode]="portal">
              </checkbox-row>
            </div>
            <div class="bon-card-inner bon-label-270">
              <input-row *ngIf="technicalDetails.collection.ownCollection" labelKey="policy.technicalDetails.nameOfDebtCollection"
                         [(ngModel)]="technicalDetails.collection.ownCollectionName" maxlength="256" name="ownCollectionName"
                         [showErrors]="showErrors" [presentationMode]="portal" required></input-row>
            </div>
          </div>
        </bon-section>

        <bon-section labelKey="policy.technicalDetails.autodecisions" *ngIf="kuke">
          <div class="bon-card-group">
            <div class="bon-card-inner bon-label-270">
              <checkbox-row labelKey="policy.technicalDetails.excludeFromAutodecisions" [(ngModel)]="technicalDetails.exclusionFromAutodecisions"
                            name="exclusionFromAutodecisions" [presentationMode]="portal">
              </checkbox-row>
            </div>
          </div>
        </bon-section>

    </div>
    </form>
  </div>
  <div class="additional-buttons">
    <button class="bon-btn-warning" *ngIf="addRelationButtonVisible()" type="button"
    (click)="routerService.toBrokerContractRelationDetailsForPolicy(0, technicalDetails.policyContract.masterPolicyContract.id, 0)">
      {{'policy.technicalDetails.addBroker' | translate}}
    </button>
    <ng-container *hasRight="SalesRepElementaryRight.SALES_REP_RELATION_CREATE_UPDATE_POLICIES">
      <button class="bon-btn-warning" *ngIf="technicalDetails" type="button"
              (click)="routerService.toSalesRepRelationDetailsForPolicy(undefined, technicalDetails.policyContract.masterPolicyContract.id)">
        {{'policy.technicalDetails.addSalesRep' | translate}}
      </button>
    </ng-container>
    <ng-container *hasRight="PolicyElementaryRight.POLICY_CESSION_CREATE">
      <button class="bon-btn-warning" *ngIf="showCession()" type="button"
              (click)="routerService.toCessionDetailsNew(technicalDetails.policyContract.id)">
        {{'policy.technicalDetails.addCession' | translate}}
      </button>
    </ng-container>
    <ng-container *hasRight="PolicyElementaryRight.POLICY_REPORTING_PERIOD_UPDATE">
      <button class="bon-btn-warning" type="button"
              (click)="routerService.toReportingPeriodEditScreen(technicalDetails.policyContract.id)">
        {{'policy.technicalDetails.editReportingPeriods' | translate}}
      </button>
    </ng-container>
  </div>
  <div class="information-header">
    <div style="float: left;">
      <b class="font-larger a-link" *ngIf="technicalDetails && !appService.credendo" style='float: left; padding: 0px 4px;'
         (click)="routerService.toPolicyContractPreviewByPolicyContractId(technicalDetails.policyContract.id)">
        {{technicalDetails.policyContract.number}}
      </b>
    </div>
    <div style="float: left; margin-top: 2px;">
      <span style="float: left;" *ngIf="technicalDetails">
        <select class="bon-select" [ngModel]="technicalDetails.policyContract.policyYear"
                (change)="onChangePolicyYear($event.target.value)">
          <option *ngFor="let item of policyContracts" value="{{item.policyYear}}">{{'policy.year' | translate}} {{item.policyYearFormatted}}</option>
        </select>
      </span>
    </div>
  </div>
</details-view>
