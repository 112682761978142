import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {CompanyService, FormatService} from '../services';
import {
  CompanyIdDto,
  InstalmentCalendarEntryDto,
  NumberFormatDto,
  PolicyContractSubinsuredCompanyDto,
  ZipCodeFormatDto,
} from '../model';
import * as normalize from 'normalize-diacritics';
import {StringUtils} from '../utils';
import Big from 'big.js';
import * as moment from 'moment';
import {isNumeric, NumberUtils} from '../utils/number-utils';

// import {Http} from '@angular/http';

export class CustomValidators {
  // return <FormGroup>this.formModel.controls[this.groupName];

  static removeDiacriticsAndSpecialLetters(s: string) {
    return normalize.normalizeSync(s).replace(/ł/g, 'l').replace(/Ł/g, 'L').replace(/ß/g, 'S');
  }

  static numberFormat = function (patternControlName: string) {
    console.log('numberFormat pattern = ' + patternControlName);
    return (control: UntypedFormControl): {[key: string]: any} => {
      const patternControl = control.root.get(patternControlName);
      if (patternControl !== undefined && patternControl !== null) {
        if (patternControl.value !== undefined && patternControl.value !== null) {
          const pattern = new RegExp(patternControl.value);
          return pattern.test(control.value) ? null : {invalidFormat: true};
        }
      }
      return null;
    };
  };

  static numberFormatValidator = function (format: NumberFormatDto | ZipCodeFormatDto, acceptEmpty: boolean) {
    if (format?.regExp) {
      const pattern = new RegExp(format.regExp);
      return (control: UntypedFormControl): {[key: string]: any} => {
        if ((control.value === null || control.value === undefined || control.value.length === 0) && acceptEmpty) {
          return null;
        }
        return pattern.test(control.value == null ? '' : control.value)
          ? null
          : format.formatDescription
          ? <ValidationErrors>{invalidFormatWithDescription: {formatDescription: format.formatDescription}}
          : <ValidationErrors>{invalidFormat: true};
      };
    } else {
      return;
    }
  };

  static emailFormat(control: AbstractControl): {[key: string]: boolean} {
    const pattern: RegExp = /\S+@\S+\.\S+/;
    if (!control.value) {
      return null;
    }
    return pattern.test(control.value) ? null : {emailFormat: true};
  }

  static chooseRequired(control: AbstractControl): {[key: string]: boolean} {
    if (!control.value || (typeof control.value === 'string' && control.value.length === 0)) {
      return {chooseRequired: true};
    }
    return null;
  }

  static userLoginFormat(control: AbstractControl): {[key: string]: boolean} {
    const pattern: RegExp = /^[A-Za-z0-9]+$/;
    if (!control.value) {
      return null;
    }
    return pattern.test(control.value) ? null : {userLoginFormat: true};
  }

  static userFirstNameFormat(control: AbstractControl): {[key: string]: boolean} {
    const pattern: RegExp = /^[A-Za-z\u0370-\u03FF]+([ -][A-Za-z\u0370-\u03FF]+)*$/;
    if (!control.value) {
      return null;
    }
    return pattern.test(CustomValidators.removeDiacriticsAndSpecialLetters(control.value.toString()))
      ? null
      : {invalidFormat: true};
  }

  static phoneNumberFormat(control: AbstractControl): {[key: string]: boolean} {
    const pattern: RegExp = /^[\x20\(\)\+\-0-9]+$/;
    if (!control.value) {
      return null;
    }
    return pattern.test(control.value) ? null : {invalidPhoneFormat: true};
  }

  static userFamilyNameFormat(control: AbstractControl): {[key: string]: boolean} {
    const pattern: RegExp = /^[A-Za-z\u0370-\u03FF]+[A-Za-z\u0370-\u03FF' -]*[A-Za-z\u0370-\u03FF]+$/;
    const antiPattern: RegExp = /[-']{2}/;
    if (!control.value) {
      return null;
    }
    const familyName: string = control.value.toString();
    if (!pattern.test(CustomValidators.removeDiacriticsAndSpecialLetters(familyName)) || antiPattern.test(familyName)) {
      return {invalidFormat: true};
    } else {
      return null;
    }
  }

  static number(control: UntypedFormControl): {[key: string]: boolean} {
    return isNaN(control.value) ? {number: true} : null;
  }

  static minValue = function (minValue: number, baseMultiplier?: number) {
    return function (control: UntypedFormControl): {[key: string]: any} {
      const v = +control.value * (baseMultiplier || 1);
      return control.value !== '' && v < minValue ? {minValue: {requiredValue: minValue, actualValue: v}} : null;
    };
  };

  static maxValue = function (maxValue: number | (() => number), baseMultiplier?: number, scale?: number) {
    return function (control: AbstractControl): {[key: string]: any} {
      const max = maxValue instanceof Function ? maxValue() : maxValue;
      const v = +control.value * (baseMultiplier || 1);
      return control.value !== '' && NumberUtils.round(v, scale || 2) > NumberUtils.round(max, scale || 2)
        ? {maxValue: {requiredValue: StringUtils.formatNumber(max), actualValue: v}}
        : null;
    };
  };

  static greaterThanValue = function (value: number, baseMultiplier?: number) {
    return function (control: AbstractControl) {
      const v = +control.value * (baseMultiplier || 1);
      return v <= value ? {greaterThanValue: {requiredValue: value, actualValue: v}} : null;
    };
  };

  static greaterOrEqualThanValue = function (value: number, baseMultiplier?: number) {
    return function (control: AbstractControl) {
      if (!control.value) {
        return null;
      }
      const v = +control.value * (baseMultiplier || 1);
      return v < value ? {greaterOrEqualThanValue: {requiredValue: value, actualValue: v}} : null;
    };
  };

  static greaterOrEqualThanValueFunc = function (value: () => number, baseMultiplier?: number) {
    return function (control: UntypedFormControl) {
      if (!control.value) {
        return null;
      }
      const v = +control.value * (baseMultiplier || 1);
      return v < value() ? {greaterOrEqualThanValue: {requiredValue: value(), actualValue: v}} : null;
    };
  };

  static lessOrEqualThanValue = function (value: number, baseMultiplier?: number) {
    return function (control: AbstractControl) {
      if (!control.value) {
        return null;
      }
      const v = +control.value * (baseMultiplier || 1);
      return v > value ? {lessOrEqThanValue: {requiredValue: value}} : null;
    };
  };

  static lessOrEqualThanValueFunc = function (value: () => number, baseMultiplier?: number) {
    return function (control: UntypedFormControl) {
      if (!control.value) {
        return null;
      }
      const v = +control.value * (baseMultiplier || 1);
      return v > value() ? {lessOrEqThanValue: {requiredValue: value()}} : null;
    };
  };

  static lessEqThanOtherField(field: string, baseMultiplier?: number) {
    return function (control: UntypedFormControl) {
      if (control.parent) {
        let v = control.parent.get(field).value;
        if (isNumeric(v)) {
          v = <any>v * (baseMultiplier || 1);
          const controlValue = control.value * (baseMultiplier || 1);
          return controlValue > v ? {lessOrEqThanValue: {requiredValue: v, actualValue: controlValue}} : null;
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  static lessEqThanSumOfFields(fields: Array<string>, baseMultiplier?: number) {
    return function (control: UntypedFormControl) {
      let sum = 0;
      if (control.parent) {
        for (const fieldName of fields) {
          const v = control.parent.get(fieldName).value;
          if (isNumeric(v)) {
            sum = sum + <any>v * (baseMultiplier || 1);
          }
        }
      } else {
        return null;
      }
      const controlValue = control.value * (baseMultiplier || 1);
      return controlValue > sum ? {lessOrEqThanValue: {requiredValue: sum, actualValue: controlValue}} : null;
    };
  }

  static eqSumOfFields(fields: Array<string>, baseMultiplier: number = 1) {
    return function (control: UntypedFormControl) {
      let sum = null;
      if (control.parent) {
        const values = Array<any>();
        for (const fieldName of fields) {
          values.push(control.parent.get(fieldName).value);
        }
        sum = CustomValidators.sumVal(values);
      } else {
        return null;
      }
      const controlValue = control.value * baseMultiplier;

      return sum === null || controlValue === sum ? null : {eqValue: {requiredValue: sum, actualValue: controlValue}};
    };
  }

  private static sumVal(values: Array<any>, baseMultiplier: number = 1): number | null {
    let sum = Big(0);
    let atLeasOneNumeric = false;
    for (const v of values) {
      if (isNumeric(v)) {
        sum = sum.plus(v);
        atLeasOneNumeric = true;
      }
    }
    if (!atLeasOneNumeric) {
      return null;
    }
    return sum * baseMultiplier;
  }

  // doesn't work: cannot use service in static method
  static vatNumber(group: UntypedFormControl) {
    const vat = group.value;
    const q = new Promise((resolve, reject) => {
      setTimeout(() => {
        console.log('vatValidator: vat = ' + vat);
        // cService.validateVat(vat).subscribe(
        //    validationResult => {console.log('Validation result = ' + validationResult.valid); resolve({'exists': true});},
        //    error => null);
        // if (vat === '12345') {
        //   console.log('vatValidator1: ' + vat);
        //   resolve({'exists': true});
        // } else {
        //   console.log('vatValidator2: ' + vat);
        //   resolve(null);
        // }
      }, 1000);
    });
    return q;
  }

  static minPremiumFixedEqualsPremiumInstalmentCalendarSum(group: UntypedFormGroup) {
    const premiumInstalments: boolean = group.controls['premiumInstalments'].value;
    if (!premiumInstalments) {
      return null;
    }
    const commitment: boolean = group.controls['commitment']?.value;
    if (commitment === true) {
      return null;
    }
    const minPremiumFixed: number = group.controls['minPremiumFixed'] ? +group.controls['minPremiumFixed'].value : 0;
    const premiumInstalmentCalendar: InstalmentCalendarEntryDto[] = group.controls['premiumInstalmentCalendar'].value;
    const subinsured: PolicyContractSubinsuredCompanyDto[] = group.controls['subinsured'].value;
    const minPremiumFixedValue = minPremiumFixed || 0;
    let premiumInstalmentCalendarSum = 0;
    if (premiumInstalmentCalendar && premiumInstalmentCalendar instanceof Array) {
      premiumInstalmentCalendarSum = premiumInstalmentCalendar.map((item) => item.value).reduce((x, y) => x + y, 0);
    }

    if (subinsured) {
      for (const s of subinsured) {
        if (s.premiumInstalementCalendar) {
          premiumInstalmentCalendarSum = s.premiumInstalementCalendar
            .map((item) => item.value)
            .reduce((x, y) => x + y, premiumInstalmentCalendarSum);
        }
      }
    }
    premiumInstalmentCalendarSum = Math.round(premiumInstalmentCalendarSum * 100) / 100 || 0;
    return minPremiumFixedValue === premiumInstalmentCalendarSum
      ? null
      : {
          valuesMismatch: {
            minuend: minPremiumFixedValue,
            subtrahend: premiumInstalmentCalendarSum,
            difference: Big(minPremiumFixedValue).minus(Big(premiumInstalmentCalendarSum)),
          },
        };
  }

  static requiredCompany(control: UntypedFormControl): {[key: string]: boolean} {
    if (!control.value) {
      return {requiredCompany: true};
    }

    const company: CompanyIdDto = <CompanyIdDto>control.value;
    if (!company.id) {
      return {requiredCompany: true};
    }
    return null;
  }

  static dateNotInPast(control: UntypedFormControl) {
    const date: Date = <Date>control.value;
    if (typeof date !== 'undefined' && date !== null) {
      return date.valueOf() - new Date().valueOf() > 0 ? null : {pastDate: true};
    }
    return null;
  }

  static dateNotNow(control: UntypedFormControl) {
    const date: Date = <Date>control.value;
    if (typeof date !== 'undefined' && date !== null) {
      return date.valueOf() - new Date().valueOf() !== 0 ? null : {nowDate: true};
    }
    return null;
  }

  static dateNotInFuture(control: UntypedFormControl) {
    const date: Date = <Date>control.value;
    if (typeof date !== 'undefined' && date !== null) {
      return new Date().valueOf() - date.valueOf() >= 0 ? null : {futureDate: true};
    }
    return null;
  }

  static validFromNotAfterValidTo(group: AbstractControl) {
    if (!group || !group.get('validFrom') || !group.get('validTo')) {
      return null;
    }
    const validFrom: Date = <Date>group.get('validFrom').value;
    const validTo: Date = <Date>group.get('validTo').value;
    if (!validFrom) {
      return null;
    }
    if (typeof validTo !== 'undefined' && validTo !== null) {
      return validTo.valueOf() - validFrom.valueOf() >= 0 ? null : {validFromAfterValidTo: true};
    }
    return null;
  }

  static minDate = function (date: Date, formatService: FormatService) {
    return (control: AbstractControl): {[key: string]: any} => {
      return moment(control.value).isBefore(moment(date)) ? {minDate: {minDate: formatService.formatDate(date)}} : null;
    };
  };

  static maxDate = function (date: Date, formatService: FormatService) {
    return (control: UntypedFormControl): {[key: string]: any} => {
      return moment(control.value).isAfter(moment(date)) ? {maxDate: {maxDate: formatService.formatDate(date)}} : null;
    };
  };

  static numberMinMax(min: number, max: number) {
    return (control: UntypedFormControl): {[key: string]: boolean} => {
      if (!control || !control.value) {
        return null;
      }
      const score: number = +control.value;
      if (isNaN(score) || score < min || score > max || score % 1 !== 0) {
        return {numberMinMaxFormat: true};
      }
      return null;
    };
  }

  static conditionalValidator(
    control: AbstractControl,
    pred: () => boolean,
    valid: ValidatorFn
  ): {[key: string]: boolean} {
    const p = pred.bind(control);
    if (p()) {
      return valid(control);
    } else {
      return null;
    }
  }

  static passwordConfirmValidation(group: AbstractControl) {
    if (group.get('newPassword') && group.get('retypePassword')) {
      let newPassword: string = group.get('newPassword').value;
      let retypePassword: string = group.get('retypePassword').value;
      if (newPassword === null || typeof newPassword === 'undefined') {
        newPassword = '';
      }
      if (retypePassword === null || typeof retypePassword === 'undefined') {
        retypePassword = '';
      }
      if (newPassword !== retypePassword) {
        return {passwordsMismatch: true};
      }
    }
    return null;
  }

  static credendoPasswordStrength(control: UntypedFormControl) {
    const value = control.value;
    if (value === null || typeof value === 'undefined' || value === '') {
      return null;
    }
    const pattern: RegExp = /^.{14,}$/;
    if (!pattern.test(value)) {
      return {passwordMinLength: {requiredLength: 14}};
    }
    const p1: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])[a-zA-Z0-9!@#$%^&*_]{14,}$/;
    if (p1.test(value)) {
      return null;
    }
    return {atLeastOneLetterAndNumber: true};
  }

  static ecgPasswordStrength(control: UntypedFormControl) {
    const value = control.value;
    if (value === null || typeof value === 'undefined' || value === '') {
      return null;
    }
    const pattern: RegExp = /^.{12,}$/;
    if (!pattern.test(value)) {
      return {passwordMinLength: {requiredLength: 12}};
    }
    const p1: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])[a-zA-Z0-9!@#$%^&*_]{12,}$/;
    if (p1.test(value)) {
      return null;
    }
    return {atLeastOneLetterAndNumber: true};
  }

  static kukePasswordStrength(control: UntypedFormControl) {
    const value = control.value;
    if (value === null || typeof value === 'undefined' || value === '') {
      return null;
    }
    const pattern: RegExp = /^.{12,}$/;
    if (!pattern.test(value)) {
      return {passwordMinLength: {requiredLength: 12}};
    }
    const p1: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])[a-zA-Z0-9!@#$%^&*_]{12,}$/;
    if (p1.test(value)) {
      return null;
    }
    return {atLeastOneLetterAndNumber: true};
  }

  // static maxTotalLiabilityLt10PercentTurnover(group: FormGroup) {
  //   let maxTotalLiability: number = +group.get('contractMaxTotalLiability').value;
  //   let turnover: number = +group.get('client').get('turnover').value;
  //   if (maxTotalLiability * 10 > turnover) {
  //     return {'maxTotalLiabilityGt10PercentTurnover': true};
  //   }
  //   return null;
  // }

  static optionalRequiredValidate(
    group: UntypedFormGroup,
    checkboxControlName: string,
    valueControlName: string,
    errorName?: string
  ): {[key: string]: boolean} {
    const required = !!group.controls[checkboxControlName] && group.controls[checkboxControlName].value;
    const value = group.controls[valueControlName] ? group.controls[valueControlName].value : null;
    const errorN = errorName ? errorName : valueControlName + 'Required';
    if (!value && required) {
      return {[errorN]: true};
    }
    return null;
  }

  // creates list of validators corresponding to the list of pairs {checkboxControlName: string, valueControlName: string}
  // each pair is a control name for checkbox field a control name for the corresponding value field
  // the value is required if the checkbox is checked
  static optionalFieldValidators(
    pairs: {checkboxControlName: string; valueControlName: string}[]
  ): ((v: UntypedFormGroup) => {[key: string]: boolean})[] {
    return pairs.map(
      (v) => (g: UntypedFormGroup) =>
        CustomValidators.optionalRequiredValidate(g, v.checkboxControlName, v.valueControlName)
    );
  }
}
