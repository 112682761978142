import {Component, OnInit} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService} from '../../../../bonding_shared';
import {DictionaryProfile, FrontingType, PolicyContractType} from '../../../../bonding_shared/model/dictionary-ids';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'premium-section',
  templateUrl: './premium-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class PremiumSectionComponent extends Section implements OnInit {
  self = this;
  readonly FrontingType = FrontingType;
  readonly DictionaryProfile = DictionaryProfile;

  def: RegExp = /^(?:SUBINSURED|MAIN_INSURED_UNGROUPED)$/;
  defWithFront: RegExp = /^(?:SUBINSURED|MAIN_INSURED_UNGROUPED|FRONTING)$/;

  constructor(protected policyContractViewService: PolicyContractViewService, public appService: AppConfigService) {
    super(policyContractViewService, appService);
  }

  ngOnInit(): void {
    // TODO: remove kuke logic
    // if (this.isSectionVisible(this.viewSection.PREMIUM__KUKE_FIXED_MIN_PREMIUMS) && !this.policy.fixedMinPremiums) {
    //   this.policy.fixedMinPremiums = [];
    // }
  }

  onChangeInTurnoverChanged(checked: boolean) {
    if (checked) {
      this.policy.turnoverRates = [];
    } else {
      delete this.policy.turnoverRates;
    }
  }
  isType(policyContractTypeId: PolicyContractType) {
    return this.policy && this.policy.contractType && this.policy.contractType.id === policyContractTypeId;
  }

  isPremiumOnSubinsuredLevelDisabled() {
    return this.frontingOfType(FrontingType.FRONTING) || this.frontingOfType(FrontingType.FRONTING_ASSUMED);
  }

  isPremiumRateDisabled() {
    return this.policy.premiumFixed;
  }
}
