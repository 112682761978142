import {Observable, of} from 'rxjs';

import {filter, find, map, mergeMap, toArray} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {
  AddressDto,
  CityBaseDto,
  DictionaryBaseDto,
  DictionaryDto,
  ZipCodeFormatDto,
} from '../../../../bonding_shared/model/dtos';
import {DictionaryService} from '../../../../bonding_shared/services/dictionary.service';
import {DictionaryPropertyType} from '../../../../bonding_shared/model/dictionary-ids';
import {AddressAutocompleteService} from '../../../../bonding_shared/services/address-autocomplete.service';
import {CustomValidators} from '../../../../bonding_shared/validators';
import {Country} from '../../../../bonding_shared/model';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table';
import {AppConfigService} from '../../../../bonding_shared/services';
import {ZipCodeFormatService} from '../../../../bonding_shared/services/zip-code-format.service';

@Component({
  selector: 'address-table',
  templateUrl: 'address-table.component.html',
  providers: [AddressAutocompleteService],
})
export class AddressTableComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() controlName = 'address';
  @Input() addresses: AddressDto[];
  @Input() showErrors: boolean;
  @Input() isCountryConst: boolean;
  @Input() isCountryEditable = true;
  @Input() editable = true;
  @Input() hiddenAddressTypes: Set<number>;
  @Input() oneTypeMode: boolean;
  @Input() hideType: boolean;
  @Input() zipCodeRequired = false;
  @Input() provinceRequired = false;
  @Input() provinceCodeVisible = true;
  @Input() commonFieldsRequired = true;
  @Input() poBoxHidden = false;
  @Input() countryDictionaryProfileId: number;
  @Input() inProgress: boolean;

  @Output() addAddress = new EventEmitter<AddressDto>();
  @Output() countryChange = new EventEmitter<DictionaryBaseDto>();

  @ViewChild(ATableComponent, {static: true}) table: ATableComponent<AddressDto>;

  countries: DictionaryBaseDto[];
  zipCodeFormatDtos: ZipCodeFormatDto[];

  constructor(
    private dictService: DictionaryService,
    public appService: AppConfigService,
    private addressPostcodeAutocompleteService: AddressAutocompleteService,
    private zipCodeFormatService: ZipCodeFormatService
  ) {}

  private getOneCity(cities: CityBaseDto[]) {
    if (cities && cities.length === 1) {
      return cities[0];
    } else {
      return null;
    }
  }

  ngOnInit() {
    this.dictService.getDictionary('Country').subscribe((entries) => {
      this.countries = entries
        .filter((d) => d.properties[DictionaryPropertyType.COUNTRY_BOND] === 'true')
        .map((d) => cloneDictNoProperties(d));
    });

    function cloneDictNoProperties(dict: DictionaryDto): DictionaryBaseDto {
      const ret: DictionaryDto = Object.assign({}, dict);
      delete ret.properties;
      return <DictionaryBaseDto>ret;
    }

    // load all regular expression for zip codes
    this.getAllZipCodeRegexp();
  }

  townsSupplier(address: AddressDto): (string) => Observable<any[]> {
    return this.supplier(address, (countryId) => this.addressPostcodeAutocompleteService.getCityBaseGeoDict(countryId));
  }

  provinceSupplier(address: AddressDto): (string) => Observable<any[]> {
    return this.supplier(address, (countryId) => this.addressPostcodeAutocompleteService.getProvinceGeoDict(countryId));
  }

  postcodesSupplier(address: AddressDto): (string) => Observable<any[]> {
    return this.supplier(address, (countryId) => this.addressPostcodeAutocompleteService.getPostCodeGeoDict(countryId));
  }

  private supplier(
    address: AddressDto,
    getAutocompletes: (number) => Observable<any[]>
  ): (string) => Observable<any[]> {
    return (keyword: string) => {
      if (!this.getCountryId(address) || !keyword) {
        return of([]);
      }

      return getAutocompletes(this.getCountryId(address)).pipe(
        map((d) => {
          return d.map((c) => c.name).filter((c) => c.toLowerCase().startsWith(keyword.toLowerCase()));
        })
      );
    };
  }

  onPostcodeChange(address: AddressDto) {
    this.resetControls();
    // find city based on postcode and set it if only one is matched
    if (this.getCountryId(address) && address.zipCode) {
      this.addressPostcodeAutocompleteService
        .getPostCodeGeoDict(this.getCountryId(address))
        .pipe(
          mergeMap((pcodes) => pcodes),
          find((pcode) => pcode.name === address.zipCode)
        )
        .subscribe((pcode) => {
          if (pcode) {
            const city = this.getOneCity(pcode.cities);
            if (city) {
              address.town = city.name;
            }
            if (pcode.province) {
              address.province = pcode.province.name;
              address.provinceCode = pcode.province.code;
            }
          }
        });
    }
  }

  onTownChange(address: AddressDto) {
    this.resetControls();
    // find postcode based on city and set it if only one is matched
    if (this.getCountryId(address) && address.town) {
      this.addressPostcodeAutocompleteService
        .getPostCodeGeoDict(this.getCountryId(address))
        .pipe(
          mergeMap((pcodes) => pcodes),
          filter((pcode) => {
            const city = this.getOneCity(pcode.cities);
            return city && city.name === address.town;
          }),
          toArray()
        )
        .subscribe((pcodes) => {
          if (pcodes && pcodes.length === 1) {
            address.zipCode = pcodes[0].name;
            if (pcodes[0].province) {
              address.province = pcodes[0].province.name;
              address.provinceCode = pcodes[0].province.code;
            }
          }
        });
    }
  }

  onProvinceChange(address: AddressDto) {
    if (this.getCountryId(address) && address.province) {
      this.addressPostcodeAutocompleteService
        .getProvinceGeoDict(this.getCountryId(address))
        .pipe(
          mergeMap((pcodes) => pcodes),
          find((pcode) => pcode.name === address.province)
        )
        .subscribe((pcode) => {
          if (pcode && pcode.code) {
            address.provinceCode = pcode.code;
          }
        });
    }
  }

  onProvinceCodeChange(address: AddressDto) {
    if (this.getCountryId(address) && address.provinceCode) {
      this.addressPostcodeAutocompleteService
        .getProvinceGeoDict(this.getCountryId(address))
        .pipe(
          mergeMap((pcodes) => pcodes),
          find((pcode) => pcode.code === address.provinceCode)
        )
        .subscribe((pcode) => {
          if (pcode && pcode.name) {
            address.province = pcode.name;
          }
        });
    }
  }

  private getCountryId(address: AddressDto): number {
    return address && address.country && address.country.id;
  }

  getZipCodePatternValidator(): (AddressDto) => ValidatorFn {
    return (address) => CustomValidators.numberFormatValidator(this.getZipCodeRegexp(address), true);
  }

  getZipCodeRegexp(address: AddressDto): ZipCodeFormatDto {
    if (this.zipCodeFormatDtos && this.zipCodeFormatDtos.length > 1 && address?.country) {
      const zipCodeFormatDtoForSelectedCountry = this.zipCodeFormatDtos.find(
        (item) => item.country.id === address.country.id
      );
      return zipCodeFormatDtoForSelectedCountry ? zipCodeFormatDtoForSelectedCountry : undefined;
    } else {
      return undefined;
    }
  }

  private getAllZipCodeRegexp() {
    if (this.zipCodeFormatDtos) {
      this.zipCodeFormatDtos = [];
    }
    this.inProgress = true;
    this.zipCodeFormatService.getAllZipCodeFormats().subscribe(
      (zipCode) => {
        this.zipCodeFormatDtos = zipCode;
        this.inProgress = false;
      },
      (error) => {
        this.inProgress = false;
      }
    );
  }

  resetControls() {
    this.table.resetControls();
  }

  kuke() {
    return this.appService.kuke;
  }
}
