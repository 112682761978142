import {Component, Input, OnInit} from '@angular/core';
import {
  AppConfigService,
  DeclarationDto,
  DeclarationEntryNNDto,
  DictionaryBaseService,
  DictionaryService,
  GrowlService,
  LoggedUserService,
  SearchResult,
} from '../../../../bonding_shared';
import {ControlContainer, NgForm} from '@angular/forms';
import {Observable} from 'rxjs';
import {DeclarationService} from '../../../../bonding_shared/services/declaration.service';
import {TranslateService} from '@ngx-translate/core';
import {DeclarationAbstractSection} from './declaration-abstract-section';

@Component({
  selector: 'declaration-single-section',
  templateUrl: './declaration-single-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class DeclarationSingleSectionComponent extends DeclarationAbstractSection implements OnInit {
  @Input() set declarationSearchResult(decSR: SearchResult<DeclarationDto>) {
    if (decSR && decSR.result) {
      this.setDeclaration(decSR.result[0]);
      this.updateEditableMaps();
    } else {
      this.setDeclaration(undefined);
    }
  }

  constructor(
    protected dictService: DictionaryBaseService,
    protected dictGlobalService: DictionaryService,
    protected growlService: GrowlService,
    protected appService: AppConfigService,
    protected loggedUserService: LoggedUserService,
    protected translateService: TranslateService,
    protected declarationService: DeclarationService
  ) {
    super(
      dictService,
      dictGlobalService,
      growlService,
      appService,
      loggedUserService,
      translateService,
      declarationService
    );
  }

  ngOnInit(): void {
    // required because the component is dynamically attached
    this.declarationService.getValidNNRoles().subscribe({
      next: (validNNRoles) => (this.validNNRoles = validNNRoles),
    });
  }

  declarationTouched(event: any) {
    if (!this.preview && !this.blockEntriesEdition) {
      this.declarationEntrySelect.emit(undefined);
      this.declarationEntryUnselect.emit();
    }
  }

  getGeneralNNs(): DeclarationEntryNNDto[] {
    return this.declaration.generalNNs;
  }
  getDomesticNNs(): DeclarationEntryNNDto[] {
    return this.declaration.domesticNNs;
  }
  getExportNNs(): DeclarationEntryNNDto[] {
    return this.declaration.exportNNs;
  }
}
