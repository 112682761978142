import {AfterViewInit, Component, Input} from '@angular/core';
import {
  DictionaryBaseDto,
  DictionaryBaseService,
  Page,
  PolicyMonitoringVersionBaseDto,
  PolicyMonitoringVersionCriteriaDto,
  PolicyMonitoringVersionDto,
  PolicyMonitoringVersionService,
  RouterService,
  SearchCriteria,
} from '../../../../bonding_shared';
import {PolicyMonitoringType} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'policy-monitoring-list',
  templateUrl: 'policy-monitoring-list.component.html',
})
export class PolicyMonitoringListComponent implements AfterViewInit {
  @Input() policyContractId: number;
  @Input() productTypeId: number;
  public policyMonitoringVersions: PolicyMonitoringVersionBaseDto[];
  public policyMonitoringTypes: DictionaryBaseDto[] = [];

  constructor(
    private routerService: RouterService,
    private policyMonitoringVersionService: PolicyMonitoringVersionService,
    private dictionaryBaseService: DictionaryBaseService
  ) {}

  ngAfterViewInit(): void {
    this.initializeList(this.policyContractId);
    this.setDictionary();
  }

  private setDictionary() {
    this.dictionaryBaseService
      .getDictionaryBase('PolicyMonitoringType')
      .subscribe((recoveryTypes: DictionaryBaseDto[]) => this.setPolicyMonitoringTypes(recoveryTypes));
  }

  private setPolicyMonitoringTypes(pmvt: DictionaryBaseDto[]) {
    this.policyMonitoringTypes = pmvt;
  }

  toPolicyMonitoringVersion(pmv: PolicyMonitoringVersionDto) {
    if (pmv.id) {
      this.routerService.toPolicyMonitoringVersionDetailVersionId(pmv.id);
    } else {
      this.routerService.toPolicyMonitoringVersionDetailNew(this.policyContractId, pmv.policyMonitoring.type.id);
    }
  }

  initializeList(policyContractId: number) {
    const criteria = <SearchCriteria<PolicyMonitoringVersionCriteriaDto>>{};
    criteria.criteria = <PolicyMonitoringVersionCriteriaDto>{};
    criteria.criteria.policyContractId = policyContractId;
    criteria.criteria.last = true;
    criteria.page = <Page>{start: 0, count: 300};
    this.policyMonitoringVersionService
      .searchByCriteria<PolicyMonitoringVersionCriteriaDto, PolicyMonitoringVersionBaseDto>(criteria)
      .subscribe((search) => {
        this.policyMonitoringVersions = search.result;
      });
  }
}
