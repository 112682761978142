import {BusinessUnitDto, CompanyDto, ContractVersionSimpleDto, ParameterDefinitionDto} from '../../model';
import {ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ComboItem} from '../combos';
import {ListEmitters} from '../details-view/list-emitters';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment from 'moment';
import {AppConfigService, RouterService} from '../../services';

const PARAMETER_LIST_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ParameterListComponent),
  multi: true,
};

@Component({
  selector: 'parameter-list',
  templateUrl: 'parameter-list.component.html',
  providers: [PARAMETER_LIST_CONTROL_VALUE_ACCESSOR],
})
export class ParameterListComponent extends ListEmitters implements ControlValueAccessor {
  readonly CESSION_VERSION_PARAM_NAME = 'cessionVersionId';
  private _parameterValues: any = {};

  get parameterValues(): any {
    return this._parameterValues;
  }

  set parameterValues(pv: any) {
    this._parameterValues = pv;
    this.onChangeModel(pv);
  }

  constructor(private cd: ChangeDetectorRef, public router: RouterService, private appService: AppConfigService) {
    super();
  }

  _parameterDefinitions: ParameterDefinitionDto[];
  @Input() showErrors = false;
  yesNoItems = [new ComboItem('true', 'Yes'), new ComboItem('false', 'No')];
  companies: {[key: string]: CompanyDto} = {};
  companyParameterDefinitions: ParameterDefinitionDto[];
  contractParameterDefinitions: ParameterDefinitionDto[];
  businessUnitParameterDefinitions: ParameterDefinitionDto[] = [];
  contractVersions: {[key: string]: ContractVersionSimpleDto} = {};
  businessUnits: {[key: string]: BusinessUnitDto} = {};

  onChangeModel: Function;
  onTouchedModel: Function;

  @Input() paramsFixed = false;

  @Input()
  set parameterDefinitions(parameterDefinitions: ParameterDefinitionDto[]) {
    this.setParameters(parameterDefinitions ? parameterDefinitions : []);
  }
  get parameterDefinitions() {
    return this._parameterDefinitions;
  }

  private setParameters(parameterDefinitions: ParameterDefinitionDto[]) {
    this.resetValues();

    this.showErrors = false;

    this._parameterDefinitions = parameterDefinitions;
    this.selectorNameList = [];
    this.companyParameterDefinitions = [];
    this.contractParameterDefinitions = [];
    for (const parameter of parameterDefinitions) {
      if (this.isCompany(parameter)) {
        this.companyParameterDefinitions.push(parameter);
        this.selectorNameList.push(parameter.name);
      }
      if (this.isContract(parameter)) {
        this.contractParameterDefinitions.push(parameter);
        this.selectorNameList.push(parameter.name);
      }
      if (this.isBusinessUnit(parameter)) {
        this.businessUnitParameterDefinitions.push(parameter);
      }
      if (parameter.defaultValue) {
        this._parameterValues[parameter.name] = this.isDate(parameter)
          ? moment(parameter.defaultValue).toDate()
          : parameter.defaultValue;
      }
    }
    this.initializeSelectorEmitters(true);
  }

  private resetValues() {
    if (this.parameterDefinitions && this.parameterDefinitions.length > 0) {
      for (const key of Object.keys(this.parameterValues)) {
        delete this.parameterValues[key];
      }
    }
    this.companies = {};
    this.contractVersions = {};
    this.businessUnits = {};
  }

  getType(parameterDefinition: ParameterDefinitionDto): String {
    if (
      String(parameterDefinition.clazz) === 'java.sql.Date' ||
      String(parameterDefinition.clazz) === 'java.util.Date' ||
      String(parameterDefinition.clazz) === 'java.time.LocalDate'
    ) {
      return 'date';
    } else if (
      String(parameterDefinition.clazz) === 'java.sql.Time' ||
      String(parameterDefinition.clazz) === 'java.time.LocalDateTime'
    ) {
      return 'datetime';
    } else if (String(parameterDefinition.clazz) === 'java.lang.Integer') {
      return 'number';
    } else if (String(parameterDefinition.clazz) === 'java.lang.Boolean') {
      return 'boolean';
    }
    return 'string';
  }

  isDate(parameterDefinition: ParameterDefinitionDto): boolean {
    return (
      String(parameterDefinition.clazz) === 'java.sql.Date' ||
      String(parameterDefinition.clazz) === 'java.sql.Time' ||
      String(parameterDefinition.clazz) === 'java.util.Date' ||
      String(parameterDefinition.clazz) === 'java.time.LocalDate' ||
      String(parameterDefinition.clazz) === 'java.time.LocalDateTime'
    );
  }

  isText(parameterDefinition: ParameterDefinitionDto): boolean {
    return (
      String(parameterDefinition.clazz) === 'java.lang.String' ||
      String(parameterDefinition.clazz) === 'java.lang.Integer'
    );
  }

  isNumber(parameterDefinition: ParameterDefinitionDto): boolean {
    return String(parameterDefinition.clazz) === 'java.math.BigDecimal';
  }

  isBoolean(parameterDefinition: ParameterDefinitionDto): boolean {
    return String(parameterDefinition.clazz) === 'java.lang.Boolean';
  }

  isDictionary(parameterDefinition: ParameterDefinitionDto): boolean {
    if (this.getDictionary(parameterDefinition)) {
      return true;
    }
    return false;
  }

  getDictionary(parameterDefinition: ParameterDefinitionDto): String {
    if (parameterDefinition) {
      const groups = String(parameterDefinition.clazz).match(/[\w\.]+\.dict\.(\w+)/);
      if (groups && groups.length > 1) {
        return groups[0];
      }
    }
    return undefined;
  }

  isCompany(parameterDefinition: ParameterDefinitionDto): boolean {
    return String(parameterDefinition.clazz) === 'pl.accuratus.isa.company.model.Company';
  }

  isBusinessUnit(parameterDefinition: ParameterDefinitionDto): boolean {
    return ['pl.accuratus.isa.bu.model.BusinessUnit', 'pl.accuratus.isa.bu.dto.BusinessUnitDto'].includes(
      String(parameterDefinition.clazz)
    );
  }

  isContract(parameterDefinition: ParameterDefinitionDto): boolean {
    return String(parameterDefinition.clazz) === 'pl.accuratus.isa.bondingcontract.contract.model.Contract';
  }

  isCessionVersion(parameterDefinition: ParameterDefinitionDto): boolean {
    return String(parameterDefinition.clazz) === 'pl.accuratus.isa.cession.model.CessionVersion';
  }

  setCompany(parameterDefinition: ParameterDefinitionDto, company: CompanyDto) {
    this.companies[parameterDefinition.name] = company;
    console.log('setCompany', company);
    if (company) {
      this._parameterValues[parameterDefinition.name] = company.id;
    } else {
      this._parameterValues[parameterDefinition.name] = undefined;
    }
  }

  setContractVersion(parameterDefinition: ParameterDefinitionDto, contractVersion: ContractVersionSimpleDto) {
    this.contractVersions[parameterDefinition.name] = contractVersion;
    console.log('setContract', contractVersion);
    if (contractVersion) {
      this._parameterValues[parameterDefinition.name] = contractVersion.contract.id;
    } else {
      this._parameterValues[parameterDefinition.name] = undefined;
    }
  }
  setBusinessUnit(parameterDefinition: ParameterDefinitionDto, bu: BusinessUnitDto) {
    this.businessUnits[parameterDefinition.name] = bu;
    console.log('setBusinessUnit', bu);
    if (bu) {
      this._parameterValues[parameterDefinition.name] = bu.id;
    } else {
      this._parameterValues[parameterDefinition.name] = undefined;
    }
  }

  writeValue(pv: any): void {
    this._parameterValues = pv;
  }

  registerOnChange(fn: any): void {
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedModel = fn;
  }

  parse(value: any) {
    if (value instanceof Date) {
      return value.toISOString();
    } else if (value.id) {
      return value.id;
    } else if (typeof value === 'number' && Number.isSafeInteger(value)) {
      return value;
    } else {
      return encodeURIComponent(value);
    }
  }

  onDateRowUpdate() {}

  backToCession() {
    this.router.toCessionDetails(+this.parameterValues[this.CESSION_VERSION_PARAM_NAME]);
  }
}
