import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {
  AppConfigService,
  BondingContractInquiryVersionSimpleDto,
  BrokerContractDto,
  BrokerContractOptionDto,
  BrokerContractRelationService,
  BrokerContractRelationSimpleDto,
  BrokerContractRelationVersionCriteriaDto,
  BrokerContractRelationVersionDto,
  BrokerContractRelationVersionSimpleDto,
  BrokerContractVersionService,
  BrokerContractVersionSimpleDto,
  BusinessObjectDto,
  ConfirmDialogComponent,
  ContractVersionService,
  ContractVersionSimpleDto,
  DictionaryBaseDto,
  DictionaryIfc,
  DocumentService,
  GrowlService,
  InquiryService,
  LoggedUserService,
  PolicyContractVersionService,
  PolicyContractVersionSimpleDto,
  PolicyInquiryVersionSimpleDto,
  RouterService,
  SearchCriteria,
  SearchDataProvider,
  SearchResult,
  StateTransitionDto,
  TemplateService,
  TemplateSimpleDto,
} from '../../bonding_shared';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  BrokerContractOptionType,
  BrokerContractOptionValue,
  BrokerContractStatus,
  BrokerRelationStatus,
  BrokerRelationTypeOfAgreement,
  BrokerType,
  BusinessObjectType,
  DocumentType,
  ElementaryRight,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {TextSearchCriteria} from '../../bonding_shared/components/search/model/text-search-criteria';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import * as _ from 'lodash';
import {BondingContractInquiryService} from '../../bonding_shared/services/bonding-contract-inquiry.service';
import {DateUtils} from '../../bonding_shared/utils/date-utils';

@Component({
  selector: 'broker-contract-relation-details',
  templateUrl: './broker-contract-relation-details.component.pug',
})
export class BrokerContractRelationDetailsComponent extends DetailsView implements OnInit {
  public brokerContract: BrokerContractDto;
  public selectedPolicy: PolicyContractVersionSimpleDto;
  public selectedContract: ContractVersionSimpleDto;
  public selectedInquiry: PolicyInquiryVersionSimpleDto;
  public relatedToId: number;
  public relationVersion: BrokerContractRelationVersionDto;
  public relationVersions: BrokerContractRelationVersionDto[];
  public policySearchCriteria = new TextSearchCriteria();
  public contractSearchCriteria = new TextSearchCriteria();
  public selectedBondingContractInquiry: BondingContractInquiryVersionSimpleDto;
  public readonly BusinessObjectType = BusinessObjectType;
  public readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  public readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  private relatedToTypeId: number;
  private relationsDataProvider: SearchDataProvider<
    BrokerContractRelationVersionCriteriaDto,
    BrokerContractRelationVersionDto
  >;
  private allPremiumNA = false;
  @ViewChild('ngForm', {static: true}) private ngForm: NgForm;
  @ViewChild('confirmDialog', {static: true}) public confirmDialog: ConfirmDialogComponent;

  constructor(
    private brokerContractService: BrokerContractVersionService,
    public brokerRelationService: BrokerContractRelationService,
    private policyService: PolicyContractVersionService,
    private contractService: ContractVersionService,
    private route: ActivatedRoute,
    public routerService: RouterService,
    protected growlService: GrowlService,
    private changeDetector: ChangeDetectorRef,
    private inquiryService: InquiryService,
    private bondingContractInquiryService: BondingContractInquiryService,
    private templateService: TemplateService,
    private documentService: DocumentService,
    public appService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {
    super(growlService);
    this.saveButton.hidden = false;
    this.cancelButton.hidden = false;
    this.newVersionButton.hidden = false;
    this.initializeSelectors();
    this.relationsDataProvider = BusinessUtils.createBrokerRelationDataProvider(this.brokerRelationService);
    this.policySearchCriteria.last = true;
    this.policySearchCriteria.lastPolicyYear = true;
    this.contractSearchCriteria.last = true;
    this.contractSearchCriteria.active = true;
  }

  templates: TemplateSimpleDto[] = [];

  private static isPremium(opt: BrokerContractOptionDto): boolean {
    return (
      opt && [BrokerContractOptionType.PREMIUM, BrokerContractOptionType.ADDITIONAL_PREMIUM].includes(opt.optionType.id)
    );
  }

  public ngOnInit(): void {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) =>
      this.initView(
        +params['relationVersionId'],
        +params['brokerContractId'],
        +params['relatedToId'],
        +params['relatedToTypeId']
      )
    );
  }

  public onSave(): void {
    this.serverErrors = undefined;
    if (!this.formValidates()) {
      return;
    }
    if (this.kuke && this.newRelationForAgentClosedBrokerContract()) {
      this.showBrokerContractClosedConfirmationDialog();
    } else if (this.kuke && this.commissionStartDateInPast()) {
      this.showCommissionStartDateInPastConfirmationDialog();
    } else {
      this.save();
    }
  }

  public onCancel(): void {
    this.clearErrors();
    this.form.reset();
    if (!this.relationVersion?.brokerRelation?.id) {
      this.brokerContract = null;
    } else {
      this.form.get('brokerContract').setValue(this.brokerContract);
    }
  }

  public onCreateNewVersion(): void {
    this.clearErrors();
    this.newVersion(this.relationVersion.brokerRelation.id);
  }

  public showLifecycleConfirmation(): (transition: StateTransitionDto) => boolean {
    return (transition: StateTransitionDto) =>
      [BrokerRelationStatus.CANCELLED, BrokerRelationStatus.USER_ERROR].includes(transition.newStatus.id) ||
      (this.commissionStartDateInPast() && transition.newStatus.id === BrokerRelationStatus.ACTIVATED);
  }

  public customLifecycleConfirmationTextKey(): (transition: StateTransitionDto) => string {
    return (transition: StateTransitionDto) => {
      if (transition.newStatus.id === BrokerRelationStatus.ACTIVATED) {
        return 'brokerContract.relation.details.commissionStartDateInPastWarningMessage';
      }
      if (transition.newStatus.id === BrokerRelationStatus.CANCELLED) {
        return 'brokerContract.relation.details.cancellationWarning';
      }
      if (transition.newStatus.id === BrokerRelationStatus.USER_ERROR) {
        return 'brokerContract.relation.details.userErrorWarning';
      }
      return null;
    };
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }

  public get ecg(): boolean {
    return this.appService.ecg;
  }

  public otherTypeOfAgreementChosen(): boolean {
    return _(this.relationVersion.typesOfAgreement)
      .map((typeOfAgreement: DictionaryBaseDto) => typeOfAgreement.id)
      .includes(BrokerRelationTypeOfAgreement.OTHER);
  }

  public typeOfAgreementChanged(): void {
    if (!this.otherTypeOfAgreementChosen()) {
      this.relationVersion.otherTypeOfAgreement = null;
    }
  }

  public isBrokerRelationStatusActivated(): boolean {
    return this.isBrokerContractRelationInStatus(BrokerRelationStatus.ACTIVATED);
  }

  public isBrokerRelationStatusInactive(): boolean {
    return this.isBrokerContractRelationInStatus(BrokerRelationStatus.INACTIVE);
  }

  public isBrokerRelationStatusCancelled(): boolean {
    return this.isBrokerContractRelationInStatus(BrokerRelationStatus.CANCELLED);
  }

  public onSelectPolicy(policyContractVersion: PolicyContractVersionSimpleDto): void {
    this.selectedPolicy = policyContractVersion;
    this.relatedToId = policyContractVersion.policyContract.masterPolicyContract.id;
    this.refreshRelationList(
      this.brokerContract.id,
      BusinessObjectType.MASTER_POLICY,
      policyContractVersion.policyContract.masterPolicyContract.id,
      true
    );
    this.checkActiveRelationsToBusinessObject();
  }

  public onSelectContract(contractVersion: ContractVersionSimpleDto): void {
    this.selectedContract = contractVersion;
    this.relatedToId = contractVersion.contract.id;
    this.refreshRelationList(this.brokerContract.id, BusinessObjectType.CONTRACT, contractVersion.contract.id, true);
    this.checkActiveRelationsToBusinessObject();
  }

  public onSelectInquiry(policyInquiryVersion: PolicyInquiryVersionSimpleDto): void {
    this.selectedInquiry = policyInquiryVersion;
    this.relatedToId = policyInquiryVersion.id;
    this.refreshRelationList(this.brokerContract.id, BusinessObjectType.CONTRACT, policyInquiryVersion.id, true);
    this.checkActiveRelationsToBusinessObject();
  }

  public onSelectBrokerContract(brokerContractVersion: BrokerContractVersionSimpleDto): void {
    this.loadBrokerContract(brokerContractVersion.brokerContract.id);
    this.refreshRelationList(brokerContractVersion.brokerContract.id, this.relatedToTypeId, this.relatedToId, true);
  }

  public onSelectVersion(linkVersion: BrokerContractRelationVersionSimpleDto): void {
    this.serverErrors = undefined;
    this.loadRelation(linkVersion.id, false);
  }

  public isPolicyRelation(): boolean {
    return this.relatedToTypeId === BusinessObjectType.MASTER_POLICY;
  }

  public isContractRelation(): boolean {
    return this.relatedToTypeId === BusinessObjectType.CONTRACT;
  }

  public isInquiryRelation(): boolean {
    return this.relatedToTypeId === BusinessObjectType.POLICY_INQUIRY_VERSION;
  }

  public isBondingContractInquiryRelation(): boolean {
    return this.relatedToTypeId === BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION;
  }

  public isBrokerTypeAgent(): boolean {
    return (
      (this.brokerContract &&
        this.brokerContract.brokerType &&
        this.brokerContract.brokerType.id === BrokerType.AGENT) ||
      false
    );
  }

  public isBrokerTypeMultiAgent(): boolean {
    return (
      (this.brokerContract &&
        this.brokerContract.brokerType &&
        this.brokerContract.brokerType.id === BrokerType.MULTI_AGENT) ||
      false
    );
  }

  public isBrokerTypeBroker(): boolean {
    return (
      (this.brokerContract &&
        this.brokerContract.brokerType &&
        this.brokerContract.brokerType.id === BrokerType.BROKER) ||
      false
    );
  }

  public isOptionValueEditable(brokerContractOptionDto: BrokerContractOptionDto): boolean {
    return (
      this.relationVersion.status.id === BrokerRelationStatus.INACTIVE &&
      !(!BrokerContractRelationDetailsComponent.isPremium(brokerContractOptionDto) && this.allPremiumNA)
    );
  }

  public handleOptionRestrictions(): void {
    if (
      this.relationVersion.options &&
      this.relationVersion.options
        .filter((opt) => BrokerContractRelationDetailsComponent.isPremium(opt))
        .every((opt) => opt.value.id === BrokerContractOptionValue.NA)
    ) {
      this.allPremiumNA = true;
      this.relationVersion.options
        .filter((opt) => !BrokerContractRelationDetailsComponent.isPremium(opt))
        .forEach((opt) => (opt.value = <DictionaryBaseDto>{id: BrokerContractOptionValue.NA}));
    } else {
      this.allPremiumNA = false;
    }
  }

  public isRelationEligibleToDelete(): boolean {
    return (
      this.kuke &&
      this.relationVersion &&
      this.relationVersion.id &&
      this.isBrokerRelationStatusInactive() &&
      this.userHasDeleteRight()
    );
  }

  initializeTemplates() {
    this.templates = [];
    const dictionarySelectors: DictionaryBaseDto[] = [];
    if (this.relationVersion?.brokerRelation?.brokerContract?.brokerType) {
      dictionarySelectors.push(this.relationVersion.brokerRelation.brokerContract.brokerType);
    }
    if (this.relationVersion?.brokerRelation?.businessObject?.relatedTo) {
      dictionarySelectors.push(this.relationVersion.brokerRelation.businessObject.relatedTo);
    }
    if (this.isPolicyRelation() && this.selectedPolicy) {
      dictionarySelectors.push(this.selectedPolicy.contractType);
    }
    this.loadTemplatesByType(
      DocumentType.BROKER_RELATION,
      BusinessObjectType.BROKER_CONTRACT_RELATION_VERSION,
      dictionarySelectors
    );
    this.loadTemplatesByType(
      DocumentType.B_RELATION_COVER_LETTER,
      BusinessObjectType.BROKER_CONTRACT_RELATION_VERSION,
      dictionarySelectors
    );
    this.loadTemplatesByType(
      DocumentType.UNIT_COURT_AGREEMENT_PROPOSITION,
      BusinessObjectType.BROKER_CONTRACT_RELATION_VERSION,
      dictionarySelectors
    );
  }

  loadTemplatesByType(type: DocumentType, boType: BusinessObjectType, dictionarySelectors: DictionaryBaseDto[]) {
    this.templateService.findByType(type, boType, null, dictionarySelectors).subscribe((result) => {
      if (result !== undefined && result !== null) {
        result.forEach((r) => this.templates.push(r));
      }
    });
  }

  public deleteRelationVersion(): void {
    const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
      'brokerContract.relation.details.deleteModalTitle',
      'brokerContract.relation.details.deleteModalMessage'
    );
    confirmationPromise.then((result) => {
      if (result === true) {
        this.delete();
      }
    });
  }

  public newDocument(template: TemplateSimpleDto): void {
    this.routerService.toDocumentDetailsNew(template.id, this.relationVersion.id);
  }

  public generateDocument(template: TemplateSimpleDto): void {
    this.documentService.generatePdf(
      template.id,
      this.relationVersion.id,
      template.name + '.' + this.relationVersion.id + '.pdf'
    );
  }

  public save(): void {
    this.clearErrors();
    if (!this.formValidates()) {
      return;
    }
    this.inProgress = true;
    const originalId = this.relationVersion.id;
    this.brokerRelationService.save(this.relationVersion).subscribe(
      (link) => {
        this.setRelation(link, true, false);
        this.afterObjectSaved(originalId, () => {
          this.setWarnings(this.relationVersion.warnings, this.relationVersion.id);
          this.routerService.toBrokerContractRelationDetails(
            link.id,
            0,
            link.brokerRelation.businessObject.relatedTo.id,
            this.brokerContract.id
          );
        });
        this.handleServerError(this.relationVersion.warnings);
      },
      (error) => this.handleServerError(error)
    );
    this.initializeTemplates();
  }

  public userHasNewDocumentRights(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_NEW_DOCUMENT);
  }

  public userHasGenerateDocumentRights(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_GENERATE_DOCUMENT);
  }

  private isBrokerContractRelationInStatus(status: BrokerRelationStatus): boolean {
    return this.relationVersion?.status?.id === status;
  }

  private delete(): void {
    this.brokerRelationService.deleteRelationVersion(this.relationVersion.id).subscribe(
      () => this.routerService.toBrokerContractPreview(0, this.brokerContract.id),
      (error) => (this.serverErrors = error)
    );
  }

  private showBrokerContractClosedConfirmationDialog(): void {
    const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
      'brokerContract.relation.details.newVersionToCancelledRelatedToWarningTitle',
      'brokerContract.relation.details.newVersionToCancelledRelatedToWarningMessage'
    );
    confirmationPromise.then((result) => {
      if (result === true) {
        this.save();
      }
    });
  }

  private showCommissionStartDateInPastConfirmationDialog(): void {
    const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
      'brokerContract.relation.details.commissionStartDateInPastWarningTitle',
      'brokerContract.relation.details.commissionStartDateInPastWarningMessage'
    );
    confirmationPromise.then((result) => {
      if (result === true) {
        this.save();
      }
    });
  }

  private showBrokerContractNotActiveConfirmationDialog(): void {
    const confirmationPromise: Promise<boolean> = this.confirmDialog.open(
      'brokerContract.relation.details.commissionStartDateInPastWarningTitle',
      'brokerContract.relation.details.commissionStartDateInPastWarningMessage'
    );
    confirmationPromise.then((result) => {
      if (result === true) {
        this.save();
      }
    });
  }

  private initializeSelectors(): void {
    this.selectorNameList = ['Policy', 'Contract', 'BrokerContract', 'Inquiry'];
    this.initializeSelectorEmitters(true);
  }

  private initView(
    relationVersionId: number,
    brokerContractId: number,
    relatedToId: number,
    relatedToTypeId: number
  ): void {
    this.relatedToTypeId = relatedToTypeId;
    this.relatedToId = relatedToId;
    if (brokerContractId > 0) {
      this.loadBrokerContract(brokerContractId);
    } else {
      this.brokerContract = null;
    }
    if (relationVersionId > 0) {
      this.loadRelation(relationVersionId, true);
      this.cancelButton.hidden = true;
    } else if (this.relatedToId > 0) {
      this.loadRelatedTo();
    }
    this.handleButtons();
    this.checkActiveRelationsToBusinessObject();
  }

  private loadBrokerContract(brokerContractId: number): void {
    this.brokerContractService
      .getBrokerContract(brokerContractId)
      .subscribe((brokerContract: BrokerContractDto) => (this.brokerContract = brokerContract));
  }

  private loadRelation(versionId: number, refreshVersionList: boolean): void {
    this.brokerRelationService
      .getById(versionId)
      .subscribe((brokerContractRelationVersion: BrokerContractRelationVersionDto) => {
        this.setRelation(brokerContractRelationVersion, refreshVersionList, true);
      });
  }

  private loadRelatedTo(): void {
    if (this.isPolicyRelation()) {
      const policyVersionDataProvider = BusinessUtils.createPolicyVersionDataProvider(this.policyService);
      policyVersionDataProvider.searchCriteria.criteria.last = true;
      policyVersionDataProvider.searchCriteria.criteria.policyContract.masterPolicyContract.id = this.relatedToId;
      policyVersionDataProvider.searchCriteria.criteria.policyContract.last = true;
      policyVersionDataProvider.search(null, null).subscribe((res) => {
        this.selectedPolicy = res.result[0];
        this.initializeTemplates();
      });
    } else if (this.isContractRelation()) {
      const contractVersionDataProvider = BusinessUtils.createContractVersionDataProvider(this.contractService);
      contractVersionDataProvider.searchCriteria.criteria.last = true;
      contractVersionDataProvider.searchCriteria.criteria.contract.id = this.relatedToId;
      contractVersionDataProvider.search(null, null).subscribe((res) => {
        this.selectedContract = res.result[0];
        this.initializeTemplates();
      });
    } else if (this.isInquiryRelation()) {
      const policyInquiryVersionDataProvider = BusinessUtils.createPolicyInquiryVersionDataProvider(
        this.inquiryService
      );
      policyInquiryVersionDataProvider.searchCriteria.criteria.last = true;
      policyInquiryVersionDataProvider.searchCriteria.criteria.id = this.relatedToId;
      policyInquiryVersionDataProvider.search(null, null).subscribe((res) => {
        this.selectedInquiry = res.result[0];
        this.initializeTemplates();
      });
    } else if (this.isBondingContractInquiryRelation()) {
      const bondingContractInquiryVersionDataProvider = BusinessUtils.createBondingContractInquiryVersionDataProvider(
        this.bondingContractInquiryService
      );
      bondingContractInquiryVersionDataProvider.searchCriteria.criteria.last = true;
      bondingContractInquiryVersionDataProvider.searchCriteria.criteria.id = this.relatedToId;
      bondingContractInquiryVersionDataProvider.search(null, null).subscribe((res) => {
        this.selectedBondingContractInquiry = res.result[0];
        this.initializeTemplates();
      });
    } else {
      throw Error('Type not handled');
    }
  }

  private newRelationForAgentClosedBrokerContract(): boolean {
    return (
      !this.relationVersion.id &&
      (this.isBrokerContractTerminated() || this.isBrokerContractClosed()) &&
      (this.isBrokerTypeAgent() || this.isBrokerTypeMultiAgent())
    );
  }

  private commissionStartDateInPast(): boolean {
    return (
      this.relationVersion &&
      this.relationVersion.validFrom &&
      DateUtils.compare(this.relationVersion.validFrom, DateUtils.today()) === -1
    );
  }

  private isBrokerContractClosed(): boolean {
    return this.brokerContractStatusId() === BrokerContractStatus.CLOSED;
  }

  private isBrokerContractTerminated(): boolean {
    return this.brokerContractStatusId() === BrokerContractStatus.TERMINATED;
  }

  private brokerContractStatusId(): number {
    return _(<Array<BrokerContractVersionSimpleDto>>this.brokerContract.versions)
      .filter((brokerContractVersion) => brokerContractVersion.last)
      .head().status.id;
  }

  private setRelation(
    relation: BrokerContractRelationVersionDto,
    refreshVersions: boolean,
    loadRelatedTo: boolean
  ): void {
    this.showErrors = false;
    this.relationVersion = <BrokerContractRelationVersionDto>{}; // initialize clazz property
    this.relationVersion = relation;
    this.relatedToId = relation.brokerRelation.businessObject.relatedToId;
    this.handleButtons();
    this.handleOptionRestrictions();
    if (refreshVersions) {
      this.refreshRelationList(
        this.relationVersion.brokerRelation.brokerContract.id,
        this.relationVersion.brokerRelation.businessObject.relatedTo.id,
        this.relationVersion.brokerRelation.businessObject.relatedToId,
        false
      );
    }
    if (loadRelatedTo) {
      this.loadRelatedTo();
    }
    this.changeDetector.detectChanges();
  }

  private newVersion(brokerRelationId: number): void {
    this.cancelButton.hidden = false;
    this.brokerRelationService
      .newRelationVersion(brokerRelationId)
      .subscribe((brokerContractRelationVersion: BrokerContractRelationVersionDto) =>
        this.setNewBrokerContractVersion(brokerContractRelationVersion)
      );
  }

  private initialVersion(brokerContractId: number, relatedToTypeId: number, relatedToId: number): void {
    this.brokerRelationService
      .initialRelationVersion(brokerContractId, relatedToTypeId, relatedToId)
      .subscribe((brokerContractRelationVersion: BrokerContractRelationVersionDto) =>
        this.setNewBrokerContractVersion(brokerContractRelationVersion)
      );
  }

  private setNewBrokerContractVersion(brokerContractRelationVersion: BrokerContractRelationVersionDto): void {
    this.relationVersion = brokerContractRelationVersion;
    this.handleButtons();
    this.handleOptionRestrictions();
    this.changeDetector.detectChanges();
  }

  private refreshRelationList(
    brokerContractId: number,
    relatedToTypeId: number,
    relatedToId: number,
    initializeRelation: boolean
  ): void {
    this.relationsDataProvider.searchCriteria.criteria.brokerRelation.brokerContract.id = brokerContractId;
    this.relationsDataProvider.searchCriteria.criteria.brokerRelation.businessObject.relatedTo.id = relatedToTypeId;
    this.relationsDataProvider.searchCriteria.criteria.brokerRelation.businessObject.relatedToId = relatedToId;
    this.relationsDataProvider.search(null, null).subscribe((list) => {
      this.relationVersions = list.result;
      if (initializeRelation) {
        this.initializeRelation(brokerContractId, relatedToTypeId, relatedToId);
      }
    });
  }

  private initializeRelation(brokerContractId: number, relatedToTypeId: number, relatedToId: number): void {
    if (this.relationVersions.length < 1) {
      this.initialVersion(brokerContractId, relatedToTypeId, relatedToId);
    } else {
      this.loadRelation(_.find(this.relationVersions, (relationVersion) => relationVersion.last).id, false);
    }
  }

  // BON-7393, Allow superusers to change commission and mandate validity (valid from/valid to) dates in broker relations
  protected allowChangeCommissionAndMandateDatesForSuperUser(): boolean {
    return (
      this.relationVersion &&
      !this.isBrokerRelationStatusInactive() &&
      this.credendo &&
      this.userHasBrokerRelationDateChange()
    );
  }

  private userHasBrokerRelationDateChange(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.BROKER_RELATION_DATE_CHANGE);
  }

  protected handleButtons(): void {
    this.saveButton.disabled =
      this.relationVersion &&
      this.relationVersion.id &&
      !this.isBrokerRelationStatusInactive() &&
      !this.allowChangeCommissionAndMandateDatesForSuperUser();
    this.newVersionButton.disabled = !this.relationVersion || !this.relationVersion.last;
    this.newVersionButton.hidden =
      !this.relationVersion ||
      !this.relationVersion.id ||
      this.isBrokerRelationStatusInactive() ||
      !this.userHasCreateNewVersionRight();
  }

  private userHasCreateNewVersionRight(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_CREATE_NEW_VERSION);
  }

  private userHasDeleteRight(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACTRELATION_REMOVE);
  }

  private checkActiveRelationsToBusinessObject(): void {
    if (this.kuke && !this.relationVersion?.id && this.relatedToId && this.relatedToTypeId) {
      const criteria = <SearchCriteria<BrokerContractRelationVersionCriteriaDto>>{};
      criteria.criteria = <BrokerContractRelationVersionCriteriaDto>{};
      criteria.criteria.businessStatus = <DictionaryBaseDto>{id: BrokerRelationStatus.ACTIVE};
      criteria.criteria.brokerRelation = <BrokerContractRelationSimpleDto>{};
      criteria.criteria.brokerRelation.businessObject = <BusinessObjectDto>{
        relatedToId: this.relatedToId,
        relatedTo: <DictionaryIfc>{id: this.relatedToTypeId},
      };
      this.brokerRelationService
        .searchByCriteria<BrokerContractRelationVersionCriteriaDto, BrokerContractRelationVersionSimpleDto>(criteria)
        .subscribe((activeRelations: SearchResult<BrokerContractRelationVersionSimpleDto>) => {
          if (activeRelations.size > 0) {
            this.addFrontendWarning('brokerContract.relation.details.activeRelationToBusinessObjectExists');
          }
        });
    }
  }

  toServiceContacts() {
    this.routerService.toServiceContacts({
      boTypeId: BusinessObjectType.BROKER_CONTRACT_RELATION,
      boId: this.relationVersion.brokerRelation.id,
    });
  }
}
