<bon-section labelKey="policy.details.limitFeesSection.title" [expandable]="true">
  <div class="bon-card-group block-narrow" *ngIf="fees && (policy || inquiry)">
    <div class="bon-card-inner  block-narrow" >
      <ng-container *ngIf="isSectionVisible(viewSection.LIMIT_FEES__LUMP_SUM, true)">
        <dict-row [(ngModel)]="fees.lumpSum" dictionary="LumpSum"
                  (changeItem)="onLumpSumKeyChanged($event)" [showErrors]="showErrors"
                  name="lumpSum" [disabled]="readonly"
                  [presentationMode]="readonly" labelKey="policy.details.limitFeesSection.lumpSum">
        </dict-row>
        <div class="bon-row">&nbsp;</div>
      </ng-container>
      <dict-row *ngIf="isSectionVisible(viewSection.LIMIT_FEES__KUKE_LIMIT_AND_MONITORING, true)"
                [(ngModel)]="fees.limitPeriodicity" name="periodicityLimit"
                dictionary="Frequency" [profileId]="dictionaryProfile.LIMIT_DECISION_PERIOD" nullLabel=" "
                [showErrors]="showErrors" [disabled]="readonly"
                [presentationMode]="readonly" labelKey="policy.details.limitFeesSection.periodicityLimitFee">
      </dict-row>

      <dict-row *ngIf="isSectionVisible(viewSection.LIMIT_FEES__KUKE_LIMIT_AND_MONITORING, true)"
                [(ngModel)]="fees.monitoringPeriodicity" name="periodicityMonitoring"
                dictionary="Frequency" [profileId]="dictionaryProfile.LIMIT_MONITORING_PERIOD"
                [codeRegexp]="monitoringPeriodicityCodeRegexp" nullLabel=" "
                [showErrors]="showErrors"
                [presentationMode]="readonly" labelKey="policy.details.limitFeesSection.periodicityMonitoringFee">
      </dict-row>

      <checkbox-row *ngIf="isSectionVisible(viewSection.LIMIT_FEES__NO_INVOICE_FOR_REF) && limitFeeEnabled()" id="noInvoiceForRefusals2"
                    [(ngModel)]="fees.noInvoiceForRefusals" (change)="onNoInvoiceForRefusalsChange()"
                    [presentationMode]="readonly" labelKey="policy.details.limitFeesSection.noInvoiceForRefusals">
      </checkbox-row>

      <num-row *ngIf="isSectionVisible(viewSection.LIMIT_FEES__BIG_FARMER, true)" [(ngModel)]="fees.bigFarmerFee" type="number"
               id="bigFarmerFee" name="bigFarmerFee" [presentationMode]="readonly" labelKey="policy.details.limitFeesSection.bigFarmerFee">
      </num-row>

      <num-row *ngIf="isSectionVisible(viewSection.LIMIT_FEES__KUKE_FALCON_FEE)" [(ngModel)]="fees.falconFee" type="number"
               id="falconFee" name="falconFee" [presentationMode]="readonly" labelKey="policy.details.limitFeesSection.falconFee">
      </num-row>
    </div>
    <ng-container *ngIf="fees.lumpSum" [ngSwitch]="fees.lumpSum.id">
      <ng-container *ngSwitchCase="lumpSum.NO">
        <div class="bon-card-inner  block-narrow">
          <dict-row dictionary="CountryInvocingType" nullLabel=" "
                      *ngIf="isSectionVisible(viewSection.LIMIT_FEES__IVOICE_TYPE, true) && productTypeId"
                      (changeItem)="onInvoicingOptionsChanged($event)"
                      [showErrors]="showErrors" [control]="policyForm.controls.countryInvoicingType"
                      name="invoicingOptions" [presentationMode]="readonly"
                      [formControl]="policyForm.controls.countryInvoicingType"
                      labelKey="policy.details.limitFeesSection.invoicingOptions"
                      [parentDictionary]="productTypeDict" [parentDictionaryEntryId]="productTypeId">
          </dict-row>
          <div class="bon-row" *ngIf="fees.fees && fees.fees.length > 0 && isSectionVisible(viewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES)">
            <label class="bon-label">{{'policy.details.limitFeesSection.fees' | translate}}</label>
            <a-table [items]="fees.fees" [deleteButton]="false" [editable]="!readonly" [addButton]="false"
                     [formModel]="policyForm" groupName="areaLimitsNO" [showAllErrors]="showErrors">
              <column title=" " property="countryGroup.name" required [editable]="false" cellWidth="255px"></column>
              <ng-container *ngIf="ecg">
                <column property="limit" required type="number" numberType="decimal"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.limitFee" ></column>
                <column property="monitoring" required type="number" numberType="decimal"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.monitoringFee"></column>
                <column *ngIf="!fees.noInvoiceForRefusals" property="refusal" required type="number" numberType="decimal"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.refusalFee" ></column>
              </ng-container>
              <ng-container *ngIf="!ecg">
                <column property="limit" required type="number" numberType="integer"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.limitFee"></column>
                <column property="monitoring" required type="number" numberType="integer"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.monitoringFee"></column>
                <column *ngIf="!fees.noInvoiceForRefusals" property="refusal" required type="number" numberType="integer"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.refusalFee"></column>
              </ng-container>
            </a-table>
          </div>
        </div>
        <div class="bon-card-inner  block-narrow" *ngIf="isSectionVisible(viewSection.LIMIT_FEES__LIMIT_MONITORING_REFUSAL_FEES)">
          <div class="bon-row" style="line-height: 2;">&nbsp;</div>
          <div class="bon-row">
            <label class="bon-label">{{'policy.details.limitFeesSection.exceptionsInCountries' | translate}}</label>
            <a-table [items]="fees.countryFees" [deleteButton]="!readonly" [editable]="!readonly" [addButton]="false"
                     [formModel]="policyForm" groupName="policyFeesExceptionsCountriesNO" [showAllErrors]="showErrors">
              <column labelKey="common.country" property="country" required [editable]="false" cellWidth="255px"
                      dictionary="Country"></column>

              <ng-container *ngIf="ecg">
                <column property="limit" required type="number" numberType="decimal"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.limitFee" ></column>
                <column property="monitoring" required type="number" numberType="decimal"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.monitoringFee"></column>
                <column *ngIf="!fees.noInvoiceForRefusals" property="refusal" required type="number" numberType="decimal"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.refusalFee"></column>
              </ng-container>
              <ng-container *ngIf="!ecg">
                <column property="limit" required type="number" numberType="integer"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.limitFee" ></column>
                <column property="monitoring" required type="number" numberType="integer"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.monitoringFee"></column>
                <column *ngIf="!fees.noInvoiceForRefusals" property="refusal" required type="number" numberType="integer"
                        cellWidth="255px" labelKey="policy.details.limitFeesSection.refusalFee"></column>
              </ng-container>
            </a-table>
            <ss-multiselect-dropdown [(ngModel)]="fees.countryFees" name="exceptionsCountries"
                                     optionsDictName="Country"
                                     [settings]="multiSelectSettings"
                                     objectName="country" [disabled]="readonly"></ss-multiselect-dropdown>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="lumpSum.NOT_LIMITED">
        <div class="bon-card-inner">
          <div class="bon-row">&nbsp;
          </div>
          <div class="bon-row">
            <label class="bon-label">{{'policy.details.limitFeesSection.thresholds' | translate}}</label>
            <a-table [items]="fees.feeThresholds" [deleteButton]="!readonly" [editable]="!readonly" [addButton]="!readonly"
                     (addButtonClick)="addButtonClickLimitsThreshold($event)" [addButtonEventEmitterActive]="!readonly"
                     [formModel]="policyForm" groupName="policyFeesThresholds" [showAllErrors]="showErrors" [formGroupValidator]="validateThresholds">
              <column labelKey="policy.details.limitFeesSection.threshold" [editable]="false" cellWidth="255px">
                <ng-template let-item="item" let-edition="edition">{{fees.feeThresholds.indexOf(item) + 1}}</ng-template>
              </column>
              <column labelKey="policy.details.limitFeesSection.noOfLimits" property="noOfLimits" required type="number" cellWidth="255px"></column>
              <column labelKey="policy.details.limitFeesSection.amount" property="amount" required type="number" cellWidth="255px"></column>
            </a-table>
          </div>
        </div>
      </ng-container>

    </ng-container>
  </div>
</bon-section>
