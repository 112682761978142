import {Component, OnInit} from '@angular/core';
import {
  AppConfigService,
  GrowlService,
  PolicyContractDeclarationsConfigurationDto,
  PolicyContractService,
  ReportingPeriodDto,
  RouterService,
} from '../../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {DetailsView} from '../../../bonding_shared/components/details-view/details-view';
import * as moment from 'moment/moment';

@Component({
  selector: 'policy-contract-reporting-periods-details',
  templateUrl: 'policy-contract-reporting-periods-details.component.pug',
})
export class PolicyContractReportingPeriodsDetailsComponent extends DetailsView implements OnInit {
  public policyContractId: number;
  config: PolicyContractDeclarationsConfigurationDto;
  initialDates: {[id: number]: Date} = {};
  reportingToDatesEditable = false;

  turnoverNotificationCodeRegexp: RegExp = undefined;
  turnoverNotificationCodeRegexpKOM: RegExp = /^(?!.*(FF|FFD|FFY|W)).*$/;
  turnoverNotificationCodeRegexpGSP: RegExp = /^(?:M)$/;

  constructor(
    private route: ActivatedRoute,
    public appService: AppConfigService,
    public router: RouterService,
    protected growlService: GrowlService,
    private policyContractService: PolicyContractService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.hideButtons(false);
    this.newVersionButton.hidden = true;
  }

  public ngOnInit(): void {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  public initializeView(params: Params): void {
    this.objectNotFound = false;
    this.serverErrors = undefined;
    this.policyContractId = +params['policyContractId'];
    this.policyContractService.getReportingPeriods(this.policyContractId).subscribe((config) => {
      this.config = config;
      this.config.reportingPeriods.forEach((rp) => (this.initialDates[rp.id] = rp.reportingTo));
      this.reportingToDatesEditable = true;
      this.setCodeRegex();
    });
  }

  getIndex(item) {
    return this.config.reportingPeriods.indexOf(item) + 1;
  }

  recalculateReportingPeriods() {
    this.inProgress = true;
    this.reportingToDatesEditable = false;
    this.serverErrors = undefined;
    this.policyContractService.reinitializeReportingPeriods(this.config).subscribe({
      next: (config) => {
        this.config = config;
        this.inProgress = false;
      },
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
    });
  }

  onCancel() {
    super.onCancel();
    this.initializeView({policyContractId: this.policyContractId});
  }

  onSave() {
    this.inProgress = true;
    this.serverErrors = undefined;
    this.policyContractService.saveReportingPeriods(this.config).subscribe({
      next: (config) => {
        this.config = config;
        this.config.reportingPeriods.forEach((rp) => (this.initialDates[rp.id] = rp.reportingTo));
        this.inProgress = false;
        this.reportingToDatesEditable = true;
        this.growlService.notice('policy.reportingPeriods.saved');
      },
      error: (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      },
    });
  }

  onDone(rp: ReportingPeriodDto) {
    if (!this.reportingToEditable(rp)) {
      return;
    }
    const minRPDate = this.minReportingTo(rp);
    // in case of value entered by hand and before minDate
    if (moment(rp.reportingTo).isBefore(minRPDate)) {
      rp.reportingTo = minRPDate;
    }
    rp.reportingTo.setHours(23, 59, 59, 999);
  }

  minReportingTo(rp: ReportingPeriodDto): Date {
    return this.initialDates && rp && this.initialDates[rp.id];
  }

  reportingToEditable(rp: ReportingPeriodDto): boolean {
    return (
      this.reportingToDatesEditable &&
      this.initialDates &&
      rp &&
      this.initialDates[rp.id] &&
      moment(this.initialDates[rp.id]).endOf('day').isAfter(this.now)
    );
  }

  get now() {
    return new Date();
  }

  get ecg() {
    return this.appService.ecg;
  }

  setCodeRegex() {
    if (this.config) {
      if (this.config?.buName === 'KOM') {
        this.turnoverNotificationCodeRegexp = this.turnoverNotificationCodeRegexpKOM;
      } else {
        this.turnoverNotificationCodeRegexp = this.turnoverNotificationCodeRegexpGSP;
      }
    }
  }

  codeRegexp(): RegExp {
    return this.turnoverNotificationCodeRegexp;
  }

  turnoverDataDisabled() {
    return !this.config || !this.config.editable;
  }

  amendmentsNonStandardVisible() {
    return this.config?.amendmentsNonStandardVisible;
  }
}
