import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {
  BooleanComboComponent,
  DictComboComponent,
  GeoDictComboComponent,
  ItemComboComponent,
  ItemIdComboComponent,
  StringComboComponent,
} from './combos';
import {
  CheckboxRowComponent,
  DateRowComponent,
  DictRowComponent,
  FormRowComponent,
  InputRowComponent,
} from './form-row';
import {PasswordRowComponent} from './form-row/password-row.component';
import {ErrorMessageComponent} from './error-message';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DatePickerComponent} from './date-picker';
import {NumRowComponent} from './form-row/num-row.component';
import {
  ATableComponent,
  BTableComponent,
  ColumnComponent,
  ColumnTemplateComponent,
  SelectionTableComponent,
} from './aku-table';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {BonInputCssClassesPipe, DatePipe, DateTimePipe, HasRightPipe, NumberPipe} from '../pipes';
import {
  BonFocusDirective,
  BtnIconDirective,
  BusinessUnitDirective,
  ChooseRequiredDirective,
  DateNotInFutureDirective,
  DateNotInPastDirective,
  EmailFormatDirective,
  ExpandableSectionDirective,
  FeatureDirective,
  GreaterOrEqualThanValueDirective,
  GreaterThanValueDirective,
  HasRightDirective,
  LessEqThanOtherFieldDirective,
  LessOrEqualThanValueDirective,
  MaxValueDirective,
  MinValueDirective,
  RequiredTrueDirective,
  ToggleOpenNestedDropdownDirective,
  TurnoversSumDirective,
} from '../directives';
import {MonthSelectorComponent} from './month-selector';
import {BackendErrorComponent} from './backend-error';
import {RelatedObjectInfoComponent} from './related-object-info';
import {NumberInputComponent} from './number-input';
import {LifecycleDropdownSimpleComponent} from './lifecycle-dropdown';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ConfirmDialogComponent} from './confirm-dialog';
import {ItemDropdownComponent} from './item-dropdown';
import {SearchInputComponent, SearchViewComponent, SimpleSelectorComponent} from './search';
import {TextareaRowComponent} from './form-row/textarea-row.component';
import {DetailsViewComponent} from './details-view/details-view.component';
import {DateRangeRowComponent} from './form-row/date-range-row.component';
import {BonSectionComponent} from './bon-section/bon-section.component';
import {BusinessUnitSelectorComponent} from './business-unit-selector/business-unit-selector.component';
import {SearchSectionComponent} from './bon-section/search-section.component';
import {SearchRowComponent} from './form-row/search-row.component';
import {TextRowComponent} from './form-row/text-row.component';
import {
  DictAutoCompleteComponent,
  GeoDictAutoCompleteComponent,
  ObjectAutoCompleteComponent,
  TreatyAutoCompleteComponent,
  UserAutoCompleteComponent,
} from './auto-complete';
import {Ng2AutoCompleteModule} from './auto-complete/ng2-auto-complete';
import {UserNamePipe} from '../pipes/user-name.pipe';
import {SelectableObjectSectionComponent} from './bon-section/selectable-object-section.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormDialogComponent} from './form-dialog';
import {ContactPersonDisplayNamePipe} from '../pipes/contact-person-display-name.pipe';
import {MultiselectDropdownComponent, MultiSelectSearchFilter} from './multiselect-dropdown';
import {BusinessUnitIdSelectorComponent} from './business-unit-selector/business-unit-id-selector.component';
import {RadioRowComponent} from './form-row/radio-row.component';
import {FileSelectRowComponent} from './form-row/file-select-row.component';
import {IntegerInputRowComponent} from './form-row/integer-input-row.component';
import {HttpClientModule} from '@angular/common/http';
import {DictTreeComponent} from './bon-tree/dict-tree.component';
import {TreeModule} from 'primeng/tree';
import {DictRadioComponent} from './combos/dict-radio.component';
import {DictRadioRowComponent} from './form-row/dict-radio-row.component';
import {HoverInfoComponent} from './hover-info';
import {UrlPipe} from '../pipes/url-pipe';
import {CheckboxRowSimpleComponent} from './form-row/checkbox-row-simple.component';
import {ErrorMessageSimpleComponent} from './error-message/error-message-simple.component';
import {NumberMultiplyPipe} from '../pipes/number-multiply-pipe';
import {UserComboComponent} from './combos/user-combo.component';
import {BonButtonComponent} from './button/bon-button.component';
import {FileSelectRowSimpleComponent} from './form-row/file-select-row-simple.component';
import {SectionSelectorsComponent} from './details-view/section-selectors.component';
import {DictionarySectionSelectorComponent} from './dictionary-section-selector.component';
import {RepositoryFileSelectRowComponent} from './form-row/repository-file-select-row.component';
import {InstalmentGeneratorComponent} from './instalment-generator/instalment-generator.component';
import {ParameterListComponent} from './parameter-list/parameter-list.component';
import {CompanySelectorSimpleComponent} from '../../bonding/company/components/shared/company-selector-simple.component';
// tslint:disable-next-line:max-line-length
import {ContractVersionSelectorSimpleComponent} from '../../bonding/bonding-contract/contract/components/shared/contract-version-selector-simple.component';
import {PercentRowComponent} from './form-row/percent-row.component';
import {CheckboxEntityComponent, InputEntityComponent, RadioEntityComponent} from './form-entity';
import {IdRowComponent} from './form-row/id-row.component';
import {AddressPipe} from '../pipes/address-pipe';
import {StandaloneTableItemComponent} from './standalone-table-item/standalone-table-item.component';
import {StandaloneCompanyIdentifierComponent} from './standalone-table-item/specific/standalone-company-identifier.component';
import {AttachmentListComponent} from './attachment-list/attachment-list.component';
import {ContactPersonListComponent} from './contact-person/contact-person-list.component';
import {RadioBooleanSwitchComponent} from './radio-boolean-switch/radio-boolean-switch';
import {DocumentButtonComponent} from './button/document-button.component';
import {CrmDropdownComponent} from './crm-note-dropdown';
import {BusinessObjectSelectorComponent} from './business-object-selector/business-object-selector.component';
import {BusinessObjectDetailsComponent} from './business-object-selector/business-object-details.component';
import {BusinessObjectAutoCompleteSelectorComponent} from './business-object-selector/business-object-auto-complete-selector.component';
import {AmountRowComponent} from './form-row/amount-row.component';
import {ValidFromNotAfterValidToDirective} from '../directives/validators/valid-from-not-after-valid-to.directive';
import {AListComponent} from './aku-table/a-list.component';
import {FirstNameFormatDirective} from '../directives/validators/first-name-format.directive';
import {LastNameFormatDirective} from '../directives/validators/last-name-format.directive';
import {CopyFromClipboardDirective} from '../directives/copy-from-clipboard.directive';
import {MultiItemDropdownComponent} from './item-dropdown/multi-item-dropdown.component';
import {ItemGroupedDropdownComponent} from './item-dropdown/item-grouped-dropdown.component';
import {DateNotNowDirective} from '../directives/validators/date-not-now.directive';
import {BooleanComboRowComponent} from './form-row/boolean-combo-row.component';
import {SelectRowComponent} from './form-row/select-row.component';
import {StatementRowComponent} from './form-row/statement-row.component';
import {FocusOnShowDirective} from '../directives/focus-on-show.directive';
import {GtEqThanFieldDirective} from '../directives/validators/greater-or-eq-than-field.directive';
import {PhoneNumberFormatDirective} from '../directives/validators/phone-number-format.directive';
import {CurrencyRowComponent} from './form-row/currency-row.component';
import {BusinessObjectRoutingComponent} from './business-object-selector/business-object-routing-component';
import {RepoDownloaderComponent} from './downloader/repo-downloader-component';
import {CatTranslatePipe} from '../pipes/cat-translate.pipe';
import {BooleanPairRadioRowComponent} from './form-row/boolean-pair-radio-row.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PaginationModule,
    BsDropdownModule,
    Ng2AutoCompleteModule,
    TranslateModule,
    BsDatepickerModule,
    TreeModule,
  ],
  declarations: [
    InputRowComponent,
    PasswordRowComponent,
    ErrorMessageComponent,
    DateRowComponent,
    DatePickerComponent,
    DictRowComponent,
    NumRowComponent,
    IdRowComponent,
    DictComboComponent,
    FormRowComponent,
    AmountRowComponent,
    BooleanComboRowComponent,
    SelectRowComponent,
    ItemComboComponent,
    ATableComponent,
    ColumnComponent,
    ColumnTemplateComponent,
    DatePipe,
    DateTimePipe,
    HasRightDirective,
    MonthSelectorComponent,
    BackendErrorComponent,
    ExpandableSectionDirective,
    RelatedObjectInfoComponent,
    NumberInputComponent,
    ConfirmDialogComponent,
    ItemDropdownComponent,
    ItemGroupedDropdownComponent,
    MultiItemDropdownComponent,
    CrmDropdownComponent,
    CheckboxRowComponent,
    SearchViewComponent,
    BooleanComboComponent,
    StringComboComponent,
    SearchInputComponent,
    TextareaRowComponent,
    BtnIconDirective,
    DetailsViewComponent,
    SectionSelectorsComponent,
    NumberPipe,
    NumberMultiplyPipe,
    HasRightPipe,
    DateRangeRowComponent,
    BonSectionComponent,
    BusinessUnitSelectorComponent,
    SearchSectionComponent,
    ItemIdComboComponent,
    SearchRowComponent,
    TextRowComponent,
    CurrencyRowComponent,
    DictAutoCompleteComponent,
    UserAutoCompleteComponent,
    ObjectAutoCompleteComponent,
    TreatyAutoCompleteComponent,
    BonFocusDirective,
    UserNamePipe,
    SimpleSelectorComponent,
    SelectableObjectSectionComponent,
    LifecycleDropdownSimpleComponent,
    FormDialogComponent,
    BusinessObjectSelectorComponent,
    BusinessObjectRoutingComponent,
    ContactPersonDisplayNamePipe,
    MultiselectDropdownComponent,
    MultiSelectSearchFilter,
    BusinessUnitIdSelectorComponent,
    RadioRowComponent,
    RepositoryFileSelectRowComponent,
    FileSelectRowComponent,
    FileSelectRowSimpleComponent,
    IntegerInputRowComponent,
    DictTreeComponent,
    GeoDictComboComponent,
    GeoDictAutoCompleteComponent,
    DictRadioComponent,
    DictRadioRowComponent,
    BTableComponent,
    SelectionTableComponent,
    HoverInfoComponent,
    UrlPipe,
    BusinessUnitDirective,
    FeatureDirective,
    ToggleOpenNestedDropdownDirective,
    ErrorMessageSimpleComponent,
    CheckboxRowSimpleComponent,
    DictionarySectionSelectorComponent,
    UserComboComponent,
    InstalmentGeneratorComponent,
    BonButtonComponent,
    ParameterListComponent,
    CompanySelectorSimpleComponent,
    ContractVersionSelectorSimpleComponent,
    PercentRowComponent,
    CheckboxEntityComponent,
    InputEntityComponent,
    RadioEntityComponent,
    RadioBooleanSwitchComponent,
    AddressPipe,
    BonInputCssClassesPipe,
    CatTranslatePipe,
    StandaloneTableItemComponent,
    StandaloneCompanyIdentifierComponent,
    AttachmentListComponent,
    ContactPersonListComponent,
    DocumentButtonComponent,
    BusinessObjectDetailsComponent,
    BusinessObjectAutoCompleteSelectorComponent,
    DateNotInPastDirective,
    DateNotInFutureDirective,
    DateNotNowDirective,
    EmailFormatDirective,
    LessEqThanOtherFieldDirective,
    MinValueDirective,
    MaxValueDirective,
    TurnoversSumDirective,
    RequiredTrueDirective,
    ChooseRequiredDirective,
    GreaterThanValueDirective,
    GreaterOrEqualThanValueDirective,
    LessOrEqualThanValueDirective,
    ValidFromNotAfterValidToDirective,
    FirstNameFormatDirective,
    LastNameFormatDirective,
    AListComponent,
    CopyFromClipboardDirective,
    StatementRowComponent,
    FocusOnShowDirective,
    GtEqThanFieldDirective,
    PhoneNumberFormatDirective,
    RepoDownloaderComponent,
    BooleanPairRadioRowComponent,
  ],
  exports: [
    InputRowComponent,
    PasswordRowComponent,
    DateRowComponent,
    DictRowComponent,
    NumRowComponent,
    IdRowComponent,
    DictComboComponent,
    FormRowComponent,
    AmountRowComponent,
    ItemComboComponent,
    ATableComponent,
    ColumnComponent,
    ColumnTemplateComponent,
    DatePipe,
    DateTimePipe,
    HasRightDirective,
    MonthSelectorComponent,
    BackendErrorComponent,
    ErrorMessageComponent,
    ExpandableSectionDirective,
    DatePickerComponent,
    RelatedObjectInfoComponent,
    NumberInputComponent,
    ConfirmDialogComponent,
    ItemDropdownComponent,
    ItemGroupedDropdownComponent,
    MultiItemDropdownComponent,
    CrmDropdownComponent,
    CheckboxRowComponent,
    SearchViewComponent,
    BooleanComboComponent,
    StringComboComponent,
    SearchInputComponent,
    TextareaRowComponent,
    BtnIconDirective,
    DetailsViewComponent,
    SectionSelectorsComponent,
    NumberPipe,
    NumberMultiplyPipe,
    HasRightPipe,
    DateRangeRowComponent,
    BonSectionComponent,
    BusinessUnitSelectorComponent,
    ItemIdComboComponent,
    SearchSectionComponent,
    SearchRowComponent,
    TextRowComponent,
    CurrencyRowComponent,
    DictAutoCompleteComponent,
    UserAutoCompleteComponent,
    ObjectAutoCompleteComponent,
    TreatyAutoCompleteComponent,
    BonFocusDirective,
    FeatureDirective,
    ToggleOpenNestedDropdownDirective,
    UserNamePipe,
    BusinessUnitIdSelectorComponent,
    SimpleSelectorComponent,
    SelectableObjectSectionComponent,
    LifecycleDropdownSimpleComponent,
    BusinessObjectSelectorComponent,
    BusinessObjectRoutingComponent,
    TranslateModule,
    FormDialogComponent,
    ContactPersonDisplayNamePipe,
    MultiselectDropdownComponent,
    RadioRowComponent,
    ContactPersonDisplayNamePipe,
    MultiselectDropdownComponent,
    RepositoryFileSelectRowComponent,
    FileSelectRowComponent,
    FileSelectRowSimpleComponent,
    Ng2AutoCompleteModule,
    IntegerInputRowComponent,
    DictTreeComponent,
    GeoDictComboComponent,
    GeoDictAutoCompleteComponent,
    DictRadioComponent,
    DictRadioRowComponent,
    BTableComponent,
    SelectionTableComponent,
    HoverInfoComponent,
    UrlPipe,
    ErrorMessageSimpleComponent,
    BusinessUnitDirective,
    CheckboxRowSimpleComponent,
    DictionarySectionSelectorComponent,
    UserComboComponent,
    InstalmentGeneratorComponent,
    BooleanComboRowComponent,
    SelectRowComponent,
    BonButtonComponent,
    ParameterListComponent,
    CompanySelectorSimpleComponent,
    ContractVersionSelectorSimpleComponent,
    PercentRowComponent,
    CheckboxEntityComponent,
    InputEntityComponent,
    RadioEntityComponent,
    AddressPipe,
    CatTranslatePipe,
    BonInputCssClassesPipe,
    StandaloneTableItemComponent,
    StandaloneCompanyIdentifierComponent,
    AttachmentListComponent,
    ContactPersonListComponent,
    RadioBooleanSwitchComponent,
    DocumentButtonComponent,
    BusinessObjectAutoCompleteSelectorComponent,
    DateNotInPastDirective,
    DateNotInFutureDirective,
    DateNotNowDirective,
    EmailFormatDirective,
    LessEqThanOtherFieldDirective,
    MinValueDirective,
    MaxValueDirective,
    TurnoversSumDirective,
    RequiredTrueDirective,
    ChooseRequiredDirective,
    GreaterThanValueDirective,
    GreaterOrEqualThanValueDirective,
    LessOrEqualThanValueDirective,
    ValidFromNotAfterValidToDirective,
    FirstNameFormatDirective,
    LastNameFormatDirective,
    AListComponent,
    CopyFromClipboardDirective,
    StatementRowComponent,
    FocusOnShowDirective,
    GtEqThanFieldDirective,
    PhoneNumberFormatDirective,
    RepoDownloaderComponent,
    BooleanPairRadioRowComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {}
