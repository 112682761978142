import {Component} from '@angular/core';
import {
  ClaimDto,
  ClaimIndemnificationBaseDto,
  ClaimIndemnificationVersionBaseDto,
  ClaimIndemnificationVersionCriteriaDto,
  ClaimRecoveryDto,
  DictionaryBaseDto,
  ExternalProviderRelationDto,
  LossAdjustmentExpenseDto,
  RecoveryDto,
  RecoveryRevisionEntityDto,
  SearchCriteria,
} from '../../bonding_shared/model';
import {ActivatedRoute} from '@angular/router';
import {
  AppConfigService,
  ClaimService,
  DictionaryBaseService,
  GrowlService,
  LoggedUserService,
  RouterService,
} from '../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {ClaimRecoveriesService} from '../../bonding_shared/services/claim-recoveries.service';
import {AuditedViewAbstract} from '../../bonding_shared/components/details-view/audited-view-abstract';
import {
  ClaimIndemnificationStatus,
  LossAdjustmentExpenseType,
  RecoveryType,
} from '../../bonding_shared/model/dictionary-ids';
import * as _ from 'lodash';
import {UntypedFormControl, ValidatorFn} from '@angular/forms';
import {forkJoin, Observable, of} from 'rxjs';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {ClaimIndemnificationVersionService} from '../../bonding_shared/services/claim-indemnification-version.service';
import {map} from 'rxjs/operators';
import {ClaimExternalProvidersService} from '../../bonding_shared/services/claim-external-providers.service';

@Component({
  selector: 'recoveries-details',
  templateUrl: 'claim-recoveries-details.component.pug',
})
export class ClaimRecoveriesDetailsComponent extends AuditedViewAbstract<RecoveryDto> {
  private static readonly MINIMUM_AMOUNT_VALUE = 0.01;
  public readonly TODAY = new Date();
  public claim = <ClaimDto>{};
  public recoveryTypes: DictionaryBaseDto[] = [];
  paidIndemnifications: ClaimIndemnificationBaseDto[] = [];
  revisions: RecoveryRevisionEntityDto[];
  recoveriesBeforeIndemnification: ClaimRecoveryDto[];
  recoveriesAfterIndemnification: ClaimRecoveryDto[];
  externalProviders: ExternalProviderRelationDto[] = [];

  externalProviderToString = (item: ExternalProviderRelationDto) => item?.company?.registrationName ?? '';

  constructor(
    route: ActivatedRoute,
    private claimRecoveriesService: ClaimRecoveriesService,
    router: RouterService,
    translateService: TranslateService,
    growlService: GrowlService,
    loggedUserService: LoggedUserService,
    private appService: AppConfigService,
    private claimService: ClaimService,
    private dictionaryBaseService: DictionaryBaseService,
    private claimIndemnificationVersionService: ClaimIndemnificationVersionService,
    private externalProvidersService: ClaimExternalProvidersService
  ) {
    super(route, claimRecoveriesService, router, translateService, growlService, loggedUserService, 'claimId');
  }

  extraInitialization() {
    super.extraInitialization();
    forkJoin([
      this.claimService.getClaim(this.id),
      this.dictionaryBaseService.getDictionaryBase('RecoveryType'),
      this.searchClaimIndemnifications(this.id),
      this.externalProvidersService.getFromRevision(this.id),
    ]).subscribe(([claim, recoveryTypeDict, paidIndemnifications, externalProviders]) => {
      this.claim = claim;
      this.recoveryTypes = recoveryTypeDict;
      this.paidIndemnifications = paidIndemnifications;
      this.externalProviders = externalProviders;
    });
  }

  public onNewClaimRecoveryItem(newItem: ClaimRecoveryDto, afterIndemnification: boolean): void {
    newItem.registrationDate = this.TODAY;
    newItem.afterIndemnification = afterIndemnification;
    if (this.credendo) {
      newItem.type = _(this.recoveryTypes)
        .filter((recoveryType: DictionaryBaseDto) => recoveryType.id === RecoveryType.MAIN_DEBT)
        .head();
    }
  }

  public onNewLossAdjustmentExpenseItem(newItem: LossAdjustmentExpenseDto): void {
    newItem.registrationDate = this.TODAY;
  }

  public recoveriesAmountValidator(recovery: ClaimRecoveryDto): ValidatorFn {
    return (control: UntypedFormControl) => {
      return recovery &&
        recovery.amount &&
        recovery.type &&
        !RecoveryType.CORRECTIONS.includes(recovery.type.id) &&
        control.value < 0
        ? {greaterOrEqualThanValue: {requiredValue: 0, actualValue: control.value}}
        : undefined;
    };
  }

  public lossAdjustmentExpenseAmountValidator(lossAdjustmentExpense: LossAdjustmentExpenseDto): ValidatorFn {
    return (control: UntypedFormControl) => {
      const amountMaxValue = this.amountMaxValue(lossAdjustmentExpense);
      const amountMinValue = this.amountMinValue(lossAdjustmentExpense);
      if (lossAdjustmentExpense.amount > amountMaxValue) {
        return {lessOrEqThanValue: {requiredValue: amountMaxValue, actualValue: control.value}};
      }
      if (lossAdjustmentExpense.amount < amountMinValue) {
        return {greaterOrEqualThanValue: {requiredValue: amountMinValue, actualValue: control.value}};
      }
      return undefined;
    };
  }

  onCreateNewVersion() {
    super.onCreateNewVersion();
    this.current.confirmed = false;
  }

  onLoad() {
    super.onLoad();
    this.recoveriesBeforeIndemnification = this.current.claimRecoveries.filter((r) => !r.afterIndemnification);
    this.recoveriesAfterIndemnification = this.current.claimRecoveries.filter((r) => r.afterIndemnification);
  }

  onSave() {
    this.current.claimRecoveries = [...this.recoveriesBeforeIndemnification, ...this.recoveriesAfterIndemnification];
    super.onSave();
  }

  getRevisions() {
    this.claimRecoveriesService.getRevisions(this.id).subscribe((revisions) => {
      this.revisions = revisions;
      this.newVersion = !revisions.length;
    });
  }

  recoveryHidden(recovery: ClaimRecoveryDto): boolean {
    return recovery?.eradicated;
  }

  recoveryEditable(recovery: ClaimRecoveryDto): boolean {
    return !recovery?.id;
  }

  onDeleteRecoveryAfterIndemnification({id}: ClaimRecoveryDto, recoveries: ClaimRecoveryDto[]): boolean {
    this.current.claimRecoveries = recoveries.map((r) => {
      if (r.id === id) {
        r.eradicated = true;
      }
      return r;
    });
    return true;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  get paidIndemnificationExists(): boolean {
    return this.paidIndemnifications && this.paidIndemnifications.length > 0;
  }

  public refreshCalculatedRecoveryAmount(recovery: ClaimRecoveryDto) {
    if (!!recovery.amount && !!recovery.fxRate) {
      recovery.calculatedRecoveryAmount = recovery.amount * recovery.fxRate;
    }
  }

  public isCorrection(recovery: ClaimRecoveryDto): boolean {
    return DictionaryUtils.in(recovery?.type, RecoveryType.CORRECTION_MAIN_DEBT, RecoveryType.CORRECTION_INTERESTS);
  }

  public compensateAmountOnTypeChange(recovery: ClaimRecoveryDto) {
    if (!!recovery.amount && recovery.amount < 0 && !this.isCorrection(recovery)) {
      recovery.amount = 0;
      this.refreshCalculatedRecoveryAmount(recovery);
    }
  }

  public tooLowTotalMainDebt(): boolean {
    return this.current.totalMainDebt <= this.current.paidIndemnification + this.current.totalLossAdjustmentExpenses;
  }

  private amountMaxValue(item: LossAdjustmentExpenseDto): number {
    return item && item.amount && item.type && LossAdjustmentExpenseType.CORRECTION_EXPENSES.includes(item.type.id)
      ? -ClaimRecoveriesDetailsComponent.MINIMUM_AMOUNT_VALUE
      : Number.POSITIVE_INFINITY;
  }

  private amountMinValue(item: LossAdjustmentExpenseDto): number {
    return item && item.amount && item.type && LossAdjustmentExpenseType.OTHER_EXPENSES.includes(item.type.id)
      ? ClaimRecoveriesDetailsComponent.MINIMUM_AMOUNT_VALUE
      : Number.NEGATIVE_INFINITY;
  }

  private searchClaimIndemnifications(claimId: number): Observable<ClaimIndemnificationBaseDto[]> {
    if (this.appService.ecg) {
      const criteria = <SearchCriteria<ClaimIndemnificationVersionCriteriaDto>>{};
      criteria.criteria = <ClaimIndemnificationVersionCriteriaDto>{
        last: true,
        statuses: [ClaimIndemnificationStatus.PAID],
        claimId: claimId,
      };
      return this.claimIndemnificationVersionService
        .searchByCriteria<ClaimIndemnificationVersionCriteriaDto, ClaimIndemnificationVersionBaseDto>(criteria)
        .pipe(
          map((response) => response.result),
          map((versions) => versions.map((v) => v.parent).filter((value, index, self) => self.indexOf(value) === index))
        );
    }
    return of(<ClaimIndemnificationBaseDto[]>[]);
  }

  protected handleSaved(recovery: RecoveryDto): void {
    super.handleSaved(recovery);
    this.load();
  }
}
