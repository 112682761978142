<search-view objectName="BrokerContract" customTitle="searchView.brokerContractSearch" newObjectLabelKey="brokerContract.search.new"
             [dataProvider]="dataProvider" [enableSearchViewError]="true" [newButtonDisabled]="!userHasBrokerContractCreateRights()">
  <div class="search-criteria bon-card-group">
    <div class="bon-card-inner bon-label-180" style="width:25%">
      <input-row labelKey="brokerContract.search.criteria.number"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.brokerContract.number"
                 name="number"></input-row>
      <dict-row labelKey="brokerContract.search.criteria.status" dictionary="BrokerContractStatus"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.status" nullLabel=" "
                 name="status" *ngIf="kuke"></dict-row>
      <checkbox-row labelKey="brokerContract.search.criteria.hasInsuranceContract"
                    [(ngModel)]="dataProvider.searchCriteria.criteria.hasPolicy"
                    name="hasPolicy" [nullable]="true"></checkbox-row>
      <checkbox-row labelKey="brokerContract.search.criteria.hasBondingContract"
                    [(ngModel)]="dataProvider.searchCriteria.criteria.hasContract"
                    name="hasContract" [nullable]="true" (changeState)="onContractChange()"></checkbox-row>
      <dict-row labelKey="brokerContract.search.criteria.bondingContractType" dictionary="ContractType"
                [(ngModel)]="dataProvider.searchCriteria.criteria.bondingContractType" nullLabel=" "
                name="bondingContractType" *ngIf="kuke && dataProvider.searchCriteria.criteria.hasContract"></dict-row>
    </div>

    <div class="bon-card-inner bon-label-180" style="width:25%">
      <input-row labelKey="brokerContract.search.criteria.brokerName"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.company.registrationName"
                 name="companyName"></input-row>
      <id-row labelKey="brokerContract.search.criteria.brokerId"
                 [(ngModel)]="dataProvider.searchCriteria.criteria.company.id"
                 name="brokerId" *ngIf="kuke"></id-row>
      <dict-row labelKey="brokerContract.search.criteria.type"
                [(ngModel)]="dataProvider.searchCriteria.criteria.brokerContract.brokerType"
                dictionary="BrokerType" nullLabel=" " name="name"></dict-row>
    </div>

    <div class="bon-card-inner bon-label-180" style="width:25%">
      <form-row labelKey="brokerContract.search.criteria.businessUnit">
        <business-unit-id-selector class="bon-input-size" nullLabel=" " [userDefaultBU]="kuke"
                                   [(ngModel)]="dataProvider.searchCriteria.criteria.businessUnit"
                                   name="businessUnit" [readonly]="kuke">
        </business-unit-id-selector>
      </form-row>
      <dict-row labelKey="brokerContract.search.criteria.country"
                [(ngModel)]="dataProvider.searchCriteria.criteria.company.address.country"
                dictionary="Country" nullLabel=" " name="country"></dict-row>
      <form-row label="brokerContract.search.criteria.version">
        <string-combo class="bon-input-size" [(ngModel)]="dataProvider.searchCriteria.criteria.versionPhase" nullLabel=" " [items]="versionPhases"></string-combo>
      </form-row>
    </div>
  </div>

  <div class="search-results">
    <a-table [pagination]="true" [pageSize]="20" [selection]="false" [dataProvider]="dataProvider"
             [sortable]='!dataProvider.textSearch' [showBackendErrors]="false">
      <column title="brokerContract.search.results.number" property="brokerContract.number"
              sort="brokerContract.number" [link]="true"
              (linkClick)="router.toBrokerContractPreview($event.id, 0)">
        <ng-template let-item="item">
          <span *ngIf="credendo || ecg"> {{item.brokerContract.number}}/{{item.versionNumber}}</span>
          <span *ngIf="kuke">{{item.brokerContract.number}}</span>
        </ng-template>
      </column>
      <column title="brokerContract.search.criteria.status" property="status" *ngIf="kuke" type="dictionary"
              dictionary="BrokerContractStatus"></column>
      <column title="brokerContract.search.results.brokerName" property="brokerContract.company.registrationName"
              sort="brokerContract.company.registrationName" [link]="true"
              (linkClick)="router.toCompanyPreview($event.brokerContract.company.id)"></column>
      <column title="brokerContract.search.criteria.brokerId" property="brokerContract.company.id" *ngIf="kuke"></column>
      <column title="brokerContract.search.criteria.type" property="brokerContract.brokerType" *ngIf="kuke"
              type="dictionary" dictionary="BrokerType"></column>
      <column title="brokerContract.search.results.validFrom" property="validFrom" type="date" sort="validFrom"></column>
      <column title="brokerContract.search.results.validTo" property="validTo" type="date" sort="validTo"></column>
      <column title="brokerContract.search.results.bu" property="brokerContract.businessUnit.name"
              sort="brokerContract.businessUnit.name"></column>
      <column title="brokerContract.search.results.country" property="brokerContract.company.address.country.name"
              sort="brokerContract.company.address.country.name"></column>
    </a-table>
  </div>
  <div class="additional-buttons" *ngIf="kuke">
    <button type="button" (click)="export()" class="bon-btn-info" btnIcon="fa-file-text-o" *hasRight="'BROKERCONTRACT_GENERATE_REPORT'">
      {{'brokerContract.search.criteria.export' | translate}}
    </button>
  </div>
</search-view>

