import {Component, OnInit, ViewChild} from '@angular/core';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  AppConfigService,
  DictionaryBaseDto,
  GrowlService,
  Page,
  PolicyMonitoringBaseDto,
  PolicyMonitoringVersionBaseDto,
  PolicyMonitoringVersionCriteriaDto,
  PolicyMonitoringVersionDto,
  PolicyMonitoringVersionService,
  RouterService,
  SearchCriteria,
  StringUtils,
} from '../../bonding_shared';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'policy-monitoring-version-details',
  templateUrl: 'policy-monitoring-version-details.component.pug',
})
export class PolicyMonitoringVersionDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('ngForm', {static: true}) public ngForm: NgForm;
  public policyMonitoringVersion: PolicyMonitoringVersionDto;
  public policyMonitoringVersions: PolicyMonitoringVersionBaseDto[];
  public type: DictionaryBaseDto;
  public policyContractId: number;
  private readonly newVersionButtonLabelKey = 'common.change';

  constructor(
    private route: ActivatedRoute,
    public appService: AppConfigService,
    public router: RouterService,
    protected growlService: GrowlService,
    private policyMonitoringVersionService: PolicyMonitoringVersionService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.hideButtons(false);
    this.newVersionButton.name = this.newVersionButtonLabelKey;
  }

  public get policyMonitoringHasId(): boolean {
    return this.policyMonitoringVersion && this.policyMonitoringVersion.id && this.policyMonitoringVersion.id > 0;
  }

  public ngOnInit(): void {
    this.form = this.ngForm.form;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  public initializeView(params: Params): void {
    this.objectNotFound = false;
    this.serverErrors = undefined;
    this.policyContractId = +params['policyContractId'];
    this.type = <DictionaryBaseDto>{id: params['policyMonitoringTypeId']};
    if (this.policyContractId && this.type) {
      this.setPolicyMonitoringVersionInitial(this.policyContractId, this.type.id);
    } else {
      this.setPolicyMonitoringVersion(+params['versionId']);
    }
  }

  public onSave(): void {
    this.serverErrors = undefined;
    if (!this.ngForm.form.valid) {
      this.showFormError();
      StringUtils.logFormInvalidFields(this.ngForm.form);
      return;
    }
    this.inProgress = true;
    this.save();
  }

  public onCreateNewVersion(): void {
    this.policyMonitoringVersionService
      .newVersion<PolicyMonitoringVersionDto>(this.policyMonitoringVersion.policyMonitoring.id)
      .subscribe((pmv) => {
        this.setMonitoringVersion(pmv);
      });
    this.handleButtons();
  }

  public onCancel(): void {
    super.onCancel();
    this.setPolicyMonitoringVersion(this.policyMonitoringVersions[0].id);
    this.handleButtons();
  }

  public onSelectVersion(selectedCompanyRatingVersion: PolicyMonitoringVersionDto): void {
    this.policyMonitoringVersion = selectedCompanyRatingVersion;
    this.handleButtons();
  }

  public editionDisabled(): boolean {
    return !this.policyMonitoringVersion.last;
  }

  public policyMonitoringVersionsExists(): boolean {
    return (
      this.policyMonitoringVersions &&
      this.policyMonitoringVersions.length >= 1 &&
      !!this.policyMonitoringVersions[0].id
    );
  }

  private setPolicyMonitoringVersionInitial(policyContractId: number, typeId: number) {
    this.policyMonitoringVersionService.getInitialVersion(policyContractId, typeId).subscribe({
      next: (v) => {
        this.setMonitoringVersion(v);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  private setPolicyMonitoringVersion(versionId: number) {
    this.policyMonitoringVersionService.getById<PolicyMonitoringVersionDto>(versionId).subscribe({
      next: (v) => {
        this.setMonitoringVersion(v);
        this.refreshVersions(v.policyMonitoring.id);
      },
      error: (error) => this.handleServerError(error),
    });
  }

  private refreshVersions(parentId: number) {
    const criteria = <SearchCriteria<PolicyMonitoringVersionCriteriaDto>>{};
    criteria.criteria = <PolicyMonitoringVersionCriteriaDto>{};
    criteria.criteria.policyMonitoring = <PolicyMonitoringBaseDto>{id: parentId};
    criteria.page = <Page>{start: 0, count: 300};
    this.policyMonitoringVersionService
      .searchByCriteria<PolicyMonitoringVersionCriteriaDto, PolicyMonitoringVersionBaseDto>(criteria)
      .subscribe({
        next: (search) => {
          this.policyMonitoringVersions = search.result;
        },
        error: (error) => this.handleServerError(error),
      });
  }

  private setMonitoringVersion(v: PolicyMonitoringVersionDto): void {
    this.policyMonitoringVersion = v;
    this.handleButtons();
  }

  protected handleButtons(): void {
    this.saveButton.disabled = this.editionDisabled();
    this.newVersionButton.disabled = !this.policyMonitoringHasId;
    this.cancelButton.disabled = this.policyMonitoringHasId;
  }

  private save(): void {
    const originalId = this.policyMonitoringVersion.id;
    this.policyMonitoringVersionService.save<PolicyMonitoringVersionDto>(this.policyMonitoringVersion).subscribe({
      next: (v) => {
        if (originalId > 0) {
          this.refreshVersions(v.policyMonitoring.id);
          this.showSavedMsg();
          this.serverErrors = undefined;
          this.inProgress = false;
        } else {
          this.afterObjectSaved(originalId, () => this.router.toPolicyMonitoringVersionDetailVersionId(v.id));
        }
      },
      error: (error) => {
        this.handleServerError(error);
      },
    });
  }

  public ratingChanged() {
    this.policyMonitoringVersion.resultSubType = null;
  }
}
