import {Component, OnInit} from '@angular/core';
import {
  DictionaryBaseDto,
  FileImportSimpleDto,
  DeclarationFileImportDto,
  DeclarationMetadataDto,
  DeclarationCriteriaDto,
  SearchResult,
} from '../../bonding_shared/model';
import {PortalPolicyContractService, PortalPolicyContractVersionService, PortalDeclarationService} from '../services';
import {TranslateService} from '@ngx-translate/core';
import {
  AppConfigService,
  FormatService,
  GrowlService,
  LimitListType,
  PolicyLimitListService,
  RouterService,
} from '../../bonding_shared/services';
import {ActivatedRoute} from '@angular/router';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {DeclarationParams} from '../../bonding/declaration/shared/declaration-details-view';
import {DeclarationImportDetailsView} from '../../bonding/declaration/shared/declaration-import-details-view';
import {DeclarationFileService} from '../../bonding_shared/services';
import {ImportType} from '../../bonding_shared/model/dictionary-ids';
import * as moment from 'moment';

@Component({
  selector: 'client-turnover-import',
  templateUrl: './client-turnover-import.component.pug',
})
export class ClientTurnoverImportComponent extends DeclarationImportDetailsView implements OnInit {
  constructor(
    private route: ActivatedRoute,
    protected appService: AppConfigService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected portalDeclarationService: PortalDeclarationService,
    protected declarationFileService: DeclarationFileService,
    protected portalPolicyService: PortalPolicyContractVersionService,
    public policyContractService: PortalPolicyContractService,
    private policyLimitListService: PolicyLimitListService,
    private formatService: FormatService,
    private routerService: RouterService
  ) {
    super(appService, translateService, growlService);
  }

  switchToAmendmentView = false;

  private getRelatedImportFileTypes() {
    const ecgTypes = [
      <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_INTRANET_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_PORTAL_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_WITH_NN_INTRANET_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_WITH_NN_PORTAL_DECLARATION},
    ];
    const kukeTypes = [
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_INTRANET_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_PORTAL_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_NN_INTRANET_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_NN_PORTAL_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_INTRANET_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_PORTAL_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_INTRANET_DECLARATION},
      <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_PORTAL_DECLARATION},
    ];
    if (!this.appService) {
      return [].concat(kukeTypes, ecgTypes);
    }
    if (this.appService.ecg) {
      return ecgTypes;
    }
    if (this.appService.kuke) {
      return kukeTypes;
    }
  }

  protected initializeImportDeclaration() {
    this.initializeFileDataProvider(this.declarationFileService);
    this.importDeclaration = <DeclarationFileImportDto>{
      type: <DictionaryBaseDto>{id: this.getImportTypeId()},
      metadata: <DeclarationMetadataDto>{},
    };
  }

  private initializeFileDataProvider(service: DeclarationFileService) {
    this.fileDataProvider = BusinessUtils.createFileImportDataProvider(service);
    this.fileDataProvider.searchCriteria.criteria.types = this.getRelatedImportFileTypes();
  }

  protected getImportTypeId() {
    if (this.appService) {
      if (this.appService.kuke && this.dcComponent) {
        if (this.dcComponent.nnPolicy) {
          if (this.dcComponent.dc.productDependentOnPremiumRate) {
            return ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_PORTAL_DECLARATION;
          } else {
            return ImportType.TURNOVER_KUKE_WITH_NN_PORTAL_DECLARATION;
          }
        } else {
          if (this.dcComponent.dc.productDependentOnPremiumRate) {
            return ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_PORTAL_DECLARATION;
          } else {
            return ImportType.TURNOVER_KUKE_PORTAL_DECLARATION;
          }
        }
      } else if (this.appService.ecg) {
        if (this.dcComponent.nnPolicy) {
          return ImportType.TURNOVER_ECG_WITH_NN_PORTAL_DECLARATION;
        } else {
          return ImportType.TURNOVER_ECG_PORTAL_DECLARATION;
        }
      }
    }
  }

  ngOnInit() {
    if (this.appService.kuke || this.appService.ecg) {
      this.route.params.subscribe((params) => {
        const dcParams: DeclarationParams = <DeclarationParams>{
          paramRpId: +params['reportingPeriodId'],
          paramLlvId: +params['limitListVersionId'],
          paramDecOnId: +params['declaredOnId'],
        };
        this.amendmentView = false;
        this.dcComponent.fileImportMode = true;
        this.dcComponent.initDeclarationImporterByPortal(false, dcParams);
      });
    }
  }

  onDeclarationCriteriaTouchedEvent() {
    this.inProgress_flag = true;
    this.confirmedDeclarationForSelectedParams = undefined;
    this.dcComponent.amendmentBlocked_flag = undefined;
    this.fileTable.handleSearchResult(<SearchResult<DeclarationFileImportDto>>{});
  }

  onDeclarationCriteriaChangedEvent(reloadDeclaration: boolean) {
    this.resetGenerateCriteria();
    this.refreshFileTable();
  }

  // ############## FILE MANAGEMENT ##############
  amendmentBlocked() {
    return this.dcComponent.amendmentBlocked_flag;
  }

  generateLimitListFile() {
    this.importDeclaration.type = <DictionaryBaseDto>{id: this.getImportTypeId()};
    this.inProgress_flag = true;
    const filenamePrefix = this.translateService.instant('declaration.details.filenamePrefix');
    const fileName = [
      filenamePrefix,
      this.dc.selectedPolicy.number,
      this.dc.selectedRp.id,
      this.dc.selectedLlv.id,
      this.formatService.formatDateTime(new Date()),
    ].join(' ');
    this.declarationFileService.generateLimitListFile(
      this.generateCriteria.criteria,
      LimitListType.DECLARATION,
      fileName + '.xlsx',
      (errorMsg) => this.errorCallback(errorMsg),
      (file) => this.exportCompleteCallback(file)
    );
  }

  downloadFile(file: FileImportSimpleDto, input: boolean) {
    this.declarationFileService.downloadFile(
      file.id,
      input,
      input ? file.fileName : file.outputFileName,
      (errorMsg) => this.errorCallback(errorMsg),
      () => this.downloadCompleteCallback(input ? file.fileName : file.outputFileName)
    );
  }

  public importTurnoverByFile() {
    this.importDeclaration.type = <DictionaryBaseDto>{id: this.getImportTypeId()};
    this.clearErrors();
    if (!this.selectedFile) {
      this.growlService.error('No file selected!');
      return;
    }
    this.inProgress = true;
    this.importDeclaration.metadata.rpId = this.dc.selectedRp.id;
    this.importDeclaration.metadata.llvId = this.dc.selectedLlv.id;
    this.importDeclaration.metadata.decOnId = this.dc.selectedDecOn.id;
    this.importDeclaration.metadata.amendment = false;
    this.importDeclaration.type = <DictionaryBaseDto>{id: this.getImportTypeId()};
    this.declarationFileService.importTurnoverByFile(this.importDeclaration).subscribe({
      next: () => {
        this.inProgress = false;
        this.showSavedMsg();
      },
      error: (error) => this.handleServerError(error),
      complete: () => {
        this.selectedFile = undefined;
        this.refreshFileTable();
      },
    });
  }

  // ############## SEARCH CRITERIA ##############

  protected resetGenerateCriteriaByIds(rpId: number, llvId: number, decOnId: number, finishInProgress = false) {
    this.generateCriteria.criteria = <DeclarationCriteriaDto>{
      reportingPeriodId: rpId,
      limitListVersionId: llvId,
      declaredOnId: decOnId,
    };

    if (this.dcComponent && this.dcComponent.singleFinalConfirmedDeclarationsForPolicy) {
      this.confirmedDeclarationForSelectedParams = this.dcComponent.singleFinalConfirmedDeclarationsForPolicy.find(
        (t) =>
          t.reportingPeriod.id === rpId &&
          t.limitListVersion.id === llvId &&
          (t.declaredOn ? t.declaredOn.id === decOnId : decOnId === undefined)
      );
      const now = new Date();
      const effectiveDeclarationExists =
        this.confirmedDeclarationForSelectedParams !== undefined &&
        moment(this.confirmedDeclarationForSelectedParams.effectiveDate).isBefore(now);
      const pastRp = rpId && moment(this.dc.selectedRp.reportingTo).endOf('day').isBefore(now);

      this.switchToAmendmentView = effectiveDeclarationExists || pastRp;

      if (finishInProgress) {
        this.inProgress_flag = false;
      }
    }
  }

  refreshFileTable() {
    this.fileDataProvider.searchCriteria.criteria.types = this.getRelatedImportFileTypes();
    super.refreshFileTable();
  }
}
