import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DictionaryBaseDto} from '../../model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'boolean-pair-radio-row',
  template: `
    <dict-radio-row
      [labelKey]="labelKey"
      [(ngModel)]="selectedOption"
      [entries]="availableOptions"
      [name]="name"
      [inline]="false"
      (changeItem)="onChangeItem($event)"
      [hiddenIds]="hiddenIds"
      [disabled]="disabled"
    >
    </dict-radio-row>
  `,
  styles: ['label.bon-label { vertical-align: top !important }'],
})
export class BooleanPairRadioRowComponent {
  value = <BooleanPair>{};
  @Input() set inputValue(v: BooleanPair) {
    this.value = v;
    this.initSelection(v);
  }

  constructor(private translateService: TranslateService) {}

  selectedOption: DictionaryBaseDto;
  readonly LEFT_ONLY = <DictionaryBaseDto>{id: 1, name: 'left only'};
  readonly RIGHT_ONLY = <DictionaryBaseDto>{id: 2, name: 'right only'};
  readonly BOTH = <DictionaryBaseDto>{id: 3, name: 'both'};
  readonly NONE = <DictionaryBaseDto>{id: 4, name: 'none'};
  availableOptions = [this.LEFT_ONLY, this.RIGHT_ONLY, this.BOTH, this.NONE];
  hiddenIds: Set<number>;

  @Input() name: string;
  @Input() labelKey: string;

  @Input() set leftLabelKey(label: string) {
    this.LEFT_ONLY.name = this.translateService.instant(label);
  }
  @Input() set rightLabelKey(label: string) {
    this.RIGHT_ONLY.name = this.translateService.instant(label);
  }
  @Input() set bothLabelKey(label: string) {
    this.BOTH.name = this.translateService.instant(label);
  }
  @Input() set noneLabelKey(label: string) {
    this.NONE.name = this.translateService.instant(label);
  }

  @Input() set hideNone(hide: boolean) {
    if (hide) {
      this.hiddenIds = new Set<number>([this.NONE.id]);
    }
  }
  @Input() mapNoneToBoth = false;
  @Input() disabled: boolean;

  @Output() changeItem = new EventEmitter<BooleanPair>();

  onChangeItem(item: DictionaryBaseDto) {
    this.value = this.dictToPair(item);
    this.changeItem.emit(this.value);
  }

  initSelection(v: BooleanPair) {
    this.selectedOption = this.pairToDict(v);
    this.value = this.dictToPair(this.selectedOption);
    this.changeItem.emit(this.value);
  }

  pairToDict(pair: BooleanPair): DictionaryBaseDto {
    if (pair.left && !pair.right) {
      return this.LEFT_ONLY;
    }
    if (!pair.left && pair.right) {
      return this.RIGHT_ONLY;
    }
    if (pair.left && pair.right) {
      return this.BOTH;
    }
    return this.NONE;
  }

  dictToPair(item: DictionaryBaseDto): BooleanPair {
    const result = <BooleanPair>{left: false, right: false};
    switch (item.id) {
      case this.LEFT_ONLY.id:
        result.left = true;
        break;
      case this.RIGHT_ONLY.id:
        result.right = true;
        break;
      case this.BOTH.id:
        result.left = true;
        result.right = true;
        break;
      case this.NONE.id:
        result.left = this.mapNoneToBoth;
        result.right = this.mapNoneToBoth;
        break;
      default:
        break;
    }
    return result;
  }
}

export class BooleanPair {
  left: boolean;
  right: boolean;
}
