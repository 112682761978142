import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  ClauseDto,
  DictionaryBaseDto,
  DictionaryDto,
  IdDto,
  LimitCoverPlusBaseDto,
  LimitCoverPlusDto,
  LimitCoverPlusValueDto,
  LimitDecisionDto,
  LimitRequestDto,
  LimitRequestSimpleDto,
  UserWithProfileDto,
} from '../../../bonding_shared/model/dtos';
import {RouterService} from '../../../bonding_shared/services/router-service';
import {ActivatedRoute} from '@angular/router';
import {LimitService} from '../../../bonding_shared/services/limit.service';
import {
  BusinessObjectType,
  Country,
  DictionaryPropertyType,
  ElementaryRight,
  InquiryProductType,
  LegalForm,
  LimitCategory,
  LimitCoverPlusStatus,
  LimitDecisionAnnotation,
  LimitDecisionCode,
  LimitDecisionSource,
  LimitDecisionStatus,
  LimitDecisionType,
  LimitRequestType,
  PolicyContractType,
  ProductLine,
  ProfileCategory,
} from '../../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';
import {
  AppConfigService,
  DictionaryService,
  GrowlService,
  LoggedUserService,
  UserService,
} from '../../../bonding_shared/services';
import {IMultiSelectSettings} from '../../../bonding_shared/components/multiselect-dropdown/multiselect-dropdown';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {BackendError} from '../../../bonding_shared/model';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';
import {NumberUtils} from '../../../bonding_shared/utils/number-utils';
import {DocumentListComponent} from '../../document';
import {BondingContractInquiryService} from '../../../bonding_shared/services/bonding-contract-inquiry.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'limit-decision',
  templateUrl: './limit-decision.component.pug',
})
export class LimitDecisionComponent extends ListEmitters implements OnInit {
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;
  @ViewChild('documentList') documentList: DocumentListComponent;

  @Input() activeCover: LimitCoverPlusBaseDto; // cover not necessarily from this.decision
  @Input() showErrors: boolean;
  @Input() decisionTypes = [];
  @Output() backendError = new EventEmitter<BackendError>();
  baseMultiplier = this.getBaseMultiplier();
  suffix = this.getSuffix();
  hiddenDecisionCodeIds = new Set();

  @Input() set limitDecision(d: LimitDecisionDto) {
    this.decision = d;
    this.setOtherPaymentDays();
    this.initConditionTypes();
    this.refreshDecisionCodeIds();
    if (this.kuke) {
      this.cleanAnnotationsDescriptions();
    }
  }

  @Input() set limitRequest(r: LimitRequestDto) {
    this.request = r;
    this.setOtherPaymentDays();
    this.setDecisionAnnotations();
    this.baseMultiplier = this.getBaseMultiplier();
    this.suffix = this.getSuffix();
  }

  form: UntypedFormGroup;
  decision: LimitDecisionDto;
  request: LimitRequestDto;
  decisionAnnotations: DictionaryBaseDto[] = [];
  decisionAnnotationsCache: {[requestId: number]: DictionaryBaseDto[]} = {};
  decisionOtherPaymentDays = false;
  usersForAssignment: UserWithProfileDto[];
  longLabel = false;
  portalMode = false;
  public availableConditionTypes: DictionaryDto[] = [];
  public allConditionTypes: DictionaryDto[] = [];
  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    minWidth: '400px',
  };

  readonly LimitCoverPlusStatus = LimitCoverPlusStatus;
  readonly LimitDecisionStatus = LimitDecisionStatus;
  readonly LimitDecisionType = LimitDecisionType;
  readonly LimitDecisionSource = LimitDecisionSource;
  readonly LegalForm = LegalForm;
  readonly Country = Country;

  constructor(
    private _route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private _formBuilder: UntypedFormBuilder,
    private limitService: LimitService,
    private dictionaryService: DictionaryService,
    public router: RouterService,
    protected translateService: TranslateService,
    private userService: UserService,
    private loggedUserService: LoggedUserService,
    private appService: AppConfigService,
    private inquiryService: BondingContractInquiryService,
    protected growlService: GrowlService
  ) {
    super();
    this.portalMode = this.loggedUserService.portal;
    this.initializeSelectorEmittersByNames(true, ['Guarantor0', 'Guarantor1', 'Guarantor2', 'DuplicateRequest']);
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this.longLabel = this.loggedUserService.longLabelLanguage();
    this.downloadUsersForAssignment();
    this.initConditionTypes();
  }

  onDecisionPaymentDaysOther(check: boolean) {
    if (check) {
      if (this.decision.annotations.filter((a) => a.id === LimitDecisionAnnotation.SHORTER_LOAN_PERIOD).length < 1) {
        this.decision.annotations.push(
          this.decisionAnnotations.filter((a) => a.id === LimitDecisionAnnotation.SHORTER_LOAN_PERIOD)[0]
        );
      }
    } else {
      this.decision.annotations = this.decision.annotations.filter(
        (a) => a.id !== LimitDecisionAnnotation.SHORTER_LOAN_PERIOD
      );
    }
  }

  onChangeDecisionType(type: DictionaryBaseDto) {
    if (type.id !== LimitDecisionType.PARTIAL_APPROVAL) {
      // reset cover plus
      this.onCoverPlus(false);
    }
    this.refreshDecisionCodeIds();
  }

  hideDocumentListColumns() {
    if (this.kukePortal) {
      return ['createdBy', 'lastSentDate', 'lastSentTo'];
    } else if (this.kuke) {
      return ['lastSentDate', 'lastSentTo'];
    } else {
      return [];
    }
  }

  get kukePortal(): boolean {
    return this.appService.kuke && this.loggedUserService.portal;
  }

  expandableSection() {
    // temporary
    return true;
  }

  onCoverPlus(on: boolean) {
    if (on) {
      this.decision.cover = <LimitCoverPlusDto>{
        status: <DictionaryBaseDto>{id: LimitCoverPlusStatus.PROPOSAL},
        versionNumber: 1,
        last: true,
        currency: this.request.currency,
        proposedValue: <LimitCoverPlusValueDto>{validFrom: new Date()},
      };
    } else {
      this.decision.cover = undefined;
    }
  }

  get readOnly(): boolean {
    return (
      this.decision &&
      this.decision.id &&
      (this.decision.status.id !== LimitDecisionStatus.SENT_TO_APPROVAL ||
        this.decision.businessStatus.id === LimitDecisionStatus.INACTIVE)
    );
  }

  get acceptorEditable(): boolean {
    return !this.readOnly;
  }

  deleteCondition(c: {clause: ClauseDto; internalId: number}) {
    console.log('remove condition', c);
    NumberUtils.deleteItemFirstOccurrence(this.decision.conditions, c.clause);
    if (
      this.getConditionType(c.clause.type.id) === 'Single' ||
      this.getConditionType(c.clause.type.id) === 'Exclusive'
    ) {
      this.availableConditionTypes.push(this.allConditionTypes.find((a) => a.id === c.clause.type.id));
    }
  }

  addCondition(c: ClauseDto) {
    console.log('add condition', c);
    c.values = [];
  }

  get financialDataRequest(): boolean {
    return (
      this.request &&
      this.request.contactPerson &&
      this.request.source &&
      this.request.source.id === LimitDecisionSource.AUTO_DECISION &&
      this.request.type.id === this.LimitDecisionType.PARTIAL_APPROVAL
    );
  }

  isFalcon() {
    return this.request && this.request.type.id === LimitRequestType.FALCON;
  }

  isFarmer() {
    return BusinessUtils.isFarmerLimit(this.request);
  }

  setOtherPaymentDays() {
    this.decisionOtherPaymentDays =
      this.request && this.decision && this.request.paymentDays !== this.decision.paymentDays;
  }

  setDecisionAnnotations() {
    if (!this.request || !this.request.id || !this.request.last) {
      this.decisionAnnotations = [];
    } else if (this.decisionAnnotationsCache[this.request.id]) {
      this.decisionAnnotations = this.decisionAnnotationsCache[this.request.id];
    } else {
      this.limitService.getPossibleDecisionAnnotations(this.request.id).subscribe((types) => {
        this.decisionAnnotations = types;
        this.decisionAnnotationsCache[this.request.id] = types;
        console.log('decision annotations:', this.decisionAnnotations);
      });
    }
  }

  downloadUsersForAssignment() {
    if (this.portalMode) {
      return;
    }
    const profileId = this.kuke
      ? ProfileCategory.KUKE_LIMITS
      : this.credendo
      ? ProfileCategory.CREDENDO_UNDERWRITER
      : ProfileCategory.ECG_LIMITS;
    const excludeLoggedUser = this.appService.kuke;
    const rightId = this.kuke ? ElementaryRight.LIMIT_ACCEPT_DECISION : undefined;
    this.userService.getUsersForProfileCategory(profileId, rightId, excludeLoggedUser).subscribe(
      (users) => {
        this.usersForAssignment = users;
      },
      (error) => this.backendError.emit(error)
    );
  }

  onSelectDuplicateRequest(r: LimitRequestSimpleDto) {
    this.decision.duplicateRequest = <IdDto>{id: r.id};
  }

  get policyInquiry() {
    return this.request.limit.policyLimitList.policyInquiry;
  }

  isBondingLimit() {
    return BusinessUtils.isBondingLimitRequest(this.request);
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }

  get conditionVisible(): boolean {
    return this.appService.ecg || (this.appService.credendo && this.isSingleRiskOrTurnover());
  }

  get collateralDictName() {
    return this.isBondingLimit() ? 'BondingContractCollateralType' : 'LimitDecisionConditionType';
  }

  get productLine(): number {
    return this.request.limit.category.id === LimitCategory.BONDING_CONTRACT_INQUIRY ||
      this.request.limit.category.id === LimitCategory.BONDING_CONTRACT
      ? ProductLine.BONDING
      : ProductLine.INSURANCE;
  }

  getConditionType(id: number): string {
    return this.allConditionTypes.find((c) => c.id === id).properties[DictionaryPropertyType.LIMIT_CONDITION_TYPE];
  }

  isExclusiveConditionExists() {
    let result = false;
    this.decision.conditions.every((c) => {
      if (this.getConditionType(c.type.id) === 'Exclusive') {
        result = true;
        return false;
      }
      return true;
    });
    return result;
  }

  isSingleOrMultipleConditionExists() {
    let result = false;
    this.decision.conditions.every((c) => {
      if (this.getConditionType(c.type.id) === 'Single' || this.getConditionType(c.type.id) === 'Multiple') {
        result = true;
        return false;
      }
      return true;
    });
    return result;
  }

  pushCondition(selectedConditionType: DictionaryDto) {
    const condition = <ClauseDto>{};
    condition.type = selectedConditionType;
    condition.values = [];
    this.decision.conditions.push(condition);
  }

  onSelectedConditionType(selectedConditionType: DictionaryDto) {
    const condition = <ClauseDto>{};
    switch (selectedConditionType.properties[DictionaryPropertyType.LIMIT_CONDITION_TYPE]) {
      case 'Single':
        if (this.isExclusiveConditionExists()) {
          this.growlService.warning('limitDetails.exclusiveSingleConflict');
        } else {
          this.pushCondition(selectedConditionType);
          const conditionToRemovalIndex = this.availableConditionTypes.indexOf(selectedConditionType, 0);
          if (conditionToRemovalIndex > -1) {
            this.availableConditionTypes.splice(conditionToRemovalIndex, 1);
          }
        }
        break;
      case 'Multiple':
        if (this.isExclusiveConditionExists()) {
          this.growlService.warning('limitDetails.exclusiveMultipleConflict');
        } else {
          this.pushCondition(selectedConditionType);
        }
        break;
      case 'Exclusive':
        if (this.isSingleOrMultipleConditionExists()) {
          this.growlService.warning('limitDetails.exclusiveOtherConflict');
        } else {
          this.pushCondition(selectedConditionType);
          const conditionToRemovalIndex = this.availableConditionTypes.indexOf(selectedConditionType, 0);
          if (conditionToRemovalIndex > -1) {
            this.availableConditionTypes.splice(conditionToRemovalIndex, 1);
          }
        }
        break;
    }
  }

  refreshDecisionCodeIds() {
    this.hiddenDecisionCodeIds = new Set();
    if (
      this.decision &&
      !this.decision.id &&
      (this.request.type.id !== LimitRequestType.FALCON || this.decision.type.id !== LimitDecisionType.REFUSAL)
    ) {
      this.hiddenDecisionCodeIds.add(LimitDecisionCode.INS_9_09_FALCON_LIMIT_IMPOSSIBLE);
    }
  }

  initConditionTypes() {
    this.dictionaryService.getDictionary('LimitDecisionConditionType').subscribe((entries) => {
      this.allConditionTypes = entries;
      this.availableConditionTypes = [...this.allConditionTypes];
    });
  }

  reloadDocuments() {
    this.documentList.search();
  }

  private isSingleRiskOrTurnover() {
    const req = this.request;
    const pll = req && req.limit && req.limit.policyLimitList;
    const result =
      pll &&
      ((pll.masterPolicyContract &&
        (pll.masterPolicyContract.productType.id === PolicyContractType.SINGLE_RISK ||
          pll.masterPolicyContract.productType.id === PolicyContractType.TURNOVER)) ||
        (pll.policyInquiry &&
          (pll.policyInquiry.productType.id === InquiryProductType.SINGLE_RISK ||
            pll.policyInquiry.productType.id === InquiryProductType.TURNOVER)));
    if (result === undefined) {
      return false;
    } else {
      return result;
    }
  }

  getConditionFieldsObs(typeId: number): Observable<DictionaryDto[]> {
    const relatedBoTypeId =
      this.request.limit.category.id === LimitCategory.BONDING_CONTRACT_INQUIRY
        ? BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION
        : undefined;
    return this.isBondingLimit()
      ? this.inquiryService.getCollateralFields(typeId, BusinessObjectType.LIMIT_DECISION, relatedBoTypeId)
      : this.dictionaryService.customFields(typeId);
  }

  getBaseMultiplier() {
    if (this.request && this.isSingleRiskOrTurnover()) {
      return 1;
    }
    return 1000;
  }

  getSuffix() {
    if (this.request && this.isSingleRiskOrTurnover()) {
      return '';
    }
    return '.000';
  }

  cleanAnnotationsDescriptions() {
    this.decision.annotations.forEach((a) => {
      a.description = a.description.replace(/<br\/>/g, '');
    });
  }
}
