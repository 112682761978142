import {Component, Input} from '@angular/core';
import {PolicyContractPreviewDto} from '../../../../bonding_shared/model';

@Component({
  selector: 'policy-contract-info',
  templateUrl: './policy-contract-info.component.pug',
})
export class PolicyContractInfoComponent {
  @Input()
  data: PolicyContractPreviewDto;
  @Input()
  longLabel: boolean;
}
