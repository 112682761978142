import {ClaimDetailsComponent} from './claim-details.component';
import {ClaimSearchComponent} from './claim-search.component';
import {ClaimDocumentsComponent} from './claim-documents.component';
import {ClaimCalculationComponent} from './claim-calculation.component';
import {ClaimIndemnificationComponent} from './claim-indemnification.component';
import {ClaimTreatOrderComponent} from './claim-treat-order.component';
import {ClaimCalculationDocumentsComponent} from './claim-calculation-documents.component';
import {ClaimRecoveriesDetailsComponent} from './claim-recoveries-details.component';
import {ClaimExternalProvidersComponent} from './claim-external-providers.component';
import {ClaimCalculationPayoutPreparationComponent} from './claim-calculation-payout-preparation.component';
import {ClaimLawsuitComponent} from './claim-lawsuit.component';
import {ClaimLinkedClaimsComponent} from './claim-linked-claims.component';
import {
  ClaimCalculationGuard,
  ClaimDetailsGuard,
  ClaimDocumentsGuard,
  ClaimIndemnificationGuard,
  ClaimLawsuitGuard,
  ClaimLinkedClaimsGuard,
  ClaimSearchGuard,
} from './_guards';
import {
  ClaimCalculationDocumentsGuard,
  ClaimCalculationPayoutPreparationGuard,
  ClaimExternalProvidersGuard,
  ClaimRecoveriesDetailsGuard,
  ClaimTreatOrderGuard,
} from './_emptyGuards';
import {ClaimSimplifiedCalculationComponent} from './claim-simplified-calculation.component';
import {ClaimRepaymentScheduleComponent} from './claim-repayment-schedule.component';
import {ClaimRepaymentScheduleGuard} from './_emptyGuards/claim-repayment-schedule.guard';

export const ClaimRoutes = [
  {
    path: 'claim-details/:id',
    component: ClaimDetailsComponent,
    canActivate: [ClaimDetailsGuard],
  },
  {
    path: 'claim-details',
    component: ClaimDetailsComponent,
    canActivate: [ClaimDetailsGuard],
  },
  {
    path: 'recoveries/:claimId',
    component: ClaimRecoveriesDetailsComponent,
    canActivate: [ClaimRecoveriesDetailsGuard],
  },
  {
    path: 'external-providers/:claimId',
    component: ClaimExternalProvidersComponent,
    canActivate: [ClaimExternalProvidersGuard],
  },
  {
    path: 'claim-treat-order/:policyContractId',
    component: ClaimTreatOrderComponent,
    canActivate: [ClaimTreatOrderGuard],
  },
  {
    path: 'claim-search',
    component: ClaimSearchComponent,
    canActivate: [ClaimSearchGuard],
  },
  {
    path: 'claim-documents/:parentId',
    component: ClaimDocumentsComponent,
    canActivate: [ClaimDocumentsGuard],
  },
  {
    path: 'claim-simplified-calculation/:versionId',
    component: ClaimSimplifiedCalculationComponent,
  },
  {
    path: 'claim-calculation/:calcId',
    component: ClaimCalculationComponent,
    canActivate: [ClaimCalculationGuard],
  },
  {
    path: 'claim-indemnification',
    component: ClaimIndemnificationComponent,
    canActivate: [ClaimIndemnificationGuard],
  },
  {
    path: 'claim-indemnification/:versionId',
    component: ClaimIndemnificationComponent,
    canActivate: [ClaimIndemnificationGuard],
  },
  {
    path: 'claim-calculation-check-before-payment/:calcId',
    component: ClaimCalculationDocumentsComponent,
    canActivate: [ClaimCalculationDocumentsGuard],
  },
  {
    path: 'claim-calculation-payout-preparation/:calcId',
    component: ClaimCalculationPayoutPreparationComponent,
    canActivate: [ClaimCalculationPayoutPreparationGuard],
  },
  {
    path: 'claim-lawsuit',
    component: ClaimLawsuitComponent,
    canActivate: [ClaimLawsuitGuard],
  },
  {
    path: 'claim-linked-claims/:claimVersionId',
    component: ClaimLinkedClaimsComponent,
    canActivate: [ClaimLinkedClaimsGuard],
  },
  {
    path: 'repaymentSchedule',
    component: ClaimRepaymentScheduleComponent,
    canActivate: [ClaimRepaymentScheduleGuard],
  },
];
