import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {DictionaryService} from '../../services';
import {DictBaseComponent} from './dict-base.component';
import {DictionaryBaseDto} from '../../model';

const DICT_RADIO_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DictRadioComponent),
  multi: true,
};

@Component({
  selector: 'dict-radio',
  template: `
    <span class="bon-radio">
      <div *ngFor="let item of itemsFiltered" [ngClass]="'radio-option' + (inline ? ' inline' : '')">
        <input
          type="radio"
          [name]="name"
          [value]="item.id"
          [(ngModel)]="selectedKey"
          [checked]="selectedKey === item.id"
          (change)="selectedKey = item.id"
          (click)="onClick(item)"
          [disabled]="disabled"
        />
        <span *ngIf="!template">{{ item[label] }}</span>
        <span *ngIf="template">
          <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{item: item}"></ng-container>
        </span>
      </div>
    </span>
  `,
  styles: [
    'input { vertical-align: bottom; margin-bottom: 4px}',
    'div.radio-option.inline { display: inline-block }',
    'div.radio-option > span { margin: 0 5px; }',
  ],
  providers: [DICT_RADIO_CONTROL_VALUE_ACCESSOR],
})
export class DictRadioComponent extends DictBaseComponent implements ControlValueAccessor, OnInit {
  @Input() name: string;
  @Input() unselect = false;
  @Input() inline = true;

  constructor(dictService: DictionaryService) {
    super(dictService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onClick(item: DictionaryBaseDto) {
    if (this.unselect && item.id === this.selectedKey) {
      this.selectedKey = null;
    }
  }
}
