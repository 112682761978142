import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {
  AddressDto,
  ClientCompanyBaseDto,
  CompanyCriteriaDto,
  CompanyExtendedSearchParamsDto,
  CompanyRawDto,
  CompanySimpleDto,
  DictionaryBaseDto,
  PhysicalPersonDto,
} from '../../model';
import {Subject} from 'rxjs';
import {AbstractService, AppConfigService, SearchDataProvider} from '../../services';
import {TextSearchCriteria} from '../search/model/text-search-criteria';

@Directive()
export abstract class CompanySelectorAbstractComponent<T extends CompanySimpleDto, E> {
  @Output() selectItem = new EventEmitter<CompanySimpleDto | CompanyRawDto | E>();

  @Input() title: string;
  @Input() labelKey: string;
  @Input() showErrors: boolean;
  @Input() visible = false;

  /**
   * Open selector event emitter
   * The same emitter instance should be injected into this component and the component that opens this selector
   */
  @Input() open: Subject<boolean>;

  /**
   * to be able to switch between advanced/basic search mode
   */
  _searchModeSwitchable = false;

  @Input() set searchModeSwitchable(value: boolean) {
    this._searchModeSwitchable = value;
  }

  get searchModeSwitchable(): boolean {
    // IKI 3305
    return this._searchModeSwitchable && !this.appService.kuke;
  }

  /**
   * when fixedSearch = true, search is done by injected, predefined criteria, user cannot modify them
   */
  @Input() fixedSearch = false;

  @Input() searchOnOpen = true;

  @Input() set textSearch(v: boolean) {
    if (this.dataProvider) {
      this.dataProvider.textSearch = v;
    }
  }

  @Input() set criteria(c: CompanyCriteriaDto) {
    if (c) {
      if (!c.address) {
        c.address = <AddressDto>{};
      }
      if (!c.physicalPerson) {
        c.physicalPerson = <PhysicalPersonDto>{};
      }
      this.dataProvider.textSearch = false;
      this.dataProvider.searchCriteria.criteria = c;
    } else {
      this.dataProvider.textSearch = true;
    }
  }

  @Input() set countryId(countryId: number) {
    this.dataProvider.searchCriteria.criteria.address.country = <DictionaryBaseDto>{};
    this.dataProvider.searchCriteria.criteria.address.country.id = countryId;
  }

  @Input() set textCriteria(c: TextSearchCriteria) {
    this.dataProvider.textSearch = true;
    this.dataProvider.textSearchCriteria = c;
  }

  @Input() set showDuplicates(show: boolean) {
    this.dataProvider.textSearchCriteria.duplicate = show;
    this.dataProvider.searchCriteria.criteria.isDuplicate = show;
  }

  @Input() set physicalPersonIndicator(include: boolean) {
    this.dataProvider.textSearchCriteria.physicalPersonIndicator = include;
    this.dataProvider.searchCriteria.criteria.physicalPersonIndicator = include;
  }

  @Input() set companyTypeIds(ids: number[]) {
    this.dataProvider.searchCriteria.criteria.companyTypes = ids;
  }

  dataProvider: SearchDataProvider<CompanyCriteriaDto, T>;

  get service() {
    return this.dataProvider.getService();
  }

  @Input() set service(service) {
    this.dataProvider.setService(service);
  }

  protected constructor(service: AbstractService, private appService: AppConfigService) {
    this.dataProvider = new SearchDataProvider(service);
    this.dataProvider.searchCriteria.criteria = <CompanyCriteriaDto>{};
    this.dataProvider.searchCriteria.criteria.address = <AddressDto>{};
    this.dataProvider.searchCriteria.criteria.physicalPerson = <PhysicalPersonDto>{};
    this.dataProvider.searchCriteria.criteria.clientCompany = <ClientCompanyBaseDto>{};
    this.dataProvider.textSearch = !appService.kuke;
    this.dataProvider.searchCriteria.params = <CompanyExtendedSearchParamsDto>{};
  }

  onSelectItem(c: CompanySimpleDto | CompanyRawDto) {
    if (c) {
      this.selectItem.emit(c);
      this.open.next(false);
    }
  }
}
