<a-table [items]="group.groupCompanies" [deleteButton]="groupEdition" [rowDeletableCallback]="rowDeletable"
         [addButton]="groupEdition" [addButtonEventEmitter]="addMemberEventEmitter" [emitDeleteButton]="true" (deleteButtonClick)="onDeleteItem($event)">
  <column labelKey="group.list.id" property="id"></column>
  <column labelKey="group.list.registrationName" property="registrationName" [link]="true" (linkClick)="gotoCompanyPreview($event)"></column>
  <column *ngIf="!kuke" labelKey="group.list.address">
    <ng-template let-item="item">{{prettyCompanyAddress(item)}}</ng-template>
  </column>
  <column labelKey="group.list.insuranceRating" property="insuranceRating.name"></column>
  <column *ngIf="!kuke" labelKey="group.list.validFrom" property="insuranceRatingValidFrom" type="date"></column>
  <column labelKey="group.list.validTo" property="insuranceRatingValidTo" type="date"></column>
  <column labelKey="group.list.insuranceExposure" property="insuranceExposure" type="number" class="small-column"></column>
  <column labelKey="group.list.bondRating" property="bondRating.name"></column>
  <column *ngIf="!kuke" labelKey="group.list.validFrom" property="bondRatingValidFrom" type="date"></column>
  <column labelKey="group.list.validTo" property="bondRatingValidTo" type="date"></column>
  <column *ngIf="credendo" labelKey="group.list.bondExposure" property="exposure" type="number" class="small-column"></column>
  <column *ngIf="kuke" labelKey="group.list.bondExposure" property="exposureKOM" type="number" class="small-column"></column>
  <column *ngIf="kuke" labelKey="group.list.bondRatingGSP" property="bondRatingGSP.name"></column>
  <column *ngIf="kuke" labelKey="group.list.validTo" property="bondRatingGSPValidTo" type="date"></column>
  <column *ngIf="kuke" labelKey="group.list.bondExposureGSP" property="exposureGSP" type="number" class="small-column"></column>
  <column *ngIf="!kuke" labelKey="common.currency" property="exposureCurrency" dictionary="Currency" dictLabel="code" class="small-column"></column>
  <column *ngIf="!kuke" labelKey="company.preview.parentCompany" property="parentOfBranch.registrationName"></column>
</a-table>

<confirm-dialog #memberRemovalConfirm [showCloseIcon]="false" [showRejectButton]="false" [showCancelButton]="true"></confirm-dialog>
