import {Directive} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {AppConfigService} from '../../../bonding_shared';

@Directive({
  selector: '[overdueOptionValidate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: LimitOverduePaymentsValidatorDirective,
      multi: true,
    },
  ],
})
export class LimitOverduePaymentsValidatorDirective implements Validator {
  constructor(private appService: AppConfigService) {}

  validate(form: UntypedFormGroup): ValidationErrors {
    const delaysInPayments = <UntypedFormControl>form.get('delaysInPayments')?.value;
    const noOverdueFlag = <UntypedFormControl>form.get('noOverdueFlag')?.value;

    if (!this.ecg) {
      return null;
    }
    if ((!delaysInPayments && !noOverdueFlag) || (delaysInPayments && noOverdueFlag)) {
      return {limitOverduePaymentsRequiredOneOption: true};
    }

    return null;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }
}
