import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  AppConfigService,
  BusinessPropertiesService,
  BusinessReportService,
  DeclarationFileService,
  FormatService,
  GrowlService,
  LoggedUserService,
  PolicyContractService,
  RouterService,
} from '../../bonding_shared/services';
import {
  DeclarationBuyerCriteriaDto,
  DeclarationDto,
  Page,
  PolicyContractDto,
  PolicyContractSimpleIdDataDto,
  SearchResult,
} from '../../bonding_shared/model';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import * as moment from 'moment';
import {
  BusinessObjectType,
  Feature,
  PolicyContractType,
  PolicyElementaryRight,
  RepoDocumentElementaryRight,
} from '../../bonding_shared/model/dictionary-ids';
import {
  DeclarationCriteria,
  DeclarationDetailsView,
  DeclarationParams,
} from '../declaration/shared/declaration-details-view';
import {DeclarationService} from '../../bonding_shared/services/declaration.service';
import {ConfirmDialogComponent} from '../../bonding_shared';
import {DeclarationCriteriaComponent} from '../declaration/shared/components/declaration-criteria.component';
import {DeclarationsSaveUpdateOptions} from '../declaration/shared/DeclarationsSaveUpdateOptions';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'policy-declaration-details',
  templateUrl: './policy-declaration-details.component.pug',
})
export class PolicyDeclarationDetailsComponent extends DeclarationDetailsView implements OnInit {
  readonly PolicyElementaryRight = PolicyElementaryRight;
  readonly PolicyContractType = PolicyContractType;
  private k9_k10_k11_messages_products: Set<number> = new Set();
  private titleMsg: String;
  readonly Feature = Feature;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    public routerService: RouterService,
    public appService: AppConfigService,
    public policyContractService: PolicyContractService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected declarationService: DeclarationService,
    protected loggedUserService: LoggedUserService,
    protected businessReportService: BusinessReportService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected declarationFileService: DeclarationFileService,
    protected formatService: FormatService
  ) {
    super(
      translateService,
      growlService,
      declarationService,
      loggedUserService,
      businessReportService,
      appService,
      businessPropertiesService,
      formatService,
      declarationFileService
    );
    this.k9_k10_k11_messages_products.add(PolicyContractType.KUKE_KOM_OP);
    this.k9_k10_k11_messages_products.add(PolicyContractType.KUKE_KOM_FK);
    this.k9_k10_k11_messages_products.add(PolicyContractType.KUKE_KOM_FE);
    this.k9_k10_k11_messages_products.add(PolicyContractType.KUKE_GSP_PBG);
  }

  @ViewChild(DeclarationCriteriaComponent, {static: true}) dcComponent: DeclarationCriteriaComponent;
  @ViewChild('saveDialog', {static: true}) saveDialog: ConfirmDialogComponent;
  @ViewChild('zeroDialog', {static: true}) zeroDialog: ConfirmDialogComponent;
  @ViewChild('confirmDialog', {static: true}) confirmDialog: ConfirmDialogComponent;
  @ViewChild('cleanDialog', {static: true}) cleanDialog: ConfirmDialogComponent;
  @ViewChild('amendmentDialog', {static: true}) amendmentDialog: ConfirmDialogComponent;

  readonly BusinessObjectType = BusinessObjectType;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;

  initializeAmendmentCondition_flag = false;
  showButtons = false;

  ngOnInit() {
    this.setSession(!this.appService.ecg);
    this.form = this.ngForm.form;
    this.dc = <DeclarationCriteria>{buyerCriteria: <DeclarationBuyerCriteriaDto>{}};
    this.clearEntryValuesMap();
    this.route.params.subscribe((params) => this.onInitDeclarationView(params));
  }

  // ############## DECLARATION INITIALIZATION AND RELOADING ##############
  private onInitDeclarationView(params: Params) {
    this.amendment = false;
    this.clearFields();

    const policyContractId = +params['policyContractId'];
    this.policyContractService.getById<PolicyContractDto>(policyContractId).subscribe((pc) => {
      this.dcComponent.onInitIntranetByPolicy(PolicyContractService.toPolicyContractSimpleIdDto(pc), undefined);
      this.setSession(!pc.singleEntryDeclaration);
    });
  }

  // ############## AMENDMENT INITIALIZATION AND RELOADING ##############
  private initAmendmentViewBySearchResult(tSR: SearchResult<DeclarationDto>) {
    this.reloadDeclarationSearchResult(tSR, false);
    this.setHistorySelection(this.declaration);
    this.dcComponent.reloadLlVAndDecOnListsAndSetParamsSelections(
      this.declarationToDeclarationParams(this.declaration)
    );
  }

  onPolicyChanged() {
    if (this.dc && this.dc.selectedPolicy) {
      this.setSession(this.dc && this.dc.selectedPolicy ? !this.dc.selectedPolicy.singleEntryDeclaration : undefined);
    }
    this.initializeOrReloadDeclarationHistory(
      this.dc.selectedPolicy,
      this.dc.selectedPolicy ? this.dc.selectedPolicy.id : undefined
    );
  }

  clearDeclaration() {
    super.clearDeclaration();
    this.dcComponent.showK9message = false;
  }

  onDeclarationCriteriaTouchedEvent() {
    this.setInProgress();
    this.clearDeclaration();
  }

  onDeclarationCriteriaChangedEvent(reloadDeclaration: boolean) {
    if (reloadDeclaration) {
      this.setSession(this.dc && this.dc.selectedPolicy ? !this.dc.selectedPolicy.singleEntryDeclaration : undefined);
      if (this.dc.selectedRp && this.dc.selectedLlv && this.dc.selectedDecOn) {
        this.resetSearchCriteriaWithFirstPage(this.dc);
        this.initOrFindExistingDeclarationBySearchCriteria(true, true);
      } else {
        this.setDeclarationUndefined();
      }
    }
    this.applyFiltersOnDeclarationHistory();
  }

  onFilteringChanged() {
    this.resetSearchCriteriaWithoutPageAndBaseDeclaration(this.dc);
    if (this.edition() && this.amendment) {
      this.onFilteringChangedInnerAmendment();
    } else {
      this.onFilteringChangedInnerDeclaration();
    }
  }

  onPageChange(event: PageChangedEvent) {
    this.setInProgress();
    this.resetPage(<Page>{start: (event.page - 1) * event.itemsPerPage, count: event.itemsPerPage});
    if (this.edition() && this.amendment) {
      this.initAmendmentViewByCurrentParams();
    } else {
      this.initOrFindExistingDeclarationBySearchCriteria(false);
    }
  }

  // ############## DIALOGS ##############

  private openConfirmDialog(nextStep: Function) {
    return this.confirmDialog.openAndExecuteOnConfirm(
      'declaration.details.confirmDialog.title',
      this.outstandings()
        ? 'declaration.details.confirmDialog.msgOutstandingsIntranet'
        : 'declaration.details.confirmDialog.msgTurnoversIntranet',
      nextStep,
      undefined,
      undefined
    );
  }

  private openSaveDialog(nextStep: Function) {
    return this.saveDialog.openAndExecuteOnConfirm(
      'declaration.details.confirmDialog.title',
      'declaration.criteria.k12message',
      nextStep,
      undefined,
      undefined
    );
  }

  private openZeroDeclarationDialog(nextStep: Function) {
    this.zeroDialog.openAndExecuteOnConfirm(
      'declaration.details.zeroTurnoverDialog.title',
      'declaration.details.zeroTurnoverDialog.msg',
      nextStep,
      undefined,
      undefined
    );
  }

  private openAmendmentDialog(nextStep: Function) {
    return this.amendmentDialog.openAndExecuteOnConfirm(
      'declaration.details.amendmentDialog.title',
      'declaration.details.amendmentDialog.k11message',
      nextStep,
      undefined,
      undefined
    );
  }

  public cleanAction() {
    if (this.declaration && this.canUpdate() && !this.amendment) {
      this.cleanDialog.openAndExecuteOnConfirm(
        'declaration.details.cleanDialog.title',
        'declaration.details.cleanDialog.message',
        () => {
          this.clearErrors();
          this.setInProgress();
          const policyData = this.declaration.reportingPeriod.policyContractData;
          this.declarationService.cleanDeclaration(this.declaration.id).subscribe(
            (dec) => {
              this.resetSearchCriteriaWithoutPageAndBaseDeclaration(this.dc);
              this.initOrFindExistingDeclarationBySearchCriteria(true, true);
              this.initializeOrReloadDeclarationHistory(policyData);
              this.showSavedMsg('declaration.details.declarationDeletedIntranet');
            },
            (error) => {
              this.serverErrors = error;
              this.finishInProgress();
            }
          );
        },
        undefined,
        undefined
      );
    }
  }

  public saveDeclarationClick() {
    if (!this.canUpdate() || this.amendment || !this.formValidates()) {
      return;
    }
    const finalStep = this.resolveFunction(DeclarationsSaveUpdateOptions.SAVE_DECLARATION);

    // const secondCondition = true;
    // const secondDialog = this.openConfirmDialog.bind(this, thirdStep);
    // const secondStep: Function = this.conditionalDialog(secondCondition, secondDialog, thirdStep);
    const secondStep: Function = this.openConfirmDialog.bind(this, finalStep);

    const firstCondition: boolean =
      this.declaration &&
      this.declaration.id &&
      this.k9_k10_k11_messages_products.has(this.dc.selectedPolicy.productType.id);
    const firstDialog: Function = this.openSaveDialog.bind(this, secondStep);
    if (this.kuke) {
      const firstStep: Function = this.conditionalDialog(firstCondition, firstDialog, secondStep);
      return firstStep();
    } else {
      return finalStep();
    }
  }

  public saveZeroDeclarationClick() {
    if (!this.canUpdate() || this.amendment || !this.formValidates()) {
      return;
    }
    const finalStep = this.resolveFunction(DeclarationsSaveUpdateOptions.SAVE_ZERO_DECLARATION);

    const firstStep: Function = this.openZeroDeclarationDialog.bind(this, finalStep);
    return firstStep();
  }

  public confirmDeclarationClick() {
    if (!this.canUpdate() || this.amendment || !this.formValidates()) {
      return;
    }

    const finalStep = this.resolveFunction(DeclarationsSaveUpdateOptions.CONFIRM_DECLARATION);

    // const firstCondition = true;
    // const firstDialog: Function = this.openConfirmDialog.bind(this, secondStep);
    // const firstStep: Function = this.conditionalDialog(firstCondition, firstDialog, secondStep);
    if (this.kuke) {
      const firstStep: Function = this.openConfirmDialog.bind(this, finalStep);
      return firstStep();
    } else {
      return finalStep();
    }
  }

  public confirmAmendmentClick() {
    if (!this.canUpdate() || !this.amendment || !this.formValidates()) {
      return;
    }
    const finalStep = this.resolveFunction(DeclarationsSaveUpdateOptions.CONFIRM_AMENDMENT);

    const firstCondition: boolean = this.k9_k10_k11_messages_products.has(this.dc.selectedPolicy.productType.id);
    const firstDialog = this.openAmendmentDialog.bind(this, finalStep);
    const firstStep: Function = this.conditionalDialog(firstCondition, firstDialog, finalStep);

    return firstStep();
  }

  private resolveFunction(option: DeclarationsSaveUpdateOptions): Function {
    switch (option) {
      case DeclarationsSaveUpdateOptions.SAVE_DECLARATION:
        return this.saveDeclaration.bind(this);
      case DeclarationsSaveUpdateOptions.CONFIRM_DECLARATION:
        return this.confirmDeclaration.bind(this);
      case DeclarationsSaveUpdateOptions.CONFIRM_AMENDMENT:
        return this.confirmAmendment.bind(this);
      case DeclarationsSaveUpdateOptions.SAVE_ZERO_DECLARATION:
        return this.saveZeroDeclaration.bind(this);
    }
  }

  // ############## SAVE OR UPDATE ACTIONS ##############
  private saveDeclaration() {
    this.setInProgress();
    if (this.declaration && this.canUpdate() && !this.amendment) {
      this.clearErrors();
      this.declarationService.saveDeclarationStub(this.declarationToStub()).subscribe(
        (decSimple) => {
          this.edition_flag = false;
          this.clearBuyerFilter();
          this.resetSearchCriteriaForDeclarationId(decSimple.id);
          this.initOrFindExistingDeclarationBySearchCriteria(true, true);
          this.initializeOrReloadDeclarationHistory(decSimple.reportingPeriod.policyContractData, decSimple.id);
          this.showSavedMsg('declaration.details.declarationSavedIntranet');
        },
        (error) => {
          this.serverErrors = error;
          this.finishInProgress();
        }
      );
    }
  }

  saveZeroDeclaration() {
    if (this.declaration && this.canUpdate() && !this.amendment) {
      this.setInProgress();
      this.clearEntryValuesMap();
      this.clearErrors();
      this.declarationService.saveZeroDeclarationStub(this.declarationToStub()).subscribe(
        (decSimple) => {
          this.edition_flag = false;
          this.searchCriteria.criteria.basedOnDeclarationId = decSimple.id;
          this.clearBuyerFilter();
          this.initOrFindExistingDeclarationBySearchCriteria(true);
          this.initializeOrReloadDeclarationHistory(decSimple.reportingPeriod.policyContractData, decSimple.id);
          if (decSimple.effectiveDate) {
            this.translateService
              .get('declaration.details.declarationSavedIntranet', {
                effectiveDate: decSimple.effectiveDate.toLocaleDateString(),
              })
              .subscribe((transMsg) => this.showSavedMsg(transMsg));
          } else {
            this.showSavedMsg('declaration.details.declarationSavedIntranet');
          }
        },
        (error) => {
          this.serverErrors = error;
          this.finishInProgress();
        }
      );
    }
  }

  private confirmAmendment() {
    this.setInProgress();
    this.showButtons = false;
    if (this.declaration && this.canUpdate() && this.amendment) {
      this.clearErrors();
      this.declarationService.confirmAmendmentStub(this.declarationToStub()).subscribe(
        (decSimple) => {
          this.edition_flag = false;
          this.clearBuyerFilter();
          this.searchCriteria.criteria.basedOnDeclarationId = decSimple.id;
          this.amendment = false;
          this.initOrFindExistingDeclarationBySearchCriteria(true, true);
          this.initializeOrReloadDeclarationHistory(decSimple.reportingPeriod.policyContractData, decSimple.id);
          this.showSavedMsg('declaration.details.amendmentConfirmed');
          this.dcComponent.showK9message = false;
        },
        (error) => {
          this.serverErrors = error;
          // this.initOrFindExistingDeclarationBySearchCriteria(true, true);
          this.finishInProgress();
        }
      );
    }
  }

  private confirmDeclaration() {
    if (this.declaration && this.canUpdate() && !this.amendment) {
      this.setInProgress();
      this.showButtons = false;
      this.clearErrors();
      this.declarationService.confirmDeclarationStub(this.declarationToStub()).subscribe(
        (decSimple) => {
          this.edition_flag = false;
          this.clearBuyerFilter();
          this.initOrFindExistingDeclarationBySearchCriteria(true, true);
          this.initializeOrReloadDeclarationHistory(decSimple.reportingPeriod.policyContractData, decSimple.id);
          this.dcComponent.recalculateK7Message();
          this.showSavedMsg('declaration.details.declarationConfirmed');
          this.showK8Dialog();
        },
        (error) => {
          this.serverErrors = error;
          // this.initOrFindExistingDeclarationBySearchCriteria(true, true);
          this.finishInProgress();
        }
      );
    }
  }

  // ############## GUI CONTROL ##############
  updateCanUpdateFlag() {
    this.showButtons = false;
    if (this.declaration) {
      const notSavedAmendment = this.amendment && !this.declaration.id;
      const newOrEditedDeclaration = !this.amendment && this.notConfirmed();
      this.canUpdate_flag = notSavedAmendment || newOrEditedDeclaration;
      this.computeLastConfirmedDeclaration(this.dcComponent.singleFinalConfirmedDeclarationsForPolicy);
      this.computeInitializeAmendmentCondition();
    } else {
      this.canUpdate_flag = false;
    }
    this.recalculateTitle();
  }

  private notConfirmed(): boolean {
    return !this.confirmed();
  }

  private confirmed(): boolean {
    const now = new Date();
    return this.declaration && this.declaration.effectiveDate && moment(this.declaration.effectiveDate).isBefore(now);
  }

  initializeAmendmentCondition(): boolean {
    return this.initializeAmendmentCondition_flag;
  }

  computeInitializeAmendmentCondition() {
    this.declarationService.lastDeclarationsConfirmed(this.searchCriteria.criteria).subscribe((lastConfirmed) => {
      this.initializeAmendmentCondition_flag =
        !this.canUpdate() && this.declaration && this.declaration.id && lastConfirmed;
      this.showButtons = !this.inProgress_flag;
    });
  }

  // ############## BUTTONS - NEW AMENDMENT BASED ON LAST DECLARATION ##############
  newDeclarationAmendmentDetailsBasedOnCurrentDeclaration() {
    this.setInProgress();
    this.dcComponent.showK9message = true;
    this.amendment = true;
    this.clearEntryValuesMap();

    this.resetSearchCriteriaWithoutPageAndBaseDeclaration(this.dc);
    this.initAmendmentViewByCurrentParams();
  }

  private initAmendmentViewByCurrentParams() {
    if (this.searchCriteria.criteria.basedOnDeclarationId || this.searchCriteria.criteria.reportingPeriodId) {
      this.edition_flag = true;
      this.declarationService.initializeAmendmentByCriteria(this.searchCriteria).subscribe(
        (decSR) => this.initAmendmentViewBySearchResult(decSR),
        () => {
          this.growlService.error('Error during initialization of amendment!');
          this.edition_flag = false;
          this.finishInProgress();
        }
      );
    } else {
      this.growlService.error('Error during initialization of amendment! Not sufficient parameters.');
    }
  }

  // ############## CANCELING ##############
  cancelEdition() {
    this.setInProgress();
    this.showErrors = false;
    this.edition_flag = false;
    const previousDeclarationId = this.searchCriteria.criteria.basedOnDeclarationId
      ? this.searchCriteria.criteria.basedOnDeclarationId
      : this.declaration
      ? this.declaration.id
      : undefined;
    if (previousDeclarationId) {
      this.resetSearchCriteriaForDeclarationId(previousDeclarationId);
    } else {
      this.resetSearchCriteriaWithoutPageAndBaseDeclaration(this.dc);
    }
    this.resetPage();
    this.clearBuyerFilter();
    this.initOrFindExistingDeclarationBySearchCriteria();
  }

  // ############## CLEARING FIELDS ##############
  private clearFields() {
    this.unSelectAndApplyFiltersOnDeclarationHistory();
    this.dc = <DeclarationCriteria>{buyerCriteria: <DeclarationBuyerCriteriaDto>{}};
    this.reloadDeclarationSearchResult(undefined, false);
  }

  // ############## DECLARATION HISTORY CONTROL ##############
  private initializeOrReloadDeclarationHistory(policy: PolicyContractSimpleIdDataDto, selectedDeclarationId?: number) {
    if (this.declarationHistorySectionComponent) {
      this.declarationHistorySectionComponent.initializeOrReloadDeclarationHistory(
        policy,
        this.dc.selectedRp,
        this.dc.selectedLlv,
        this.dc.selectedDecOn,
        selectedDeclarationId
      );
    }
  }

  // used from declarationHistorySectionComponent
  public onDeclarationSelectFromHistory(declarationStub: DeclarationDto) {
    this.dcComponent.clearSelections();
    this.onDeclarationCriteriaTouchedEvent();
    if (declarationStub && declarationStub.id) {
      this.resetSearchCriteriaForDeclaration(declarationStub);
      this.resetPage();
      this.declarationService.findOrInitializeDeclarationByCriteria(this.searchCriteria).subscribe((tSR) => {
        this.reloadDeclarationSearchResult(tSR, true);
        this.amendment = tSR.result[0].amendment;

        if (this.canUpdate()) {
          this.unblockDeclarationSection();
        } else {
          this.blockDeclarationSection();
        }

        // this.dc.selectedRp = this.declaration.reportingPeriod; moved inside reloadLlVAndDecOnListsAndSetParamsSelections
        this.dcComponent.reloadLlVAndDecOnListsAndSetParamsSelections(
          this.declarationToDeclarationParams(declarationStub)
        );
      });
    }
  }

  // ############## HELPERS ##############
  private declarationToDeclarationParams(declaration: DeclarationDto) {
    if (declaration) {
      return <DeclarationParams>{
        paramRpId: declaration.reportingPeriod ? declaration.reportingPeriod.id : undefined,
        paramLlvId: declaration.limitListVersion ? declaration.limitListVersion.id : undefined,
        paramDecOnId: declaration.declaredOn ? declaration.declaredOn.id : undefined,
      };
    } else {
      return undefined;
    }
  }

  recalculateTitle() {
    if (this.dc.selectedPolicy && this.dc.selectedRp) {
      this.titleMsg = this.amendment
        ? this.outstandings()
          ? 'declaration.details.outstandingsAmendmentTitle'
          : 'declaration.details.turnoverAmendmentTitle'
        : this.outstandings()
        ? 'declaration.details.outstandingsTitle'
        : 'declaration.details.turnoverTitle';
    } else {
      this.titleMsg = '';
    }
  }

  get title(): String {
    return this.titleMsg;
  }

  setInProgress() {
    this.showButtons = false;
    this.inProgress_flag = true;
  }

  finishInProgress() {
    this.showButtons = true;
    this.inProgress_flag = false;
  }

  importDeclarationPossible() {
    return !this.dc.selectedPolicy.singleEntryDeclaration && this.canUpdate() && !this.amendment && !this.edition();
  }

  importAmendmentPossible() {
    return (
      !this.dc.selectedPolicy.singleEntryDeclaration &&
      ((!this.canUpdate() && this.declaration && this.declaration.id) ||
        (this.canUpdate() && this.amendment && !this.edition()))
    );
  }

  showExportLimits() {
    return (
      this.dc &&
      this.dc.selectedRp &&
      this.dc.selectedLlv &&
      this.dc.selectedDecOn &&
      this.dc.selectedPolicy &&
      !this.dc.selectedPolicy.singleEntryDeclaration
    );
  }
}
