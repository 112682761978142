import {EventEmitter, Injectable, Output, Directive} from '@angular/core';
import {ApplicationModule, ElementaryRight} from '../model/dictionary-ids';
import {LoggedUserService} from './logged-user.service';
import {AppConfigService} from './app-config.service';
import {RouterService} from './router-service';

interface ModuleDefinition {
  name: string;
  on: boolean;
  id: number;
  rightId: number;
  hasRight: boolean;
}

@Directive()
@Injectable()
export class SettingsService {
  @Output() viewChange = new EventEmitter<number>();

  activeModuleName: string;

  private modules: ModuleDefinition[] = [];

  private loadModules() {
    const defaultModuleId = this.loggedUserService.getLoggedUserDefaultModuleId();
    this.modules = [
      {name: 'module.bonding', id: ApplicationModule.BONDING, rightId: ElementaryRight.ACCESS_MODULE_BOND},
      {name: 'module.policies', id: ApplicationModule.POLICY, rightId: ElementaryRight.ACCESS_MODULE_POLICIES},
      {name: 'module.limits', id: ApplicationModule.LIMIT, rightId: ElementaryRight.ACCESS_MODULE_LIMITS},
      {name: 'module.claims', id: ApplicationModule.CLAIM, rightId: ElementaryRight.ACCESS_MODULE_CLAIMS},
      {name: 'module.sales', id: ApplicationModule.SALES, rightId: ElementaryRight.ACCESS_MODULE_SALES},
      {name: 'module.finance', id: ApplicationModule.FINANCE, rightId: ElementaryRight.ACCESS_MODULE_FINANCE},
      {name: 'module.collection', id: ApplicationModule.COLLECTION, rightId: ElementaryRight.ACCESS_MODULE_COLLECTION},
      {
        name: 'module.administration',
        id: ApplicationModule.ADMINISTRATION,
        rightId: ElementaryRight.ACCESS_MODULE_ADMINISTRATION,
      },
      {name: 'module.hr', id: ApplicationModule.HR, rightId: ElementaryRight.ACCESS_MODULE_HR},
    ].map((module) => {
      const typed = <ModuleDefinition>module;
      typed.hasRight = this.loggedUserService.hasRight(module.rightId);
      typed.on = module.id === defaultModuleId && typed.hasRight;
      // show HR module for employee and HR Roles
      if (module.id === ApplicationModule.HR) {
        typed.hasRight =
          this.loggedUserService.hasRight(module.rightId) && this.loggedUserService.isEmployeeOrHasHRRole();
        typed.on = module.id === defaultModuleId && typed.hasRight && this.loggedUserService.isEmployeeOrHasHRRole();
      }
      return typed;
    });
    if (this.getActiveModuleId() === 0) {
      const moduleDefinition = this.modules.find((m) => m.hasRight);
      if (moduleDefinition) {
        moduleDefinition.on = true;
      }
    }
    this.setActiveModuleName();
  }

  constructor(
    private loggedUserService: LoggedUserService,
    private router: RouterService,
    private appConfig: AppConfigService
  ) {
    this.loadModules();
    loggedUserService.loggedUsersSubject.subscribe((newUser) => {
      this.loadModules();
      this.viewChange.emit(newUser.defaultModule?.id);
    });
  }

  get policyViews(): boolean {
    return this.isModuleActive(ApplicationModule.POLICY);
  }

  get bondingViews(): boolean {
    return this.isModuleActive(ApplicationModule.BONDING);
  }

  get limitViews(): boolean {
    return this.isModuleActive(ApplicationModule.LIMIT);
  }

  get claimViews(): boolean {
    return this.isModuleActive(ApplicationModule.CLAIM);
  }

  get salesViews(): boolean {
    return this.isModuleActive(ApplicationModule.SALES);
  }

  get financeViews(): boolean {
    return this.isModuleActive(ApplicationModule.FINANCE);
  }

  get collectionViews(): boolean {
    return this.isModuleActive(ApplicationModule.COLLECTION);
  }

  get administrationViews(): boolean {
    return this.isModuleActive(ApplicationModule.ADMINISTRATION);
  }

  get hrViews(): boolean {
    return this.isModuleActive(ApplicationModule.HR);
  }

  get extranetViews(): boolean {
    return !this.getActiveModuleId();
  }

  isModuleActive(...mTypes: ApplicationModule[]) {
    return this.modules.filter((m) => mTypes.includes(m.id)).some((m) => m.on);
  }

  getActiveModuleId(): number {
    const module = this.modules.find((m) => m.on);
    return module ? module.id : 0;
  }

  getActiveModuleIds(): number[] {
    return this.modules.filter((m) => m.on).map((m) => m.id);
  }

  viewOn(moduleId: number) {
    this.allViewsOff();
    const module = this.modules.find((m) => m.id === moduleId && m.hasRight);
    if (module) {
      module.on = true;
    }
    this.viewChange.emit(moduleId);
    this.setActiveModuleName();
    if (this.administrationViews) {
      if (this.loggedUserService.hasRight(ElementaryRight.MY_PROFILE_CATEGORY_USERS_SEARCH)) {
        console.log('Has user search right');
        this.router.toUserSearch();
      } else {
        console.log('Not has user search right');
        this.router.toTaskSearch();
      }
    } else {
      this.router.toStartPage();
    }
  }

  allViewsOff() {
    this.modules.forEach((obj) => (obj.on = false));
  }

  setActiveModuleName() {
    let name = '';
    let count = 0;
    this.modules
      .filter((module) => module.on)
      .forEach((module) => {
        count++;
        name += module.name + ', ';
      });
    this.activeModuleName =
      count > 1 && count === this.getAccessibleModules().length ? 'module.all' : name.substr(0, name.length - 2);
  }

  getAccessibleModules(): ModuleDefinition[] {
    return this.modules.filter((m) => m.hasRight);
  }

  allAccessibleViewsOn(): void {
    this.allViewsOff();
    this.getAccessibleModules().forEach((module) => (module.on = true));
    this.viewChange.emit(0);
    this.setActiveModuleName();
  }
}
