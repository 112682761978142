import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AppConfigService,
  GrowlService,
  PolicyContractPreviewDto,
  PolicyContractVersionService,
  PolicyDrawdownConstituentDto,
  PolicyDrawdownExpectedRepaymentDto,
  PolicyDrawdownVersionBaseDto,
  PolicyDrawdownVersionDto,
  RouterService,
} from '../../../../bonding_shared';
import {ActivatedRoute, Params} from '@angular/router';
import {PolicyDrawdownVersionService} from '../../../../bonding_shared/services/policy-drawdown-version.service';
import {
  BusinessObjectType,
  DictionaryProfile,
  PolicyContractType,
  PolicyDrawdownType,
  PolicyDrawdownVersionStatus,
  RepoDocumentElementaryRight,
} from '../../../../bonding_shared/model/dictionary-ids';
import {PolicyDrawdownRepaymentScheduleVersionService} from '../../../../bonding_shared/services/policy-drawdown-repayment-schedule-version.service';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {mergeMap} from 'rxjs/operators';
import {combineLatest, Observable, of} from 'rxjs';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'policy-drawdown-details',
  templateUrl: './policy-drawdown-details.component.pug',
})
export class PolicyDrawdownDetailsComponent extends DetailsView implements OnInit {
  readonly BusinessObjectType = BusinessObjectType;
  readonly PolicyDrawdownVersionStatus = PolicyDrawdownVersionStatus;
  readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  policyDrawdownVersion = <PolicyDrawdownVersionDto>{};
  policyContractPreview: PolicyContractPreviewDto;
  versions: PolicyDrawdownVersionBaseDto[];
  policyDrawdownRepayments: PolicyDrawdownExpectedRepaymentDto[] = [];
  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  public constructor(
    private _route: ActivatedRoute,
    public service: PolicyDrawdownVersionService,
    private drawdownScheduleVersionService: PolicyDrawdownRepaymentScheduleVersionService,
    private policyContractVersionService: PolicyContractVersionService,
    public router: RouterService,
    protected growlService: GrowlService
  ) {
    super(growlService);
    this.deleteButton = undefined;
    this.newVersionButton.name = 'policyDrawdown.newVersionButton';
    this.saveButton.name = 'policyDrawdown.saveButton';
    this.selectorNameList = ['CurrentTargetCompany'];
    this.initializeSelectorEmitters(true);
  }

  ngOnInit(): void {
    this.form = this.ngForm.form;
    this._route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    super.initializeView(params);
    const drawdownId = +params['drawdownId'];
    if (drawdownId) {
      this.fetchData(this.service.getLastPolicyDrawdownVersion(drawdownId));
    } else {
      this._route.queryParams.subscribe((queryParams) => {
        this.fetchData(this.service.getInitialVersion(+queryParams['masterPolicyId']));
      });
    }
  }

  private fetchData(resultObs: Observable<PolicyDrawdownVersionDto>) {
    resultObs
      .pipe(
        mergeMap((drawdownVersion) => {
          return combineLatest([
            of(drawdownVersion),
            this.policyContractVersionService.getPreviewByMasterPolicyId(
              drawdownVersion.parent.masterPolicyContract.id
            ),
          ]);
        })
      )
      .subscribe({
        next: ([drawdownVersion, policyContractPreview]) => {
          this.setDrawdownVersion(drawdownVersion);
          this.policyContractPreview = policyContractPreview;
          const drawdownId = drawdownVersion.parent.id;
          if (drawdownId) {
            this.fetchVersions(drawdownId);
            this.fetchRepayments(policyContractPreview.masterPolicyId, drawdownId);
          }
        },
        error: (error) => {
          this.handleServerError(error);
        },
      });
  }

  handleButtons() {
    this.saveButton.hidden = !this.isEditable;
    this.cancelButton.hidden = !this.isEditable;
    this.newVersionButton.hidden = !this.canCreateNewVersion;
  }

  onSave() {
    this.serverErrors = undefined;
    this.showErrors = false;
    if (this.formValidates()) {
      this.inProgress = true;
      const originalDrawdownId = this.policyDrawdownVersion?.parent?.id;
      this.service.save(this.policyDrawdownVersion).subscribe({
        next: (savedDrawdownVersion) => {
          this.afterObjectSaved(originalDrawdownId, () =>
            this.router.toPolicyDrawdownDetails(savedDrawdownVersion.parent.id)
          );
          this.setDrawdownVersion(savedDrawdownVersion);
          this.fetchVersions(savedDrawdownVersion.parent.id);
        },
        error: (error) => {
          this.handleServerError(error);
        },
      });
    }
  }

  onCreateNewVersion() {
    super.onCreateNewVersion();
    this.service
      .newVersion<PolicyDrawdownVersionDto>(this.policyDrawdownVersion?.parent?.id)
      .subscribe((newVersion) => {
        this.setDrawdownVersion(newVersion);
      });
  }

  onCancel() {
    super.onCancel();
    this.initializeView(this._route.snapshot.params);
  }

  onSelectPolicyDrawdownVersion(policyDrawdownVersionId: number) {
    this.service
      .getById<PolicyDrawdownVersionDto>(policyDrawdownVersionId)
      .subscribe((drawdownVersion) => this.setDrawdownVersion(drawdownVersion));
  }

  setDrawdownVersion(policyDrawdownVersion: PolicyDrawdownVersionDto) {
    this.policyDrawdownVersion = policyDrawdownVersion;
    this.handleButtons();
  }

  onAddConstituent(constituent: PolicyDrawdownConstituentDto) {
    constituent.currency = this.policyContractPreview.insuredAmountCurrency;
  }

  get hiddenDrawdownTypeIds() {
    const alreadySelectedTypes =
      this.policyDrawdownVersion && this.policyDrawdownVersion.constituents
        ? this.policyDrawdownVersion.constituents.filter((c) => c.type).map((c) => c.type.id)
        : [];
    return [
      ...alreadySelectedTypes,
      PolicyDrawdownType.MATERIALS_AND_PARTS,
      PolicyDrawdownType.WAGES_AND_CONTRIBUTIONS,
      PolicyDrawdownType.GENERAL_EXPENSES,
      PolicyDrawdownType.PRE_FINANCING,
      PolicyDrawdownType.OTHER_COSTS,
      PolicyDrawdownType.CASH,
      PolicyDrawdownType.INVESTMENT_IN_KIND,
      PolicyDrawdownType.OWNERSHIP_LOAN,
    ];
  }

  fetchVersions(policyDrawdownId: number) {
    this.service.findPolicyDrawdownVersions(policyDrawdownId).subscribe((versions) => {
      this.versions = versions;
    });
  }

  fetchRepayments(masterPolicyId: number, policyDrawdownId: number) {
    this.drawdownScheduleVersionService
      .findLastDrawdownRepaymentScheduleVersionByMasterPolicyId(masterPolicyId)
      .subscribe((r) => {
        this.policyDrawdownRepayments = r?.expectedRepayments?.filter(
          (repayment) => repayment.drawdown.id === policyDrawdownId
        );
      });
  }

  get isEditable() {
    return (
      !this.policyDrawdownVersion.id ||
      (this.policyDrawdownVersion.last && this.policyDrawdownVersion.status.id === PolicyDrawdownVersionStatus.DRAFT)
    );
  }

  get canCreateNewVersion() {
    return (
      this.policyDrawdownVersion.id &&
      this.policyDrawdownVersion.status.id === PolicyDrawdownVersionStatus.APPROVED &&
      this.policyDrawdownVersion.last
    );
  }

  get targetCompanySelectable() {
    // return this.policyContractPreview?.contractType?.id === PolicyContractType.FOREIGN_INVESTMENT_B;
    return false;
  }
}
