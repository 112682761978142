import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  DictionaryBaseDto,
  DocumentDto,
  Page,
  PolicyContractLossRatioDto,
  PolicyContractPreviewDto,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionDto,
  PolicyContractVersionSimpleDto,
  PolicyProductConfigurationDto,
  ReportingPeriodDto,
  SearchCriteria,
  SearchResult,
  SortBy,
} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {FormatService} from './format.service';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class PolicyContractVersionService extends AbstractService {
  constructor(
    public http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    private formatService: FormatService
  ) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'policyContractVersion';
  }

  getPolicyContractVersion(id: number): Observable<PolicyContractVersionDto> {
    console.log('policy contract version details: id = ' + id);
    return this.get<PolicyContractVersionDto>(this.url + '/' + id);
  }

  getPolicyContractLastVersionsByClientId(clientId: string): Observable<SearchResult<PolicyContractVersionDto>> {
    // return this.http.get(this._contractUrl + '?clientId=' + clientId + '&start=' + start + '&count=' + count)
    const query: string = this.url + '?clientId=' + clientId + '&last=true';
    return this.get<SearchResult<PolicyContractVersionDto>>(query);
  }
  getAllVersions(
    start: number,
    count: number,
    sortBy: string,
    desc: boolean,
    active: boolean
  ): Observable<SearchResult<PolicyContractVersionDto>> {
    const query: string =
      this.url +
      '?start=' +
      start +
      '&count=' +
      count +
      '&sortBy=' +
      sortBy +
      '&desc=' +
      desc; /*+  '&active=' + active*/
    return this.get<SearchResult<PolicyContractVersionDto>>(query);
  }

  searchPolicyContractVersions(
    text: string,
    start: number,
    count: number,
    active: boolean
  ): Observable<SearchResult<PolicyContractVersionDto>> {
    const query: string = this.url + '/search?text=' + text + '&start=' + start + '&count=' + count;
    if (active) {
      console.log('Skipping "active" parameter, as it is not implemented in backend');
      // query += '&active=' + active;
    }
    return this.get<SearchResult<PolicyContractVersionDto>>(query);
  }

  createPolicyContractVersion(policyContractVersion: PolicyContractVersionDto): Observable<PolicyContractVersionDto> {
    console.log('crete policy contract version:' + JSON.stringify(policyContractVersion));
    return this.put<PolicyContractVersionDto>(policyContractVersion, this.url);
  }

  updatePolicyContractVersion(policyContractVersion: PolicyContractVersionDto): Observable<PolicyContractVersionDto> {
    console.log('update policy contract version: id = ' + policyContractVersion.id);
    return this.post<PolicyContractVersionDto>(policyContractVersion, this.url);
  }

  savePolicyContractVersion(policyContractVersion: PolicyContractVersionDto): Observable<PolicyContractVersionDto> {
    if (policyContractVersion.id > 0) {
      return this.updatePolicyContractVersion(policyContractVersion);
    } else {
      return this.createPolicyContractVersion(policyContractVersion);
    }
  }

  getPreview(id: number): Observable<PolicyContractPreviewDto> {
    return this.get<PolicyContractPreviewDto>(this.url + '/preview/' + id);
  }

  getPreviewByMasterPolicyId(masterPolicyId: number): Observable<PolicyContractPreviewDto> {
    return this.get<PolicyContractPreviewDto>(this.url + '/preview?masterPolicyId=' + masterPolicyId);
  }

  countLossRatio(id: number, skipPaid: boolean): Observable<PolicyContractLossRatioDto> {
    return this.get<PolicyContractLossRatioDto>(this.url + '/lossRatio/' + id + '?skipPaid=' + skipPaid);
  }

  sendUnifiedText(templateId: number, pcvId: number): Observable<DocumentDto> {
    return this.get<DocumentDto>(this.url + '/sendUnifiedText?templateId=' + templateId + '&pcvId=' + pcvId);
  }

  getClientProducts(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/clientProducts');
  }

  termination(pcId: number): Observable<PolicyContractVersionDto> {
    return this.get<PolicyContractVersionDto>(this.url + '/termination?pcId=' + pcId);
  }

  resignationBeforeStart(policyContractVersion: PolicyContractVersionDto): Observable<PolicyContractVersionDto> {
    console.log('resignation before start: id = ' + policyContractVersion.id);
    return this.post<PolicyContractVersionDto>(policyContractVersion, this.url + '/resignationBeforeStart');
  }

  getClientPolicies(): Observable<SearchResult<PolicyContractVersionSimpleDto>> {
    const criteria = <SearchCriteria<PolicyContractVersionCriteriaDto>>{};
    criteria.criteria = <PolicyContractVersionCriteriaDto>{};
    criteria.criteria.policyYearVersionPhase = 'LAST';
    criteria.criteria.versionPhase = 'LAST';
    criteria.page = <Page>{start: 0, count: 300};
    criteria.sortBy = <SortBy>{column: 'mpc.number'};
    return this.searchByCriteria<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>(criteria);
  }

  @Cacheable()
  getProductConfiguration(): Observable<PolicyProductConfigurationDto> {
    return this.get<PolicyProductConfigurationDto>(this.url + '/productConfiguration');
  }

  isRenewable(pcvId: number): Observable<boolean> {
    return this.get(this.url + '/isRenewable/' + pcvId);
  }

  canBeTerminated(pcvId: number): Observable<boolean> {
    return this.get(this.url + '/canBeTerminated/' + pcvId);
  }
}
