import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DeclarationParams} from '../../../../declaration/shared/declaration-details-view';
import {ImportType, PolicyContractType} from '../../../../../bonding_shared/model/dictionary-ids';
import * as moment from 'moment';
import {
  DeclarationCriteriaDto,
  DeclarationFileImportDto,
  DeclarationMetadataDto,
  DictionaryBaseDto,
  FileImportSimpleDto,
  PolicyContractDto,
  SearchResult,
} from '../../../../../bonding_shared/model';
import {
  AppConfigService,
  FormatService,
  GrowlService,
  LimitListType,
  PolicyContractService,
  PolicyContractVersionService,
  PolicyLimitListService,
  RouterService,
} from '../../../../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {DeclarationService} from '../../../../../bonding_shared/services/declaration.service';
import {BusinessUtils} from '../../../../../bonding_shared/utils/business-utils';
import {DeclarationImportDetailsView} from '../../../../declaration/shared/declaration-import-details-view';
import {DeclarationFileService} from '../../../../../bonding_shared/services';

@Component({
  selector: 'policy-declaration-import',
  templateUrl: './policy-declaration-import.component.pug',
})
export class PolicyDeclarationImportComponent extends DeclarationImportDetailsView implements OnInit {
  private importTitleMsg: String;

  constructor(
    private route: ActivatedRoute,
    protected appService: AppConfigService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected declarationService: DeclarationService,
    protected declarationFileService: DeclarationFileService,
    public policyContractVersionService: PolicyContractVersionService,
    public policyContractService: PolicyContractService,
    private policyLimitListService: PolicyLimitListService,
    private formatService: FormatService,
    private routerService: RouterService
  ) {
    super(appService, translateService, growlService);
  }

  protected initializeImportDeclaration() {
    this.initializeFileDataProvider(this.declarationFileService);
    this.importDeclaration = <DeclarationFileImportDto>{
      type: <DictionaryBaseDto>{
        id: this.selectedPolicyIsOutstanding()
          ? this.getOutstandingsDeclarationIntranetType()
          : this.getTurnoverDeclarationIntranetType(),
      },
      metadata: <DeclarationMetadataDto>{},
    };
  }

  protected getTurnoverDeclarationIntranetType() {
    if (this.appService && this.dcComponent) {
      return this.appService.kuke && this.dcComponent.nnPolicy
        ? ImportType.TURNOVER_KUKE_WITH_NN_INTRANET_DECLARATION
        : ImportType.TURNOVER_KUKE_INTRANET_DECLARATION;
    } else {
      return undefined;
    }
  }

  private getOutstandingsDeclarationIntranetType() {
    if (this.appService && this.appService.kuke && this.dcComponent) {
      return this.dcComponent.nnPolicy
        ? ImportType.OUTSTANDINGS_WITH_NN_KUKE_INTRANET_DECLARATION
        : ImportType.OUTSTANDINGS_KUKE_INTRANET_DECLARATION;
    }
    return undefined;
  }

  private initializeFileDataProvider(service: DeclarationFileService) {
    this.fileDataProvider = BusinessUtils.createFileImportDataProvider(service);
    if (this.appService.kuke) {
      this.fileDataProvider.searchCriteria.criteria.types = [
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_PORTAL_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_INTRANET_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_INTRANET_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_PORTAL_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_KUKE_INTRANET_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_KUKE_PORTAL_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_KUKE_INTRANET_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_NN_INTRANET_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_NN_PORTAL_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_NN_INTRANET_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_NN_PORTAL_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_WITH_NN_KUKE_INTRANET_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_WITH_NN_KUKE_PORTAL_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.OUTSTANDINGS_WITH_NN_KUKE_INTRANET_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_INTRANET_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_PORTAL_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_INTRANET_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_PORTAL_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_INTRANET_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_PORTAL_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_INTRANET_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_PORTAL_AMENDMENT},
      ];
    } else if (this.appService.ecg) {
      this.fileDataProvider.searchCriteria.criteria.types = [
        <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_INTRANET_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_INTRANET_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_PORTAL_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_WITH_NN_INTRANET_DECLARATION},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_WITH_NN_INTRANET_AMENDMENT},
        <DictionaryBaseDto>{id: ImportType.TURNOVER_ECG_WITH_NN_PORTAL_DECLARATION},
      ];
    }
  }

  ngOnInit() {
    if (this.appService.kuke || this.appService.ecg) {
      this.route.params.subscribe((params) => {
        const dcParams: DeclarationParams = <DeclarationParams>{
          paramRpId: +params['reportingPeriodId'],
          paramLlvId: +params['limitListVersionId'],
          paramDecOnId: +params['declaredOnId'],
        };
        const policyContractId = +params['policyContractId'];
        this.policyContractService.getById<PolicyContractDto>(policyContractId).subscribe((pc) => {
          this.dcComponent.onInitIntranetByPolicy(PolicyContractService.toPolicyContractSimpleIdDto(pc), dcParams);
        });
      });
    }
  }

  public onPolicyChanged() {}

  public onDeclarationCriteriaTouchedEvent() {
    this.inProgress_flag = true;
    this.confirmedDeclarationForSelectedParams = undefined;
    this.fileTable.handleSearchResult(<SearchResult<DeclarationFileImportDto>>{});
  }

  public onDeclarationCriteriaChangedEvent(reloadDeclaration: boolean) {
    this.resetGenerateCriteria();
    this.refreshFileTable();
    this.recalculateTitle();
  }

  // ############## FILE MANAGEMENT ##############
  generateLimitListFile() {
    this.initializeImportDeclaration();
    // generate template ?
    this.inProgress_flag = true;
    const fileName = [
      this.filePrefix,
      this.dc.selectedPolicy.number,
      this.dc.selectedRp.id,
      this.dc.selectedLlv.id,
      this.formatService.formatDateTime(new Date()),
    ].join(' ');
    this.declarationFileService.generateLimitListFile(
      this.generateCriteria.criteria,
      this.amendment ? LimitListType.AMENDMENT : LimitListType.DECLARATION,
      fileName + '.xlsx',
      (errorMsg) => this.errorCallback(errorMsg),
      (file) => this.exportCompleteCallback(file)
    );
  }

  get filePrefix(): String {
    if (this.appService.kuke) {
      return this.fileKukePrefix;
    }
    if (this.appService.ecg) {
      return this.fileEcgPrefix;
    }
  }

  get fileKukePrefix(): String {
    if (this.selectedPolicyIsOutstanding()) {
      if (this.amendment) {
        return 'salda korekta';
      } else {
        return 'salda zgłoszenia';
      }
    } else {
      if (this.dc.productDependentOnPremiumRate) {
        if (this.amendment) {
          return 'zmienna stawka obroty korekta';
        } else {
          return 'zmienna stawka obroty zgłoszenia';
        }
      } else {
        if (this.amendment) {
          return 'obroty korekta';
        } else {
          return 'obroty zgłoszenia';
        }
      }
    }
  }

  get fileEcgPrefix(): String {
    if (this.amendment) {
      return 'turnover amendment';
    } else {
      return 'turnover declaration';
    }
  }

  downloadFile(file: FileImportSimpleDto, input: boolean) {
    this.declarationFileService.downloadFile(
      file.id,
      input,
      input ? file.fileName : file.outputFileName,
      (errorMsg) => this.errorCallback(errorMsg),
      () => this.downloadCompleteCallback(input ? file.fileName : file.outputFileName)
    );
  }

  importTurnoverByFile() {
    this.clearErrors();
    if (!this.selectedFile) {
      this.growlService.error('No file selected!');
      return;
    }
    this.inProgress = true;
    this.importDeclaration.metadata.rpId = this.dc.selectedRp.id;
    this.importDeclaration.metadata.llvId = this.dc.selectedLlv.id;
    this.importDeclaration.metadata.decOnId = this.dc.selectedDecOn ? this.dc.selectedDecOn.id : undefined;
    this.importDeclaration.metadata.amendment = this.amendment;
    if (this.appService.kuke) {
      this.importDeclaration.type = <DictionaryBaseDto>{
        id: this.selectedPolicyIsOutstanding()
          ? this.amendment
            ? this.dcComponent.nnPolicy
              ? ImportType.OUTSTANDINGS_WITH_NN_KUKE_INTRANET_AMENDMENT
              : ImportType.OUTSTANDINGS_KUKE_INTRANET_AMENDMENT
            : this.dcComponent.nnPolicy
            ? ImportType.OUTSTANDINGS_WITH_NN_KUKE_INTRANET_DECLARATION
            : ImportType.OUTSTANDINGS_KUKE_INTRANET_DECLARATION
          : this.amendment
          ? this.dcComponent.nnPolicy
            ? this.dc.productDependentOnPremiumRate
              ? ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_INTRANET_AMENDMENT
              : ImportType.TURNOVER_KUKE_WITH_NN_INTRANET_AMENDMENT
            : this.dc.productDependentOnPremiumRate
            ? ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_INTRANET_AMENDMENT
            : ImportType.TURNOVER_KUKE_INTRANET_AMENDMENT
          : this.dcComponent.nnPolicy
          ? this.dc.productDependentOnPremiumRate
            ? ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_WITH_NN_INTRANET_DECLARATION
            : ImportType.TURNOVER_KUKE_WITH_NN_INTRANET_DECLARATION
          : this.dc.productDependentOnPremiumRate
          ? ImportType.TURNOVER_KUKE_WITH_PREMIUM_RATE_INTRANET_DECLARATION
          : ImportType.TURNOVER_KUKE_INTRANET_DECLARATION,
      };
    }
    if (this.appService.ecg) {
      this.importDeclaration.type = <DictionaryBaseDto>{
        id: this.amendment
          ? this.dcComponent.nnPolicy
            ? ImportType.TURNOVER_ECG_WITH_NN_INTRANET_AMENDMENT
            : ImportType.TURNOVER_ECG_INTRANET_AMENDMENT
          : this.dcComponent.nnPolicy
          ? ImportType.TURNOVER_ECG_WITH_NN_INTRANET_DECLARATION
          : ImportType.TURNOVER_ECG_INTRANET_DECLARATION,
      };
    }

    this.declarationFileService.importTurnoverByFile(this.importDeclaration).subscribe(
      () => {
        this.inProgress = false;
        this.selectedFile = undefined;
        this.showSavedMsg();
      },
      (error) => this.handleServerError(error),
      () => this.refreshFileTable()
    );
  }

  // ############## SEARCH CRITERIA ##############
  protected resetGenerateCriteriaByIds(rpId: number, llvId: number, decOnId: number, finishInProgress = false) {
    this.generateCriteria.criteria = <DeclarationCriteriaDto>{
      reportingPeriodId: rpId,
      limitListVersionId: llvId,
      declaredOnId: decOnId,
    };
    // set proper values of amendmentImport and inProgress_flag flags
    if (this.dcComponent && this.dcComponent.singleFinalConfirmedDeclarationsForPolicy) {
      this.confirmedDeclarationForSelectedParams = this.dcComponent.singleFinalConfirmedDeclarationsForPolicy.find(
        (t) =>
          t.reportingPeriod.id === rpId &&
          t.limitListVersion.id === llvId &&
          (t.declaredOn ? t.declaredOn.id === decOnId : decOnId === undefined)
      );
      const now = new Date();
      const effectiveDeclarationExists =
        this.confirmedDeclarationForSelectedParams !== undefined &&
        moment(this.confirmedDeclarationForSelectedParams.effectiveDate).isBefore(now);
      const pastRp = rpId && moment(this.dc.selectedRp.reportingTo).endOf('day').isBefore(now);
      this.amendmentView = effectiveDeclarationExists || pastRp;
      if (finishInProgress) {
        this.inProgress_flag = false;
      }
    }
  }

  selectedPolicyIsOutstanding(): boolean {
    return (
      this.appService &&
      this.appService.kuke &&
      this.dc.selectedPolicy &&
      this.dc.selectedPolicy.productType &&
      this.dc.selectedPolicy.productType.id === PolicyContractType.KUKE_KOM_SP
    );
  }

  selectedPolicyIsDependentOnPremiumRate(): boolean {
    return (
      this.appService &&
      this.appService.kuke &&
      this.dc.selectedPolicy &&
      this.dc.selectedPolicy.productType &&
      this.dc.productDependentOnPremiumRate
    );
  }

  recalculateTitle() {
    this.importTitleMsg = this.amendment
      ? this.selectedPolicyIsOutstanding()
        ? 'declaration.details.importOutstandingsAmendmentTitle'
        : 'declaration.details.importTurnoverAmendmentTitle'
      : this.selectedPolicyIsOutstanding()
      ? 'declaration.details.importOutstandingsTitle'
      : 'declaration.details.importTurnoverTitle';
  }

  get title(): String {
    return this.importTitleMsg;
  }
}
