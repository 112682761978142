import {Component, OnInit} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {AppConfigService, PolicyContractVersionService} from '../../../../../bonding_shared/services';
import {ReportingPeriodDto} from '../../../../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'turnover-reporting-section',
  templateUrl: './turnover-reporting-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class TurnoverReportingSectionComponent extends Section implements OnInit {
  self = this;

  turnoverNotificationCodeRegexpKOM: RegExp = /^(?!.*(FF|FFD|FFY|W)).*$/;
  turnoverNotificationCodeRegexpGSP: RegExp = /^(?:M)$/;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected policyContractVersionService: PolicyContractVersionService
  ) {
    super(policyContractViewService, appService, policyContractVersionService);
  }

  ngOnInit() {
    if (this.policy.policyContract.turnoverNotification) {
      this.notificationToRestore = JSON.parse(JSON.stringify(this.policy.policyContract.turnoverNotification));
    }
  }

  get now() {
    return new Date();
  }

  turnoverNotificationCodeRegexp(): RegExp {
    if (this.policy && this.policy.businessUnit && this.policy.businessUnit.name === 'KOM') {
      return this.turnoverNotificationCodeRegexpKOM;
    } else {
      return this.turnoverNotificationCodeRegexpGSP;
    }
  }

  minReportingTo(rp: ReportingPeriodDto): Date {
    if (rp && moment(rp.periodTo).isAfter(this.now)) {
      return moment(rp.periodTo).utc().add(1, 'days').toDate();
    } else {
      return new Date();
    }
  }

  reportingPeriodsEditable(): boolean {
    return this.policy.lastActivated && this.policyContractViewService.reportingPeriodsEditable;
  }

  reportingToEditable(rp: ReportingPeriodDto): boolean {
    return moment(rp.reportingTo).isAfter(this.now);
  }

  getIndex(item) {
    return this.policy.policyContract.reportingPeriods.indexOf(item) + 1;
  }

  turnoverDataDisabled() {
    return true;
  }

  onDone(rp: ReportingPeriodDto) {
    if (!this.reportingToEditable(rp)) {
      return;
    }
    const minRPDate = this.minReportingTo(rp);
    const modifiedRP = this.policy.policyContract.reportingPeriods.find((item) =>
      item.id ? item.id === rp.id : moment(item.periodTo).isSame(moment(rp.periodTo))
    );
    // in case of value entered by hand and before minDate
    if (moment(rp.reportingTo).isBefore(minRPDate)) {
      modifiedRP.reportingTo = minRPDate;
    }
    modifiedRP.reportingTo.setHours(23, 59, 59, 999);
  }
}
