import {Component, ViewChild} from '@angular/core';
import {RouterService} from '../../bonding_shared/services/router-service';
import {SearchViewComponent} from '../../bonding_shared/components/search/search-view/search-view.component';
import {
  DictionaryDto,
  ErrorReason,
  LimitRequestCriteriaDto,
  LimitRequestDto,
  PolicyContractVersionCriteriaDto,
  PortalUserContextDto,
} from '../../bonding_shared/model/dtos';
import {LimitListComponent} from '../../bonding/limit/shared/components/limit-list.component';
import {LoggedUserService} from '../../bonding_shared/services/logged-user.service';
import {SearchView} from '../../bonding_shared/components/search/search-view/search-view';
import {
  LimitCoverPlusStatus,
  LimitDecisionStatus,
  LimitDecisionType,
  LimitRequestStatus,
  LimitRequestType,
  LimitStatus,
} from '../../bonding_shared/model/dictionary-ids';
import {LimitGuiService} from '../../bonding/limit/shared/services/limit-gui.service';
import {DictionaryBaseDto} from '../../bonding_shared/model';
import {
  AppConfigService,
  DictionaryBaseService,
  DictionaryService,
  GrowlService,
  LimitService,
} from '../../bonding_shared/services';
import {PortalUserService} from '../services/portal-user.service';
import {ClientPolicyComboComponent} from '../policy/shared/client-policy-combo.component';
import {TranslateService} from '@ngx-translate/core';
import {DateUtils} from '../../bonding_shared/utils/date-utils';

@Component({
  selector: 'client-limit-search',
  templateUrl: './client-limit-search.component.pug',
})
export class ClientLimitSearchComponent extends SearchView {
  @ViewChild(SearchViewComponent, {static: true}) searchView: SearchViewComponent<
    LimitRequestCriteriaDto,
    LimitRequestDto
  >;
  @ViewChild(LimitListComponent, {static: true}) limitList: LimitListComponent;

  @ViewChild('policyCombo', {static: true}) policyCombo: ClientPolicyComboComponent;

  policySearchCriteria: PolicyContractVersionCriteriaDto = <PolicyContractVersionCriteriaDto>{};
  requestTypes: DictionaryBaseDto[];
  userContext: PortalUserContextDto;
  readonly LimitRequestStatus = LimitRequestStatus;
  readonly LimitCoverPlusStatus = LimitCoverPlusStatus;
  showRequest = false;
  productTypes: DictionaryBaseDto[];

  readonly hiddenStatuses = new Set([
    LimitRequestStatus.INACTIVE,
    LimitDecisionStatus.INACTIVE,
    LimitDecisionStatus.SENT_TO_APPROVAL,
    LimitDecisionStatus.UNAPPROVED,
    LimitStatus.ABANDONED,
    LimitStatus.MOVED,
    LimitCoverPlusStatus.UNACCEPTED_PROPOSAL,
    LimitCoverPlusStatus.INACTIVE_PROPOSAL,
    LimitCoverPlusStatus.INACTIVE_COVER,
  ]);

  constructor(
    public guiService: LimitGuiService,
    public router: RouterService,
    public loggedUser: LoggedUserService,
    private userService: PortalUserService,
    private limitService: LimitService,
    private growlService: GrowlService,
    private translateService: TranslateService,
    private dictionaryService: DictionaryService,
    private dictionaryBaseService: DictionaryBaseService,
    private appService: AppConfigService
  ) {
    super();
    this.selectorNameList = ['Buyer'];
    this.initializeSelectorEmitters(true);
    // TODO: temporarily clean criteria because they are not shown on comeback to the view
    this.guiService.searchCriteria.cleanCriteria();

    loggedUser.loggedUsersSubject.subscribe((newUser) =>
      this.guiService.searchCriteria.setInsuredId(newUser.company.id)
    );
    this.userService.getPortalUserContext().subscribe((ctx) => {
      this.userContext = ctx;
      this.dictionaryService.getDictionary('LimitRequestType').subscribe((dicts) => this.setLimitTypes(dicts));
      this.dictionaryBaseService
        .getDictionaryBase('PolicyContractType')
        .subscribe(
          (dicts) =>
            (this.productTypes = dicts.filter((d) => this.userContext.policies.find((p) => p.productType.id === d.id)))
        );
    });
  }

  onChangePolicy(masterPolicyContractId: number) {
    console.log('onChangePolicy masterPolicyContractId = ', masterPolicyContractId);
    this.guiService.searchCriteria.setPolicyMasterPolicyId(masterPolicyContractId);
  }

  onChangeCoOwner(companyId: number) {
    this.criteria.policyLimitListOwnerIdsAll = companyId ? [companyId] : [];
  }

  onCreateNewRequest(type: DictionaryBaseDto) {
    const buyerId = this.searchView.dataProvider.searchCriteria.criteria.buyerCompanyId;
    const insuredId = this.searchView.dataProvider.searchCriteria.criteria.insuredId;
    const policyId = this.searchView.dataProvider.searchCriteria.criteria.masterPolicyContractId;
    this.router.toLimitDetailsNew(insuredId, policyId, buyerId, type.id);
  }

  reductionSelected() {
    const criteria = this.guiService.searchCriteria.dataProvider.searchCriteria.criteria;
    return criteria.decisionType && criteria.decisionType.id === LimitDecisionType.REDUCTION;
  }

  get criteria() {
    return this.guiService.searchCriteria.dataProvider.searchCriteria.criteria;
  }

  setLimitTypes(types: DictionaryDto[]) {
    const allowedTypes = [LimitRequestType.STANDARD];
    if (this.userContext.policyWithFalconClause) {
      allowedTypes.push(LimitRequestType.FALCON);
    }
    if (this.userContext.policyWithFarmerClause) {
      allowedTypes.push(LimitRequestType.FARMER);
    }
    this.requestTypes = types.filter((t) => allowedTypes.includes(t.id));
  }

  export() {
    this.searchView.serverErrors = undefined;
    this.limitService
      .executeExportAsync(this.guiService.searchCriteria.dataProvider.searchCriteria.criteria)
      .subscribe({
        next: () => this.growlService.notice('common.reportGeneration'),
        error: (error) => (this.searchView.serverErrors = error),
      });
  }

  searchByStatus(statusId: number, cover: boolean) {
    this.guiService.searchCriteria.cleanCriteria();
    this.policyCombo.reset();
    if (cover) {
      this.criteria.hasCoverInAcceptedDecision = true;
      this.criteria.coverPlusStatus = <DictionaryBaseDto>{id: statusId};
    } else {
      if (this.kuke) {
        this.criteria.businessStatus = <DictionaryBaseDto>{id: statusId};
      } else {
        this.criteria.statusIds = [LimitRequestStatus.PENDING, LimitRequestStatus.UNRESOLVED_COMPANY];
      }
    }
    this.limitList.search();
  }

  requestChanged() {
    this.criteria.creationDateRange.dateFrom = undefined;
    this.criteria.creationDateRange.dateTo = undefined;
    this.criteria.type = undefined;
    this.criteria.id = undefined;
  }

  generateAndDownloadLimitListReport() {
    this.searchView.dataProvider.inProgress = true;
    this.limitService.generateLimitListReport(
      this.buildLimitListReportFileName(),
      this.generateReportErrorCallback.bind(this),
      this.generateReportCompleteCallback.bind(this)
    );
  }

  generateReportErrorCallback(err: string): void {
    this.searchView.serverErrors = <ErrorReason[]>JSON.parse(err);
    this.searchView.showErrors = true;
    this.searchView.dataProvider.inProgress = false;
  }

  generateReportCompleteCallback(): void {
    this.searchView.serverErrors = undefined;
    this.searchView.showErrors = false;
    this.searchView.dataProvider.inProgress = false;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get ecgPortal(): boolean {
    return this.appService.ecg && this.loggedUser.portal;
  }

  private buildLimitListReportFileName(): string {
    const baseName = this.translateService.instant('limitSearch.generateLimitListReport');
    const formattedDate = DateUtils.format(DateUtils.today());
    return baseName + ' ' + formattedDate + '.xlsx';
  }
}
