import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {NgForm, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PolicyContractVersionListComponent} from './components';
import {
  AppConfigService,
  BackendError,
  BusinessReportService,
  CompanyService,
  CompanySimpleDto,
  ConfirmDialogComponent,
  CustomValidators,
  DictionaryBaseDto,
  DictionaryBaseService,
  DictionaryDto,
  DocumentService,
  FormDialogComponent,
  GrowlService,
  InquiryService,
  LoggedUserService,
  MasterPolicyContractBaseDto,
  MasterPolicyContractDto,
  MasterPolicyContractService,
  PolicyContractBaseDto,
  PolicyContractDto,
  PolicyContractService,
  PolicyContractVersionDto,
  PolicyContractVersionService,
  RelatedObject,
  RouterService,
  TemplateService,
  TemplateSimpleDto,
} from '../../bonding_shared';
import {PolicyContractViewSection, PolicyContractViewService} from './services/policy-contract-view.service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {Button} from '../../bonding_shared/components/details-view/button';
import {
  BusinessObjectType,
  ContactNoteElementaryRight,
  DocumentType,
  FrontingType,
  POLICY_CONTRACT_REQUEST_OR_TERMINATION_REQUEST_STATUSES,
  PolicyContractType,
  PolicyContractVersionStatus,
  PolicyElementaryRight,
  PolicyTerminationType,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
  TaskType,
} from '../../bonding_shared/model/dictionary-ids';
import {CrPolicyProductComponent} from './components/products/cr-policy-product.component';
import {KukePolicyProductComponent} from './components/products/kuke-policy-product.component';
import {CaptiveProductComponent, ExcessOfLossProductComponent, TopUpProductComponent} from './components/products/cr';
import {
  KomEpProductComponent,
  KomFeProductComponent,
  KomFkProductComponent,
  KomOpProductComponent,
  KomSpProductComponent,
} from './components/products/kuke/kom';
import {GspPbgProductComponent} from './components/products/kuke/gsp';
import * as moment from 'moment';

import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'policy-contract-details',
  templateUrl: './policy-contract-details.component.pug',
})
export class PolicyContractDetailsComponent extends DetailsView implements OnInit {
  @ViewChild('rejectPolicyRequestDialog', {static: true}) rejectPolicyRequestDialog: FormDialogComponent;
  @ViewChild('policyResignationDialog', {static: true}) policyResignationDialog: FormDialogComponent;

  public readonly BusinessObjectType = BusinessObjectType;
  public readonly PolicyContractViewService = PolicyContractViewService;
  public readonly PolicyContractVersionStatus = PolicyContractVersionStatus;
  public readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  public readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  public readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  documentReceivedAndSignatureDateRequired = true;
  public readonly PolicyElementaryRight = PolicyElementaryRight;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  _policyContractVersionList: PolicyContractVersionListComponent;

  @ViewChild(PolicyContractVersionListComponent)
  set policyContractVersionList(policyContractVersionList: PolicyContractVersionListComponent) {
    this._policyContractVersionList = policyContractVersionList;
  }

  get policyContractVersionList(): PolicyContractVersionListComponent {
    return this._policyContractVersionList;
  }

  // called in ??
  @ViewChild(ConfirmDialogComponent, {static: true}) confirmDialog: ConfirmDialogComponent;

  // CR products components
  @ViewChild('topUpProductComponent', {static: true}) topUpProductComponent: TopUpProductComponent;
  // Excess of loss and Excess of loss partner
  @ViewChild('excessOfLossProductComponent', {static: true}) excessOfLossProductComponent: ExcessOfLossProductComponent;
  @ViewChild('captiveProductComponent', {static: true}) captiveProductComponent: CaptiveProductComponent;

  // KUKE products components
  @ViewChild('komOpProductComponent', {static: true}) komOpProductComponent: KomOpProductComponent;
  @ViewChild('komEpProductComponent', {static: true}) komEpProductComponent: KomEpProductComponent;
  @ViewChild('komFeProductComponent', {static: true}) komFeProductComponent: KomFeProductComponent;
  @ViewChild('komFkProductComponent', {static: true}) komFkProductComponent: KomFkProductComponent;
  @ViewChild('komSpProductComponent', {static: true}) komSpProductComponent: KomSpProductComponent;
  @ViewChild('gspPbgProductComponent', {static: true}) gspPbgProductComponent: GspPbgProductComponent;

  @ViewChild('crPolicyProductComponent', {static: true}) crPolicyProductComponent: CrPolicyProductComponent;
  @ViewChild('kukePolicyProductComponent', {static: true}) kukePolicyProductComponent: KukePolicyProductComponent;

  serverErrors: BackendError;

  public selectedPolicyContractVersion: PolicyContractVersionDto = <PolicyContractVersionDto>{};
  masterPolicyContract: MasterPolicyContractDto;
  previousStatus: DictionaryBaseDto;

  self = this;

  showErrors = false;
  isCreatingNewPolicyYear = false;

  policyContractTypeSet: boolean;
  versionListReady: boolean;

  viewSection = PolicyContractViewSection;

  dropButtons: {
    newPolicyVersionButton: Button;
    newPolicyYearButton: Button;
    newPolicyClosingVersion: Button;
    newPolicyReplaceVersion: Button;
  } = {
    newPolicyVersionButton: new Button(
      'common.button.newVersion',
      this.onCreateNewVersion.bind(this),
      true,
      true,
      'calendar'
    ),
    newPolicyYearButton: new Button(
      'common.button.newPolicyYear',
      this.onCreateNewPolicyYear.bind(this),
      true,
      true,
      'calendar'
    ),
    newPolicyClosingVersion: new Button(
      'common.button.newClosingVersion',
      this.onCreateNewClosingVersion.bind(this),
      true,
      true,
      'calendar'
    ),
    newPolicyReplaceVersion: new Button(
      'common.button.newReplaceVersion',
      this.onCreateNewReplaceVersion.bind(this),
      true,
      true,
      'calendar'
    ),
  };
  renewButtons: {
    renewalWithoutChanges: Button;
    renewalInquiry: Button;
    renewalOffer: Button;
  } = {
    renewalWithoutChanges: new Button(
      'policy.details.renewal.withoutChanges',
      this.onRenewalWithoutChanges.bind(this),
      false,
      false,
      'calendar'
    ),
    renewalInquiry: new Button(
      'policy.details.renewal.inquiry',
      this.onRenewalInquiry.bind(this),
      false,
      false,
      'calendar'
    ),
    renewalOffer: new Button('policy.details.renewal.offer', this.onRenewalOffer.bind(this), false, false, 'calendar'),
  };

  readonly unifiedTextItems: {name: string; action: () => any}[] = [
    {name: 'policyContract.preview.unifiedText.generate', action: () => this.unifiedTextGenerate()},
    {name: 'policyContract.preview.unifiedText.send', action: () => this.unifiedTextSend()},
  ];

  newRenewalInquiryHidden = true;
  changeState_flag = false;

  credendoAnyNewActionPossible_flag = false;

  kukeAnnexInquiryAvailable_flag = false;
  kukeRenewal_flag = false;
  kukeNewVersion_flag = false;
  kukeUnifiedText_flag = false;

  // kuke, credendo
  termination_flag = false;
  // kuke
  resignationBeforeStart_flag = false;

  PolicyContractType = PolicyContractType;
  private _presentationMode = false;

  dictionarySelectors: DictionaryBaseDto[] = [];

  wordingTemplates: TemplateSimpleDto[] = [];

  constructor(
    protected _companyService: CompanyService,
    protected policyContractService: PolicyContractService,
    protected loggedUserService: LoggedUserService,
    public policyContractVersionService: PolicyContractVersionService,
    protected masterPolicyContractService: MasterPolicyContractService,
    protected route: ActivatedRoute,
    public routerService: RouterService,
    protected templateService: TemplateService,
    protected policyContractViewService: PolicyContractViewService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected cd: ChangeDetectorRef,
    public appService: AppConfigService,
    protected inquiryService: InquiryService,
    protected dictionaryBaseService: DictionaryBaseService,
    private documentService: DocumentService,
    protected businessReportService: BusinessReportService
  ) {
    super(growlService);

    this.hideButtons(false);
    this.deleteButton.hidden = true; // No logic for deleting policy on backend
    this.newVersionButton.hidden = true;

    this.selectorNameList = [RelatedObject.CLIENT];
    this.initializeSelectorEmitters(true);
  }

  get readonly() {
    return this.policyContractViewService.readonly;
  }

  set readonly(readonly) {
    this.policyContractViewService.readonly = readonly;
    this.handleButtonsWithParam(readonly);
    if (!readonly) {
      this.policyContractViewService.reportingPeriodsEditable = false;
    }
  }

  handleButtonsWithParam(readonlyParam: boolean) {
    const totallyReadonly = readonlyParam && !this.policyContractViewService.reportingPeriodsEditable;
    const editionPossible = this.versionEditable();
    const hideSaveCancelButtons = totallyReadonly || !editionPossible;
    this.saveButton.hidden = hideSaveCancelButtons;
    this.cancelButton.hidden = hideSaveCancelButtons;
    this.editButton.hidden = !(totallyReadonly && editionPossible);
  }

  ngOnInit() {
    this.readOnlyMode = true;
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  // ngOnInit - starting point
  //
  // 1. ekstrakcja parametrów
  // 2. inicjalizacja polisy (initializeContract)
  // 3. utworzenie formularza
  initializeView(params: Params) {
    this.hideButtons(true);
    this.form = this.ngForm.form;
    const id = +params['id'];
    if (this.selectedPolicyContractVersion && id === this.selectedPolicyContractVersion.id) {
      return;
    }
    this.versionListReady = false;
    const clientId = +params['clientId'];
    const offerVersionId = +params['offerVersionId'];
    // prettier-ignore
    console.log('initialize view: contract-details id = ' + id + ', clientId = ' + clientId + ', offerVersionId = ' + offerVersionId);
    this.initializeContract(id, clientId, offerVersionId);
    this.form.addControl(
      'insureds',
      new UntypedFormGroup(
        {
          premiumInstalments: new UntypedFormControl({
            value: this.selectedPolicyContractVersion.premiumInstalments,
            disabled: this.premiumInstalmentsCheckboxDisabled(),
          }),
          premiumInstalmentCalendar: new UntypedFormControl(
            this.selectedPolicyContractVersion.premiumInstalementCalendar
          ),
          subinsured: new UntypedFormControl(this.selectedPolicyContractVersion.subinsured),
        },
        this.appService.kuke ? null : CustomValidators.minPremiumFixedEqualsPremiumInstalmentCalendarSum
      )
    );

    this._presentationMode = params['presentationMode'];
  }

  private premiumInstalmentsCheckboxDisabled() {
    return (
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.contractType &&
      this.policyContractViewService.isSectionVisible(
        this.selectedPolicyContractVersion.contractType.id,
        PolicyContractViewSection.PREMIUM__PREMIUM_INSTALMENTS_DISABLED,
        false
      )
    );
  }

  // setPolicyContractVersion (9 triggers)
  // takie afterInitialize
  //
  // głównie zmiany w formularzu
  policyVersionInitialized() {
    if (this.appService.credendo) {
      const ft = this.selectedPolicyContractVersion.frontingType;
      this.addOrRemoveRequiredControl(
        ft && ft.id === FrontingType.FRONTING_ASSUMED,
        'frontingTreaty',
        this.selectedPolicyContractVersion.frontingTreaty
      );
    }

    const gr = <UntypedFormGroup>this.form.get('insureds');
    if (!this.isSectionVisible(this.viewSection.PREMIUM__MIN_PREMIUM_FIXED, true)) {
      if (gr) {
        gr.removeControl('minPremiumFixed');
      }
    } else {
      gr.setControl(
        'premiumInstalments',
        new UntypedFormControl({
          value: this.selectedPolicyContractVersion.premiumInstalments,
          disabled: this.premiumInstalmentsCheckboxDisabled(),
        })
      );
    }
    if (
      !this.isSectionVisible(this.viewSection.PREMIUM__KUKE_PREMIUM_INSTALMENTS, true) &&
      !this.isSectionVisible(this.viewSection.PREMIUM__KUKE_PAYMENT_METHOD, true)
    ) {
      this.form.removeControl('insureds');
    }
  }

  private addOrRemoveRequiredControl(condition: boolean, controlName: string, value?: any) {
    if (condition && !this.form.contains(controlName)) {
      this.form.addControl(controlName, new UntypedFormControl(value ? value : '', Validators.required));
    }
    if (!condition && this.form.contains(controlName)) {
      this.form.removeControl(controlName);
    }
  }

  // initializeView
  // onCancel
  //
  // 1. wyczyszczenie wybranej polisy
  // 2. jeśli jest id to ustawienei polisy
  // 3. jeśli nie ma id to inicjalizacja

  initializeContract(contractVersionId: number, clientId: number, offerVersionId: number) {
    this.selectedPolicyContractVersion = <PolicyContractVersionDto>{};
    console.log(this.selectedPolicyContractVersion);
    if (contractVersionId > 0) {
      this.policyContractTypeSet = true;
      this.policyContractVersionService.getPolicyContractVersion(contractVersionId).subscribe(
        (policyContractVersion) => {
          this.setPolicyContractVersion(policyContractVersion, false);
          this.loadWordingTemplates();
        },
        () => console.log('Something went wrong on policyContractVersionService.getPolicyContractVersion')
      );
    } else {
      if (clientId && clientId > 0) {
        this.initializeCompany(clientId);
      }
      if (offerVersionId && offerVersionId > 0) {
      }
      this.policyContractTypeSet = false;
      // this.selectedPolicyContractVersion.policyContract.id = 0;
      this.selectedPolicyContractVersion.policyContract = <PolicyContractBaseDto>{};
      this.selectedPolicyContractVersion.policyContract.masterPolicyContract = <MasterPolicyContractBaseDto>{};
      this.selectedPolicyContractVersion.policyContract.policyYear = 1;
      this.selectedPolicyContractVersion.policyContract.policySubYear = 1;
      this.selectedPolicyContractVersion.policyContract.policySubYears = 1;
      this.selectedPolicyContractVersion.subinsured = [];
      this.selectedPolicyContractVersion.businessUnit = this.loggedUserService.getLoggedUserData().businessUnit;
      this.readonly = false; // this.appService.kuke
      this.cd.reattach();
    }
  }

  // initializeContract if there was clientId
  //
  // dopisanie firmy do polisy
  initializeCompany(clientId: number) {
    this._companyService
      .getCompanySimple(clientId)
      // .map(company => this.createContractCompany(company))
      // .subscribe(contractCompany => {
      //     this.onSetPolicyContractCompany(contractCompany);
      // });
      .subscribe((company) => {
        this.selectedPolicyContractVersion.client = company;
      });
  }

  /* Policy contract type set from the GUI
    1. Sets the policyContractTypeSet flags
    2. Calls setPolicyContractVersion*/
  onSetPolicyContractType(policyContractType: DictionaryDto) {
    if (!this.policyContractTypeSet) {
      this.policyContractTypeSet = true;
      this.handleClientCompanyType(policyContractType);
    }
  }

  getInitialContractVersion(policyContractTypeId: number) {
    this.policyContractService
      .getInitialContractVersion(
        this.selectedPolicyContractVersion.businessUnit.id,
        this.selectedPolicyContractVersion.client.address.country.id,
        policyContractTypeId
      )
      .subscribe((policyContractVersion) => this.setPolicyContractVersion(policyContractVersion, true));
  }

  handleClientCompanyType(policyContractType: DictionaryBaseDto) {
    this.getInitialContractVersion(policyContractType.id);
  }

  // onSave
  // onSelectPolicyContract
  // afterNewAnnex
  // afterNewPolicyYear
  //
  // ustawienie master policy contract
  // update buttonów
  setPolicyContract(policyContract: PolicyContractDto, reloadPolicyVersion: boolean) {
    console.log('<<<<<<<<< set Contract >>>>>>>>> ', policyContract);
    // if (this.contractVersionListComponent) {
    //   this.contractVersionListComponent.searchByPolicyContractId(policyContract.id);
    // }

    if (this.selectedPolicyContractVersion.policyContract.masterPolicyContract.id) {
      this.masterPolicyContractService
        .getMasterPolicyContract(this.selectedPolicyContractVersion.policyContract.masterPolicyContract.id)
        .subscribe((masterPolicy) => (this.masterPolicyContract = masterPolicy));
    }
    this.updateButtons();
    console.log('<//////// set Contract >>>>>>>>>');
  }

  // initializeContract
  // onSetPolicyContractType
  // onCreateNewVersion
  // onCreateNewPolicyYear
  // onSave
  // onCancel
  // onSelectPolicyContractVersion
  // afterNewAnnex
  // afterNewPolicYear
  //
  // ustawnie flagi policyContractTypeSet
  // wyczyszczenie idków ??
  // przeładowanie polisy
  // przeładowanie komponenetu listy wersji
  // ustawienie master policy
  // update buttonów
  // after initialize
  setPolicyContractVersion(policyContractVersion: PolicyContractVersionDto, isTemplate?: boolean) {
    console.log('<<<<<<<<< set Contract Version >>>>>>>>> ', policyContractVersion);
    this.serverErrors = null;
    this.policyContractTypeSet = true;

    this.readonly = !!policyContractVersion.id;

    if (isTemplate) {
      policyContractVersion = Object.assign(this.selectedPolicyContractVersion, policyContractVersion);
    } else {
      policyContractVersion = Object.assign(<PolicyContractVersionDto>{}, policyContractVersion);
    }

    this.selectedPolicyContractVersion = policyContractVersion;
    this.previousStatus = policyContractVersion.status;

    if (this.policyContractVersionList) {
      this.policyContractVersionList.selectedPolicyContractVersion = policyContractVersion;
    }

    if (!this.masterPolicyContract && this.selectedPolicyContractVersion.policyContract.masterPolicyContract.id) {
      this.masterPolicyContractService
        .getMasterPolicyContract(this.selectedPolicyContractVersion.policyContract.masterPolicyContract.id)
        .subscribe((masterPolicy) => (this.masterPolicyContract = masterPolicy));
    }
    if (policyContractVersion.warnings) {
      this.handleServerError(policyContractVersion.warnings);
    }
    this.inProgress = false;
    this.updateButtons();
    this.policyVersionInitialized();

    this.updateForm();

    this.cd.reattach();
    console.log('<//////// set Contract Version >>>>>>>>>');
  }

  private updateForm() {
    if (this.selectedPolicyContractVersion.reinsuranceMode) {
      setTimeout(
        () => this.form.get('reinsuranceMode').setValue(this.selectedPolicyContractVersion.reinsuranceMode),
        0
      );
    }
    if (this.selectedPolicyContractVersion.frontingTreaty) {
      this.form.get('frontingTreaty').setValue(this.selectedPolicyContractVersion.frontingTreaty);
    }
    if (this.form.get('insuredTurnoverDomestic')) {
      this.form.get('insuredTurnoverDomestic').setValue(this.selectedPolicyContractVersion.insuredTurnoverDomestic);
      this.form.get('insuredTurnoverDomestic').updateValueAndValidity();
    }
    if (this.form.get('insuredTurnoverExport')) {
      this.form.get('insuredTurnoverExport').setValue(this.selectedPolicyContractVersion.insuredTurnoverExport);
      this.form.get('insuredTurnoverExport').updateValueAndValidity();
    }
    if (this.form.get('insuredTurnover')) {
      this.form.get('insuredTurnover').setValue(this.selectedPolicyContractVersion.insuredTurnover);
      this.form.get('insuredTurnover').updateValueAndValidity();
    }
    if (this.form.get('clientTurnoverDomestic')) {
      this.form.get('clientTurnoverDomestic').setValue(this.selectedPolicyContractVersion.clientTurnoverDomestic);
      this.form.get('clientTurnoverDomestic').updateValueAndValidity();
    }
    if (this.form.get('clientTurnoverExport')) {
      this.form.get('clientTurnoverExport').setValue(this.selectedPolicyContractVersion.clientTurnoverExport);
      this.form.get('clientTurnoverExport').updateValueAndValidity();
    }
    if (this.form.get('clientTurnover')) {
      this.form.get('clientTurnover').setValue(this.selectedPolicyContractVersion.clientTurnover);
      this.form.get('clientTurnover').updateValueAndValidity();
    }
  }

  selectedPCVInStatus(status: PolicyContractVersionStatus): boolean {
    return (
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.id > 0 &&
      this.selectedPolicyContractVersion.status &&
      this.selectedPolicyContractVersion.status.id === status
    );
  }

  selectedPCVIsProductType(productType: PolicyContractType) {
    return (
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.contractType &&
      this.selectedPolicyContractVersion.contractType.id === productType
    );
  }

  get selectedPCVIsLastVersion(): boolean {
    return this.selectedPolicyContractVersion && this.selectedPolicyContractVersion.last;
  }

  selectedPCVIsLastPolicyYear() {
    return this.selectedPolicyContractVersion && this.selectedPolicyContractVersion.policyContract.last;
  }

  get changeStatePossible() {
    return this.changeState_flag;
  }

  private changeStateAvailable(): boolean {
    if (!this.selectedPolicyContractVersion) {
      return false;
    }
    if (
      this.appService.kuke &&
      this.inStatus(PolicyContractVersionStatus.TERMINATION_REQUEST) &&
      this.selectedPolicyContractVersion.terminationType &&
      this.selectedPolicyContractVersion.terminationType.id === PolicyTerminationType.RESIGNATION &&
      this.selectedPolicyContractVersion.withdrawalOnTime
    ) {
      // when RESIGNATION ON TIME => past version can be updated ((validFrom === validTo) > accrualDate)
      return true;
    }
    if (
      this.appService.kuke &&
      !this.selectedPCVInOneOfStatuses([
        PolicyContractVersionStatus.ACTIVATED,
        PolicyContractVersionStatus.TERMINATION_ACTIVATED,
      ])
    ) {
      return true;
    }
    if (
      this.appService.kuke &&
      (this.pastVersion() || this.inStatus(PolicyContractVersionStatus.TERMINATION_RESIGNATION))
    ) {
      return false;
    }
    // CREDENDO, KUKE
    if (this.lastVersionRejected()) {
      return this.selectedPolicyContractVersion.lastActivated;
    } else {
      return (
        this.selectedPCVIsLastVersion &&
        (!this.appService.kuke ||
          !this.selectedPCVInOneOfStatuses([
            PolicyContractVersionStatus.ACTIVATED,
            PolicyContractVersionStatus.ACTIVATED_LOCKED,
            PolicyContractVersionStatus.TERMINATION_ACTIVATED,
          ]))
      );
    }
  }

  updateButtons() {
    // CREDENDO
    const newVersionHidden =
      !(this.appService.credendo || this.appService.ecg) ||
      !this.selectedPolicyContractVersion.id ||
      !this.selectedPolicyContractVersion.lastActivated ||
      this.selectedPolicyContractVersion.status.id === PolicyContractVersionStatus.ACTIVATED_LOCKED ||
      (!this.selectedPolicyContractVersion.last && this.lastVersionCanBeActivated());

    const newYearHidden =
      !this.selectedPolicyContractVersion.id ||
      !(
        (this.appService.credendo || this.appService.ecg) &&
        this.selectedPCVIsLastPolicyYear() &&
        ((this.selectedPolicyContractVersion.lastActivated && !this.lastVersionCanBeActivated()) ||
          this.selectedVersionIsOnlyAndCanBeActivated())
      ) ||
      this.isCreatingNewPolicyYear ||
      this.selectedPolicyContractVersion.status.id === PolicyContractVersionStatus.ACTIVATED_LOCKED;

    const terminatedStatus = this.inStatusTerminated;
    this.dropButtons.newPolicyVersionButton.disabled = newVersionHidden;
    this.dropButtons.newPolicyReplaceVersion.disabled = newVersionHidden;
    this.dropButtons.newPolicyClosingVersion.disabled = newVersionHidden || terminatedStatus;
    this.dropButtons.newPolicyVersionButton.hidden = newVersionHidden;
    this.dropButtons.newPolicyClosingVersion.hidden = newVersionHidden || terminatedStatus;
    this.dropButtons.newPolicyReplaceVersion.hidden = newVersionHidden;

    this.dropButtons.newPolicyYearButton.disabled = newYearHidden;
    this.dropButtons.newPolicyYearButton.hidden = newYearHidden;
    this.newRenewalInquiryHidden = newYearHidden;

    // KUKE && CREDENDO
    this.credendoAnyNewActionPossible_flag = !(newVersionHidden && newYearHidden);
    this.changeState_flag = this.changeStateAvailable();

    // KUKE
    this.kukeAnnexInquiryAvailable_flag = this.kukeAnnexInquiryAvailable();
    this.calculateIsKukePolicyRenewable();
    this.kukeNewVersion_flag = this.kukeNewVersionAvailable();
    this.kukeUnifiedText_flag = this.policyContractViewService.kukeUnifiedTextAvailable(
      this.selectedPolicyContractVersion
    );

    // KUKE && CREDENDO
    this.calculateCanBeTerminated();
    this.resignationBeforeStart_flag = this.canResignBeforeStart();

    this.handleButtonsWithParam(this.readonly);
  }

  private lastVersionCanBeActivated() {
    if (!this.selectedPolicyContractVersion || !this.selectedPolicyContractVersion.policyContract) {
      return false;
    }
    const lvs = this.selectedPolicyContractVersion.policyContract.lastVersionStatus;
    return (
      lvs &&
      [
        PolicyContractVersionStatus.REQUEST,
        PolicyContractVersionStatus.REQUEST_ACCEPTED,
        PolicyContractVersionStatus.ISSUED,
        PolicyContractVersionStatus.PROLONGATION_REQUEST,
        PolicyContractVersionStatus.PROLONGATION_REQUEST_EXTRA_RIGHTS,
        PolicyContractVersionStatus.PROLONGATION_REQUEST_ACCEPTED,
        PolicyContractVersionStatus.TERMINATION_REQUEST,
        PolicyContractVersionStatus.TERMINATION_REQUEST_ACCEPTED,
      ].includes(lvs.id)
    );
  }

  private lastVersionRejected() {
    if (!this.selectedPolicyContractVersion || !this.selectedPolicyContractVersion.policyContract) {
      return false;
    }
    const lvs = this.selectedPolicyContractVersion.policyContract.lastVersionStatus;
    return (
      lvs &&
      [
        PolicyContractVersionStatus.REQUEST_REJECTED,
        PolicyContractVersionStatus.PROLONGATION_REQUEST_REJECTED,
        PolicyContractVersionStatus.USER_ERROR,
        PolicyContractVersionStatus.TERMINATION_REJECTED,
        PolicyContractVersionStatus.VERSION_REPLACED,
      ].includes(lvs.id)
    );
  }

  private selectedVersionIsOnlyAndCanBeActivated() {
    const svs = this.selectedPolicyContractVersion.status;
    // (first && last) => one and only
    return (
      this.selectedPolicyContractVersion.last && // last
      this.selectedPolicyContractVersion.versionNumber === 1 && // first
      svs &&
      [
        PolicyContractVersionStatus.REQUEST, // proper status
        PolicyContractVersionStatus.REQUEST_ACCEPTED,
        PolicyContractVersionStatus.ISSUED,
        PolicyContractVersionStatus.PROLONGATION_REQUEST,
        PolicyContractVersionStatus.PROLONGATION_REQUEST_EXTRA_RIGHTS,
        PolicyContractVersionStatus.PROLONGATION_REQUEST_ACCEPTED,
        // no PolicyContractVersionStatus.TERMINATION_REQUEST
        // => version with this status have to have predecesor
      ].includes(svs.id)
    );
  }

  // GUI podpięte pod buttton new version
  //
  // ustawienie nowego template
  // odpalenie afterNewAnnex
  onCreateNewVersion() {
    this.versionListReady = false;
    this.inProgress = true;
    this.policyContractService
      .getPolicyContractAnnexTemplate(this.selectedPolicyContractVersion.policyContract.id)
      .subscribe(
        (policyContractVersion) => {
          this._presentationMode = false;
          this.setPolicyContractVersion(policyContractVersion);
        },
        (error) => {
          this.handleServerError(error);
        },
        () => this.afterNewAnnex()
      );
  }

  // GUI podpięte pod buttton new version
  onCreateNewClosingVersion() {
    this.termination();
  }

  // GUI podpięte pod buttton new version
  onCreateNewReplaceVersion() {
    console.log('Replace policy contract: ' + this.selectedPolicyContractVersion.policyContract.id);
    this.versionListReady = false;
    this.inProgress = true;
    this.policyContractService
      .getPolicyContractReplaceAnnexTemplate(this.selectedPolicyContractVersion.policyContract.id)
      .subscribe(
        (policyContractVersion) => {
          this.setPolicyContractVersion(policyContractVersion);
        },
        (error) => {
          this.handleServerError(error);
        },
        () => this.afterNewAnnex()
      );
  }

  // GUI podpięte pod new policy year
  //
  // ustawienie nowego template
  // odpalenie afterNewPolicyYear
  onCreateNewPolicyYear() {
    this.versionListReady = false;
    this.isCreatingNewPolicyYear = true;
    this.inProgress = true;
    this.policyContractService.getNewPolicyYearTemplate(this.selectedPolicyContractVersion.policyContract.id).subscribe(
      (policyContractVersion) => {
        this.setPolicyContractVersion(policyContractVersion);
      },
      (error) => {
        this.handleServerError(error);
        this.isCreatingNewPolicyYear = false;
      },
      () => {
        this.inProgress = false;
        this.afterNewPolicyYear();
      }
    );
  }

  // do inquiry details z createRenewalInquiry
  createNewRenewalInquiry() {
    this.inProgress = true;
    this.inquiryService.createRenewalInquiry(this.selectedPolicyContractVersion.id).subscribe(
      (inquiryVersionId) => this.routerService.toPolicyInquiryDetails(inquiryVersionId),
      (error) => this.handleServerError(error),
      () => (this.inProgress = false)
    );
  }

  createNewAnnexInquiry() {
    this.inProgress = true;
    this.inquiryService.createAnnexInquiry(this.selectedPolicyContractVersion.id).subscribe(
      (inquiryVersionId) => this.routerService.toPolicyAnnexInquiryDetails(inquiryVersionId),
      (error) => this.handleServerError(error),
      () => (this.inProgress = false)
    );
  }

  afterPolicyContractSaved(policyContract: PolicyContractDto) {
    console.log('##### contract version saved id = ' + policyContract.id + 'contract = ', policyContract);
    this.showSavedMsg();
    this.routerService.toPolicyContractDetails(this.selectedPolicyContractVersion.id);
  }

  override afterExclusiveStateTransition(object: PolicyContractVersionDto) {
    this.showSavedMsg();
    this.setPolicyContractVersion(object);
  }

  afterNewAnnex() {
    this.setPolicyContractVersion(this.selectedPolicyContractVersion);
    this.setPolicyContract(<PolicyContractDto>this.selectedPolicyContractVersion.policyContract, false);

    this.isCreatingNewPolicyYear = false;

    this.readonly = false;
  }

  afterNewPolicyYear() {
    this.setPolicyContractVersion(this.selectedPolicyContractVersion);
    this.setPolicyContract(<PolicyContractDto>this.selectedPolicyContractVersion.policyContract, false);
    this.isCreatingNewPolicyYear = true;
    this.readonly = false;
  }

  lifecycleSave() {
    if (this.appService.kuke) {
      this.documentReceivedAndSignatureDateRequired = !(
        this.selectedPolicyContractVersion.versionNumber > 1 &&
        this.selectedPolicyContractVersion.status.id === PolicyContractVersionStatus.REQUEST_REJECTED &&
        this.previousStatus.id === PolicyContractVersionStatus.ISSUED
      );
    }
    if (this.appService.kuke && this.cancellationWithTerminationType()) {
      this.openRejectPolicyRequestDialog();
    } else {
      this.onSave();
    }
  }

  onSave(): void {
    this.policyContractViewService.reportingPeriodsEditable = false;
    // this.isSaving = true;
    if (this.appService.kuke) {
      this._presentationMode = true;
    }
    this.inProgress = true;
    this.showErrors = false;
    if (!this.form.valid) {
      this.showErrors = true;
      this.selectedPolicyContractVersion.status = this.previousStatus;
      this.showFormError();
      Object.keys(this.form.controls).map((key) => {
        if (this.form.controls[key].invalid) {
          console.warn('Invalid control key: ', key);
          console.warn('Invalid control errors: ', this.form.controls[key].errors);
          console.warn('Invalid control value: ', this.form.controls[key].value);
        }
      });
      return;
    }
    this.serverErrors = null;
    console.log('Save: id', this.selectedPolicyContractVersion.id, this.selectedPolicyContractVersion);
    this.updateModel();

    this.policyContractVersionService.savePolicyContractVersion(this.selectedPolicyContractVersion).subscribe({
      next: (contractVersion) => this.setPolicyContractVersion(contractVersion),
      error: (error) => this.handleServerError(error),
      complete: () => {
        this.policyContractService
          .getPolicyContract(this.selectedPolicyContractVersion.policyContract.id)
          .subscribe((policyContract) => {
            this.setPolicyContract(policyContract, false);
            this.afterPolicyContractSaved(policyContract);
            this.isCreatingNewPolicyYear = false;
            this._presentationMode = true;
            this.readOnlyMode = true;
            this.updateButtons();
          });
      },
    });
  }

  showSavedMsg() {
    this.growlService.notice('Policy is saved.');
    this.inProgress = false;
    this.updateButtons();
  }

  showFormError() {
    this.growlService.error('The form has errors!');
    this.inProgress = false;
    this.updateButtons();
  }

  onCancel(): void {
    this.policyContractViewService.reportingPeriodsEditable = false;
    this.cd.detach();
    super.onCancel();
    this.isCreatingNewPolicyYear = false;
    if (this.appService.kuke) {
      this._presentationMode = true;
    }
    this.updateButtons();
    this.policyContractTypeSet = +this.route.snapshot.params['id'] !== 0;
    if (this.selectedPolicyContractVersion.id && this.selectedPolicyContractVersion.id > 0) {
      this.policyContractVersionService
        .getPolicyContractVersion(this.selectedPolicyContractVersion.id)
        .subscribe((policyContractVersion) => this.setPolicyContractVersion(policyContractVersion));
    } else {
      this.policyContractTypeSet = false;
      this.initializeContract(
        this.route.snapshot.params['id'],
        this.route.snapshot.params['clientId'],
        this.route.snapshot.params['offerVersionId']
      );
    }
  }

  onSelectPolicyContractVersion(policyContractVersion: PolicyContractVersionDto) {
    this.policyContractVersionService.getPolicyContractVersion(policyContractVersion.id).subscribe((pcv) => {
      this.setPolicyContractVersion(pcv);
      this.routerService.toPolicyContractDetails(this.selectedPolicyContractVersion.id);
    });
  }

  onSelectPolicyContract(policyContract: PolicyContractBaseDto, selectLastPolicyContractVersion?: boolean) {
    console.log('onSelectPolicyContract :: id = ' + policyContract.id);
    this.policyContractService.getPolicyContract(policyContract.id).subscribe((pc) => {
      console.log('onSelectPolicyContract ::', pc);
      this.setPolicyContract(pc, true);
      this.isCreatingNewPolicyYear = false;
      if (selectLastPolicyContractVersion) {
        this.onSelectPolicyContractVersion(pc.versions[pc.versions.length - 1]);
      }
    });
  }

  // uruchamiane z GUI
  // PK change client search result lists
  onSelectClient(client: CompanySimpleDto) {
    this.selectedPolicyContractVersion.client = client;
    delete this.selectedPolicyContractVersion.guarantors;
    if (
      this.policyContractTypeSet &&
      client !== undefined &&
      !(this.selectedPolicyContractVersion.id && this.selectedPolicyContractVersion.id > 0)
    ) {
      this.getInitialContractVersion(this.selectedPolicyContractVersion.contractType.id);
    }
  }

  fixedBlockHidden() {
    return !(this.policyContractTypeSet && this.selectedPolicyContractVersion.client);
  }

  onPrintSelected(template: TemplateSimpleDto) {
    this.routerService.toDocumentDetailsNew(template.id, this.selectedPolicyContractVersion.id);
  }

  // onIssueDocuments() {
  //   this.inProgress = true;
  //   this.policyContractVersionService.issueDocuments(this.selectedPolicyContractVersion).subscribe(
  //     (contractVersion: PolicyContractVersionDto) => this.setPolicyContractVersion(contractVersion),
  //     (error) => this.handleServerError(error),
  //     () => (this.inProgress = false)
  //   );
  // }
  onGeneratedDocumentsPack(pcvId: number) {
    console.log('START onGeneratedDocumentsPack');
    this.routerService.toDocumentPackSearch(BusinessObjectType.POLICY_VERSION, pcvId);
  }

  showGeneratedDocuments(): boolean {
    // const pcv = this.selectedPolicyContractVersion;
    // if (pcv && pcv.status) {
    //   return pcv.status.id === PolicyContractVersionStatus.CONTRACT_ISSUED;
    // }
    return true;
  }

  // uruchamiane z GUI
  // zmiana wersji
  onChangePolicyVersion(policyContractVersion: PolicyContractVersionDto) {
    this.onSelectPolicyContractVersion(policyContractVersion);
  }

  // uruchamiane z GUI i setPolicyYear
  // zmiana roku ?
  onChangePolicyYear(policyContract: PolicyContractBaseDto) {
    this.onSelectPolicyContract(policyContract, true);
  }

  setPolicyYear(year: number) {
    if (+year <= this.masterPolicyContract.policyContracts.length) {
      this.onChangePolicyYear(this.masterPolicyContract.policyContracts.find((item) => item.policyYear === +year));
    }
  }

  private loadWordingTemplates() {
    this.templateService.getWordingTemplates(this.selectedPolicyContractVersion).subscribe((result) => {
      this.wordingTemplates = result.reduce((acc, v) => acc.concat(v, [])).sort((a, b) => (a.id < b.id ? 1 : -1));
    });
  }

  isSectionVisible(viewSection: PolicyContractViewSection, defaultVisibility = false) {
    const pcv = this.selectedPolicyContractVersion;
    return (
      pcv &&
      pcv.contractType &&
      pcv.contractType.id &&
      this.policyContractViewService.isSectionVisible(pcv.contractType.id, viewSection, defaultVisibility)
    );
  }

  isRequired(viewSection: PolicyContractViewSection, defaultVisibility = false) {
    return this.policyContractViewService.isSectionVisible(
      this.selectedPolicyContractVersion.contractType.id,
      viewSection,
      defaultVisibility
    );
  }

  public policyTypeSwitch(): number {
    if (this.selectedPolicyContractVersion && this.selectedPolicyContractVersion.contractType) {
      return this.selectedPolicyContractVersion.contractType.id;
    }
    return 0;
  }

  get credendoAnyNewActionPossible() {
    return this.credendoAnyNewActionPossible_flag;
  }

  createUnlockTaskButtonVisible() {
    return (
      this.appService.credendo &&
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.status &&
      this.selectedPolicyContractVersion.status.id === PolicyContractVersionStatus.ACTIVATED_LOCKED
    );
  }

  createLockTaskButtonVisible() {
    return (
      (this.credendo && this.selectedPolicyContractVersion?.status?.id === PolicyContractVersionStatus.ACTIVATED) ||
      (this.ecg &&
        [PolicyContractVersionStatus.ACTIVATED, PolicyContractVersionStatus.BS_ACTIVE].includes(
          this.selectedPolicyContractVersion?.status?.id
        ))
    );
  }

  declarationButtonVisible() {
    const now = new Date();
    return (
      this.appService.kuke &&
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.policyContract &&
      this.selectedPolicyContractVersion.policyContract.id &&
      PolicyContractViewService.policyAtLeastActivated(this.selectedPolicyContractVersion.businessStatus) &&
      this.isProductDeclarable() &&
      moment(this.selectedPolicyContractVersion.policyContract.validFrom).isBefore(now)
    );
  }

  private isProductDeclarable(): boolean {
    const typeId =
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.contractType &&
      this.selectedPolicyContractVersion.contractType.id;
    return (
      !!typeId &&
      [
        PolicyContractType.KUKE_KOM_OP,
        PolicyContractType.KUKE_KOM_FE,
        PolicyContractType.KUKE_KOM_FK,
        PolicyContractType.KUKE_KOM_SP,
        PolicyContractType.KUKE_GSP_PBG,
      ].includes(typeId) &&
      !this.selectedPCVInOneOfStatuses([
        PolicyContractVersionStatus.REQUEST_REJECTED,
        PolicyContractVersionStatus.PROLONGATION_REQUEST_REJECTED,
        PolicyContractVersionStatus.TERMINATION_REJECTED,
        PolicyContractVersionStatus.TERMINATION_RESIGNATION,
        PolicyContractVersionStatus.USER_ERROR,
        PolicyContractVersionStatus.VERSION_REPLACED,
      ])
    );
  }

  outstandings(): boolean {
    return (
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.policyContract &&
      this.selectedPolicyContractVersion.policyContract.masterPolicyContract.productType &&
      this.selectedPolicyContractVersion.policyContract.masterPolicyContract.productType.id ===
        PolicyContractType.KUKE_KOM_SP
    );
  }

  createUnlockTask() {
    this.createTask(
      TaskType.POLICY_UNLOCK,
      'policy.details.unlockTask.creationNoticeSuccess',
      'policy.details.unlockTask.creationNoticeError'
    );
  }

  createLockTask() {
    this.createTask(
      TaskType.POLICY_LOCK,
      'policy.details.lockTask.creationNoticeSuccess',
      'policy.details.lockTask.creationNoticeError'
    );
  }

  private createTask(taskType: TaskType, msgSuccess: string, msgError: string) {
    if (this.appService.credendo || this.appService.ecg) {
      this.policyContractService.createTask(this.selectedPolicyContractVersion.policyContract.id, taskType).subscribe({
        next: (task) => {
          this.growlService.notice(msgSuccess, null, {description: task.description});
        },
        error: () => {
          this.growlService.error(msgError);
        },
      });
    }
  }

  private updateModel() {
    this.selectedPolicyContractVersion.reinsuranceMode = this.form.get('reinsuranceMode')
      ? this.form.get('reinsuranceMode').value
      : undefined;
    this.selectedPolicyContractVersion.frontingTreaty = this.form.get('frontingTreaty')
      ? this.form.get('frontingTreaty').value
      : undefined;
    if (
      !this.selectedPolicyContractVersion.premiumInstalments &&
      this.selectedPolicyContractVersion.premiumInstalementCalendar
    ) {
      this.selectedPolicyContractVersion.premiumInstalementCalendar = undefined;
    }
  }

  get resignationBeforeStartPossible() {
    return this.resignationBeforeStart_flag;
  }

  // lastActivatedVersion
  // first and lastPC => one and only
  // status ACTIVATED
  private canResignBeforeStart() {
    const now = new Date();
    return (
      this.appService.kuke &&
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.id > 0 &&
      this.selectedPolicyContractVersion.lastActivated && // last activated PCV
      this.selectedPolicyContractVersion.versionNumber === 1 && // first version PCV
      this.inStatus(PolicyContractVersionStatus.ACTIVATED) && // activated
      this.selectedPolicyContractVersion.policyContract.last && // last PC
      this.selectedPolicyContractVersion.policyContract.policyYear === 1 && // first PC
      moment(this.selectedPolicyContractVersion.validFrom).isAfter(now) && // future version
      !this.lastVersionCanBeActivated() && // do not have future versions witch can be activated
      !(
        this.selectedPolicyContractVersion.terminationType && // if it is already resignation it can not be resigned once again
        this.selectedPolicyContractVersion.terminationType.id === PolicyTerminationType.RESIGNATION &&
        this.selectedPolicyContractVersion.withdrawalOnTime === false
      )
    );
  }

  get terminationPossible() {
    return this.termination_flag;
  }

  private calculateCanBeTerminated() {
    if (this.appService.kuke && this.selectedPolicyContractVersion && this.selectedPolicyContractVersion.id) {
      this.policyContractVersionService.canBeTerminated(this.selectedPolicyContractVersion.id).subscribe(
        (canBeTerminated) => (this.termination_flag = canBeTerminated),
        () => (this.termination_flag = false)
      );
    } else {
      this.termination_flag = false;
    }
  }

  private cancellationWithTerminationType() {
    return (
      this.selectedPolicyContractVersion.versionNumber === 1 &&
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.id > 0 &&
      this.selectedPolicyContractVersion.status &&
      this.previousStatus &&
      this.previousStatus.id === PolicyContractVersionStatus.ISSUED &&
      this.selectedPolicyContractVersion.status.id === PolicyContractVersionStatus.REQUEST_REJECTED
    );
  }

  toPolicyServiceContacts() {
    this.routerService.toServiceContacts({
      boTypeId: BusinessObjectType.MASTER_POLICY,
      boId: this.selectedPolicyContractVersion.policyContract.masterPolicyContract.id,
      returnBoTypeId: BusinessObjectType.POLICY_VERSION,
      returnBoId: this.selectedPolicyContractVersion.id,
    });
  }

  // kuke && pcv.last && bs = (activated || active) && !(EU || EP) && have inquiry
  get kukeAnnexInquiryPossible() {
    return this.kukeAnnexInquiryAvailable_flag;
  }

  // kuke && pcv.last && bs = (activated || active) && !(EU || EP) && have inquiry
  private kukeAnnexInquiryAvailable(): boolean {
    return this.notEPorEUType && this.kukeNewVersionOrAnnex();
  }

  get notEPorEUType(): boolean {
    return (
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.contractType &&
      !(this.selectedPolicyContractVersion.contractType.id === PolicyContractType.KUKE_KOM_EP)
    );
  }

  resignationBeforeStart(): void {
    this.openPolicyResignationDialog();
  }

  termination(): void {
    if (!this.selectedPolicyContractVersion || !this.selectedPolicyContractVersion.id) {
      return;
    }
    // this.serverErrors = null;
    this.inProgress = true;
    // this.readonly = false;on
    this.policyContractVersionService.termination(this.selectedPolicyContractVersion.policyContract.id).subscribe(
      (newPCV) => {
        this.setPolicyContractVersion(newPCV);
      },
      (error) => this.handleServerError(error),
      () => this.afterNewAnnex()
    );
  }

  versionEditable(): boolean {
    const changeStateAvailableOrNewVersion = this.changeStateAvailable() || !this.selectedPolicyContractVersion.id;
    if (this.appService.kuke || this.appService.ecg) {
      return changeStateAvailableOrNewVersion || this.isProductDeclarable();
    } else if (this.appService.credendo) {
      return changeStateAvailableOrNewVersion && !this.inStatus(PolicyContractVersionStatus.ACTIVATED_LOCKED);
    }
    return false;
  }

  onEdit() {
    super.onEdit();
    this._presentationMode = false;
    this.serverErrors = null;
    // this.readonly = false
    this.policyContractViewService.reportingPeriodsEditable = true;

    // for kuke activated version you can press edit
    // but then only edition of reporting periods is possible (without edition of nay other policy data!)
    this.readonly =
      (this.appService.kuke &&
        this.selectedPCVInOneOfStatuses([
          PolicyContractVersionStatus.ACTIVATED,
          PolicyContractVersionStatus.TERMINATION_ACTIVATED,
        ])) ||
      this.inStatus(PolicyContractVersionStatus.ACTIVATED_LOCKED); // CR;
  }

  get kukeRenewalPossible() {
    return this.kukeRenewal_flag;
  }

  private calculateIsKukePolicyRenewable() {
    if (this.appService.kuke && this.selectedPolicyContractVersion && this.selectedPolicyContractVersion.id) {
      this.policyContractVersionService.isRenewable(this.selectedPolicyContractVersion.id).subscribe(
        (renewable) => (this.kukeRenewal_flag = renewable),
        () => (this.kukeRenewal_flag = false)
      );
    } else {
      this.kukeRenewal_flag = false;
    }
  }

  get kukeNewVersionPossible() {
    return this.kukeNewVersion_flag;
  }

  private kukeNewVersionOrAnnex(): boolean {
    return (
      this.appService.kuke &&
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.id &&
      this.isNotPastContractLastAndActivatedOrLastActivated
    );
  }

  private kukeNewVersionAvailable(): boolean {
    return this.kukeNewVersionOrAnnex() && !this.inStatus(PolicyContractVersionStatus.TERMINATION_ACTIVATED);
  }

  get isNotPastContractLastAndActivatedOrLastActivated(): boolean {
    if (this.selectedPolicyContractVersion && this.selectedPolicyContractVersion.status) {
      if (this.pastVersion()) {
        return false;
      }
      return (
        this.selectedPolicyContractVersion.lastActivated &&
        (this.selectedPolicyContractVersion.last || this.lastVersionRejected())
      );
    } else {
      return false;
    }
  }

  get inquiryName(): String {
    if (this.selectedPolicyContractVersion.inquiry.policyInquiry.inquiryType === 'POLICY_ANNEX') {
      return this.translateService.instant('inquiry.annexName');
    } else if (this.selectedPolicyContractVersion.inquiry.policyInquiry.inquiryType === 'RENEWAL_INQUIRY') {
      return this.translateService.instant('inquiry.renewalName');
    }
    return this.translateService.instant('inquiry.name');
  }

  onRenewalWithoutChanges() {
    this.onCreateNewPolicyYear();
  }

  onRenewalInquiry() {
    console.log('Creating renewal inquiry for: ' + this.selectedPolicyContractVersion.policyContract.id);
    this.inProgress = true;
    this.inquiryService.createRenewalInquiry(this.selectedPolicyContractVersion.id).subscribe(
      (inquiryVersionId) => this.routerService.toPolicyRenewalInquiryDetails(inquiryVersionId),
      (error) => this.handleServerError(error),
      () => (this.inProgress = false)
    );
  }

  onRenewalOffer() {
    console.log('Creating renewal offer for: ' + this.selectedPolicyContractVersion.policyContract.id);
    this.inProgress = true;
    this.inquiryService.createRenewalOffer(this.selectedPolicyContractVersion.id).subscribe({
      next: (inquiryVersion) =>
        this.routerService.toNewKukeInquiryOfferDetails(inquiryVersion.id, inquiryVersion.productType.id, true, null),
      error: (error) => this.handleServerError(error),
      complete: () => (this.inProgress = false),
    });
  }

  saveEvent() {
    this.onSave();
  }

  private pastVersion(): boolean {
    const now = new Date();
    return (
      this.selectedPolicyContractVersion &&
      !moment(this.selectedPolicyContractVersion.validTo).add(1, 'day').isAfter(now)
    );
  }

  get inStatusIssued() {
    return this.inStatus(PolicyContractVersionStatus.ISSUED);
  }

  get inStatusRequestAccepted() {
    return this.inStatus(PolicyContractVersionStatus.REQUEST_ACCEPTED);
  }

  get inStatusRejected() {
    return this.selectedPCVInOneOfStatuses([
      PolicyContractVersionStatus.REQUEST_REJECTED,
      PolicyContractVersionStatus.TERMINATION_REJECTED,
    ]);
  }

  get inStatusTerminated() {
    return this.selectedPCVInOneOfStatuses(POLICY_CONTRACT_REQUEST_OR_TERMINATION_REQUEST_STATUSES);
  }

  private inStatus(statusId: PolicyContractVersionStatus): boolean {
    return (
      this.selectedPolicyContractVersion &&
      this.selectedPolicyContractVersion.status &&
      this.selectedPolicyContractVersion.status.id === statusId
    );
  }

  private selectedPCVInOneOfStatuses(statuses: PolicyContractVersionStatus[]): boolean {
    return <boolean>statuses.reduce((prev, curr) => prev || this.selectedPCVInStatus(curr), undefined);
  }

  get rejectTypeRegexp(): RegExp {
    const pbg = this.selectedPCVIsProductType(PolicyContractType.KUKE_GSP_PBG);
    const notFirstVersion =
      this.selectedPolicyContractVersion && this.selectedPolicyContractVersion.versionNumber !== 1;
    if (pbg || notFirstVersion) {
      return /^(?:CANCELLATION_DOCUMENT)$/;
    } else {
      return /^(?:CANCELLATION_PAYMENT|CANCELLATION_DOCUMENT)$/;
    }
  }

  openRejectPolicyRequestDialog() {
    this.rejectPolicyRequestDialog.openAndExecuteOnConfirm(
      () => {
        if (!this.ngForm.invalid) {
          this.selectedPolicyContractVersion.status.id =
            this.selectedPolicyContractVersion.policyContract.policyYear === 1
              ? PolicyContractVersionStatus.REQUEST_REJECTED
              : PolicyContractVersionStatus.PROLONGATION_REQUEST_REJECTED;
          this.onSave();
        } else {
          this.showErrors = true;
          this.selectedPolicyContractVersion.status = this.previousStatus;
          this.showFormError();
          return;
        }
      },
      () => this.clearTerminationData()
    );
  }

  openPolicyResignationDialog() {
    if (!this.selectedPolicyContractVersion || !this.selectedPolicyContractVersion.id) {
      return;
    }
    this.dictionaryBaseService
      .getDictionaryEntry('PolicyTerminationType', PolicyTerminationType.RESIGNATION)
      .subscribe((res) => {
        this.selectedPolicyContractVersion.terminationType = res;
        this.policyResignationDialog.openAndExecuteOnConfirm(
          () => {
            this.inProgress = true;
            this.policyContractVersionService.resignationBeforeStart(this.selectedPolicyContractVersion).subscribe(
              (updatedPcv) => this.setPolicyContractVersion(updatedPcv),
              (error) => this.handleServerError(error),
              () => {
                this.policyContractService
                  .getPolicyContract(this.selectedPolicyContractVersion.policyContract.id)
                  .subscribe((policyContract) => {
                    this.setPolicyContract(policyContract, false);
                    this.afterPolicyContractSaved(policyContract);
                    this.isCreatingNewPolicyYear = false;
                    this._presentationMode = true;
                    this.updateButtons();
                  });
              }
            );
          },
          () => this.clearTerminationData()
        );
      });
  }

  clearTerminationData() {
    this.selectedPolicyContractVersion.terminationComment = undefined;
    if (this.selectedPolicyContractVersion.terminationType) {
      this.selectedPolicyContractVersion.terminationType.id = undefined;
      this.selectedPolicyContractVersion.terminationType = undefined;
    }
    this.selectedPolicyContractVersion.withdrawalOnTime = undefined;
  }

  onUnifiedText(item) {
    if (!item) {
      return;
    }
    item.action();
  }

  private unifiedTextGenerate() {
    const wt = this.wordingTemplates;
    if (!wt || !wt.length) {
      this.growlService.warning('No templates!');
      return;
    }
    if (wt.length > 1) {
      console.log('More then one template. Selected first one.');
    }

    const template = wt[0];
    const oid = this.selectedPolicyContractVersion.id;
    this.documentService.generatePdf(template.id, oid, template.name + '.' + oid + '.pdf');
  }

  private unifiedTextSend() {
    const wt = this.wordingTemplates;
    if (!wt || !wt.length) {
      this.growlService.warning('No templates!');
      return;
    }
    if (wt.length > 1) {
      console.log('More then one template. Selected first one.');
    }

    const template = wt.filter((t) =>
      DictionaryUtils.in(t.type, DocumentType.POLICY_ANNEX, DocumentType.KUKE_POLICY)
    )[0];
    const businessObjectId = this.selectedPolicyContractVersion.id;

    this.policyContractVersionService.sendUnifiedText(template.id, businessObjectId).subscribe({
      next: () => this.growlService.notice('Email has been sent!'),
      error: (error) => (this.serverErrors = error),
    });
  }

  protected generateCoInsReport() {
    let params = new HttpParams();
    params = params.append('policycontractid', this.selectedPolicyContractVersion.policyContract.id + '');
    this.serverErrors = undefined;
    this.businessReportService.generate(103, 'Co-ins report.xlsx', params, (errorMsg) =>
      this.downloadErrorCallback(errorMsg)
    );
  }

  get credendo(): boolean {
    return this.appService.credendo;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }

  get ecg(): boolean {
    return this.appService.ecg;
  }
}
