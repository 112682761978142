import {AfterViewInit, Component, Input} from '@angular/core';
import {UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {ContactPersonDto, DictionaryBaseDto} from '../../model/dtos';
import {ContactPersonType, ContactPersonTypes} from '../../model/dictionary-ids';
import {AppConfigService, ContactPersonService, DictionaryBaseService, LoggedUserService} from '../../services';

@Component({
  selector: 'contact-person-list',
  templateUrl: './contact-person-list.component.pug',
})
export class ContactPersonListComponent extends ListEmitters implements AfterViewInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() showErrors: boolean;
  @Input() hidden = false;
  @Input() contactPersons: ContactPersonDto[];
  @Input() deleteButton = true;
  @Input() addButton = true;
  @Input() parentId: number;
  @Input() businessObjectTypeId: number;
  @Input() editable = true;
  @Input() rowDeletionDisabled: boolean;
  @Input() rowDeletionDisabledHint: string;
  @Input() minLength = 0;
  @Input() setFirstMain = false;
  @Input() selection = true;
  @Input() formElementErrorClass = '';
  @Input() titleRequired = false;
  @Input() firstNameRequired = false;
  @Input() lastNameRequired = true;
  @Input() emailRequired = false;
  @Input() phoneRequired = false;
  @Input() typeColumn = true;
  @Input() typeEditable = true;
  @Input() hideMobile = false;
  @Input() hidePosition = false;
  @Input() hideContactFor = false;
  @Input() hideMainContact = false;
  @Input() hideType = false;
  @Input() showSalesforceId = false;
  @Input() groupName = 'contactPersonTable';
  @Input() firstNameCustomValidatorSupplier: () => ValidatorFn;
  @Input() lastNameCustomValidatorSupplier: () => ValidatorFn;
  form: UntypedFormGroup;
  typeMain: DictionaryBaseDto;
  hiddenTypes: Set<number>;
  @Input() rowEditable: (item: ContactPersonDto) => boolean;

  constructor(
    private dictBaseService: DictionaryBaseService,
    private appService: AppConfigService,
    protected contactPersonService: ContactPersonService,
    private loggedUserService: LoggedUserService
  ) {
    super();
    if (this.loggedUserService.portal && this.kuke) {
      this.hiddenTypes = ContactPersonTypes.HIDDEN_IN_PORTAL;
    }
    this.form = new UntypedFormGroup({});
    this.initializeSelectorEmittersByNames(true, ['Attachment']);
  }

  ngAfterViewInit() {
    if (this.parentForm) {
      this.parentForm.setControl(this.groupName, this.form);
    }
    this.dictBaseService
      .getDictionaryEntry('ContactPersonType', ContactPersonType.MAIN)
      .subscribe((typeMain) => (this.typeMain = typeMain));
  }

  setMainContactPerson(cp: ContactPersonDto) {
    if ((this.setFirstMain && this.contactPersons.length < 2) || !this.typeEditable) {
      cp.type = this.typeMain;
    }
  }

  updateContactPerson(cp: ContactPersonDto) {
    if (this.kuke) {
      return;
    }
    if (cp.main) {
      cp.type = this.typeMain;
    }
    const isMainType = (c: ContactPersonDto) => c && c.type && c.type.id === ContactPersonType.MAIN;
    if (this.contactPersons && isMainType(cp) && this.typeEditable) {
      this.contactPersons
        .filter((p) => p.id !== cp.id && isMainType(p))
        .forEach((p) => {
          p.type = undefined;
          p.main = false;
        });
    }
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }
}
