<form *ngIf="invoice" #ngForm="ngForm">
  <div class="bon-card-inner-group">
    <div class="bon-card-inner" style="width: 30%;">
      <div class="bon-row">
        <company-section *ngIf="invoice" labelKey="invoice.shared.data.client" [(ngModel)]="invoice.client" [clearable]="false"
           [openSelectorEmitter]="listEmitters.openSelectorEmitters['Client']" [showLink]="true" [ngModelOptions]="{standalone: true}"
           [readOnly]="false">
        </company-section>
      </div>
    </div>
    <div class="bon-card-inner" style="width: 30%;">
      <invoice-contract-link [contractLink]="invoice.contractLink"></invoice-contract-link>
    </div>
    <div class="bon-card-inner" style="width: 40%;">
      <div *ngIf="invoice.client">
        <bon-section labelKey="invoice.shared.data.clientData">
          <div class="bon-card-group">
            <div class="bon-card-inner">
              <form-row labelKey="invoice.shared.data.registrationName">
                <span>{{invoice.clientName}}</span>
              </form-row>
              <form-row labelKey="invoice.shared.data.address">
                <span *ngIf="invoice.address">{{invoice.address.street}} {{invoice.address.streetNumber}}, {{invoice.address.zipCode || ''}} {{invoice.address.town}}</span>
                <span *ngIf="invoice.address && invoice.address.country">, {{invoice.address.country.name}}</span>
              </form-row>
              <form-row labelKey="invoice.shared.data.vatNumber">
                <span>{{invoice.vatNumber}}</span>
              </form-row>
              <form-row labelKey="invoice.shared.data.nationalId">
                <span>{{invoice.nationalId}}</span>
              </form-row>
            </div>
          </div>
        </bon-section>
        <bon-section labelKey="invoice.shared.data.bankAccount">
          <div class="bon-card-group">
            <div class="bon-card-inner">
            <input-row [(ngModel)]="invoice.iban" labelKey="invoice.shared.data.iban" name="iban" [readonly]="editionBlocked()">
            </input-row>
            <input-row [(ngModel)]="invoice.bic" labelKey="invoice.shared.data.bic" name="bic" [readonly]="editionBlocked()">
            </input-row>
            <input-row [(ngModel)]="invoice.bankName" labelKey="invoice.shared.data.bankName" name="bankName" [readonly]="editionBlocked()">
            </input-row>
            </div>
          </div>
        </bon-section>
      </div>
    </div>
  </div>

  <div class="bon-card-group">
    <company-selector-simple (selectItem)="onCompanySelect($event)" labelKey="invoice.shared.data.companySearch"
                             [open]="listEmitters.openSelectorEmitters['Client']" [showDuplicates]="false" [searchModeSwitchable]="true">
    </company-selector-simple>
  </div>

  <div *ngIf="invoice.client">
    <bon-section *ngIf="!invoiceAutomatically || (invoiceAutomatically && invoiceAutomaticallyFinished)"
                 [title]= "'invoice.shared.data.notInvoicedItems' | cattranslate: category" [expandable]="!invoice.id" >
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <div class="bon-row">
            <label class="bon-label" translate>invoice.shared.data.addToPosition</label>
            <div class="bon-input-no-underline">
              <item-combo [(ngModel)]="chosenInvoicePositionSelect" name="chosenInvoicePositionSelect"
                          [items]="invoice.invoicePositions" [key]="'positionNumber'"
                          [label]="'positionNumber'" [nullLabel]="'invoice.shared.data.newPosition' | translate"></item-combo>
            </div>
          </div>
        </div>
        <div class="bon-card-inner">
          <boolean-combo-row *ngIf="categoryId === InvoiceCategory.BROKER" labelKey="invoice.shared.data.sourceInvoicePaid"
                             [(ngModel)]="sourceInvoicePaid" name="sourceInvoicePaid"
                             (changeItem) = "sourceInvoicePaidChanged($event)"></boolean-combo-row>
        </div>
        <div class="bon-card-inner">
        </div>
      </div>
      <div class="bon-card-group">
        <invoice-item-list [relatedToId]="invoice.client.id"
                           [categoryId]="categoryId"
                           [parentCategoryId]="categoryId"
                           [invoiceCriteria]="invoiceCriteria"
                           [invoiceItemsToBeRemovedFromResults]="invoiceItems"
                           [showSourceInvoice] = "categoryId === InvoiceCategory.BROKER"
                           [sourceInvoicePaid] = "sourceInvoicePaid"
                           (invoiceItemEmitter)="onAddInvoiceItem($event)"
                           (invoiceItemsEmitter)="invoiceAll($event)"></invoice-item-list>
      </div>
    </bon-section>

    <bon-section labelKey="invoice.shared.data.details">
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <form-row labelKey="invoice.shared.data.number">
            <span class="bon-input">{{invoice.number}}</span>
          </form-row>
          <input-row *ngIf="appService.ecg" labelKey="salesforce.number" [ngModel]="invoice.salesforceId" name="salesforceId" disabled></input-row>
          <form-row labelKey="invoice.shared.data.businessUnit">
            <business-unit-selector class="bon-input-size" [(ngModel)]="invoice.businessUnit"
                                    name="businessUnit" (changeItem)="onBusinessUnitChange($event)"
                                    [disabled]="editionBlocked(false) || correction"></business-unit-selector>
          </form-row>
          <form-row labelKey="invoice.shared.data.numberingBag">
            <span *ngIf="!numberingBagEditionBlocked" class="bon-input-no-underline">
              <invoice-numbering-bag-selector [businessUnit]="invoice.businessUnit" [categoryId]="categoryId"
                                              [(ngModel)]="invoice.numberingBag" (changeItem)="onInvoiceNumberingBagDtoChange($event)"
                                              [showErrors]="showErrors" [required]="true"
                                              name="invoiceNumberingBag"></invoice-numbering-bag-selector>
            </span>
            <span *ngIf="numberingBagEditionBlocked" class="bon-input">{{invoice.numberingBag?.name}}</span>
          </form-row>
          <form-row *ngIf="invoice.numberingBag?.documentType" labelKey="invoice.shared.data.documentType">
            <span class="bon-input">{{invoice.numberingBag?.documentType?.name}}</span>
          </form-row>
          <form-row labelKey="invoice.shared.data.type">
            <span class="bon-input">{{invoice.invoiceType?.name}}</span>
          </form-row>
          <form-row *ngIf="invoice.invoiceType.id === InvoiceType.CREDIT_NOTE" labelKey="invoice.shared.data.correctedInvoice">
            <span class="bon-input">
              <span *ngIf="invoice.correctedInvoice">
                <a class="a-link" (click)="router.toInvoiceDetails(categoryId, invoice.correctedInvoice.id)">{{invoice.correctedInvoice.number}}</a>
              </span>
            </span>
          </form-row>
          <form-row *ngIf="invoice.invoiceType.id === InvoiceType.CREDIT_NOTE" labelKey="invoice.shared.data.correctionNumber">
            <span class="bon-input">{{invoice.correctionNumber}}</span>
          </form-row>
          <form-row labelKey="invoice.shared.data.status">
            <span class="bon-input">{{invoice.businessStatus?.name}}</span>
          </form-row>
          <input-row *ngIf="!appService.credendo" labelKey="invoice.shared.data.referenceNumber" [(ngModel)]="invoice.referenceNumber" name="referenceNumber"
                     [readonly]="editionBlocked()"></input-row>
          <input-row *ngIf="appService.credendo" labelKey="invoice.shared.data.additionalText" [(ngModel)]="invoice.referenceNumber" name="additionalText"></input-row>
          <form-row *ngIf="categoryId !== InvoiceCategory.BROKER" labelKey="invoice.shared.data.invoiceItemType">
            <span class="bon-input">{{invoiceItemTypesStr}}</span>
          </form-row>
          <form-row *ngIf="categoryId !== InvoiceCategory.BROKER && showSubtype" labelKey="invoice.shared.data.invoiceItemSubtype">
            <span class="bon-input">{{invoice.subtype?.name}}</span>
          </form-row>
          <form-row *ngIf="installmentsStr && categoryId !== InvoiceCategory.BROKER && showInstallments" labelKey="invoice.shared.data.installmentsStr">
            <span class="bon-input">{{installmentsStr}}</span>
          </form-row>
        </div>
        <div class="bon-card-inner">
          <form-row *ngIf="correctionReasonVisible && appProperties[AppProperty.INVOICE_CORRECTION_REASON]" labelKey="invoice.shared.data.correctionReason">
            <item-combo class="bon-input-size" [items]="correctionReasons" [(ngModel)]="invoice.correctionReason"
                        label="name" name="correctionReason" nullLabel="-"></item-combo>
          </form-row>
          <textarea-row *ngIf="appProperties[AppProperty.INVOICE_COMMENT]"
                        [labelKey]="correctionReasonVisible && !appProperties[AppProperty.INVOICE_CORRECTION_REASON] ? 'invoice.shared.data.correctionReason' : 'Comment'"
                        [(ngModel)]="invoice.comment" maxlength="255" [showErrors]="showErrors" name="comment"></textarea-row>

          <form-row labelKey="invoice.shared.data.netAmount">
            <span class="bon-input" style="text-align: right">{{invoice.netAmount | number}}</span>
          </form-row>
          <form-row labelKey="invoice.shared.data.grossAmount">
            <span class="bon-input" style="text-align: right">{{invoice.grossAmount | number}}</span>
          </form-row>
          <dict-row labelKey="invoice.shared.data.currency" [(ngModel)]="invoice.currency"
                    dictionary="Currency" name="currency" itemLabel="code" [disabled]="true">
          </dict-row>
          <form-row labelKey="invoice.shared.data.amounts">
            <a-table [items]="invoice.invoiceAmounts"
                     [formModel]="ngForm.form" groupName="invoiceAmountsTable" [showAllErrors]="showErrors">
              <column labelKey="invoice.shared.data.netAmount" property="netAmount" required type="number"></column>
              <ng-container *ngFor="let taxCategory of taxCategories">
                <column [title]="taxCategory.name + ' ' + ('invoice.shared.data.taxRate' | translate)">
                  <ng-template let-item="item" let-edition="edition">{{formatTax(item.taxes[taxCategory.id])}}</ng-template>
                </column>
                <column [title]="taxCategory.name + ' ' + ('invoice.shared.data.taxAmount' | translate)">
                  <ng-template let-item="item" let-edition="edition">{{formatTaxAmount(item, taxCategory)}}</ng-template>
                </column>
              </ng-container>
            </a-table>
          </form-row>
          <form-row *ngIf="invoice.interestsAmount" labelKey="invoice.shared.data.interestsAmount">
            <span class="bon-input" style="text-align: right">{{invoice.interestsAmount | number}}</span>
          </form-row>
          <form-row *ngIf="invoice.interestsAmount" labelKey="invoice.shared.data.grossAndInterestsAmount">
            <span class="bon-input" style="text-align: right">{{invoice.grossAndInterestsAmount | number}}</span>
          </form-row>
        </div>

        <div class="bon-card-inner bon-label-180">
          <date-row labelKey="invoice.shared.data.creationDate" [(ngModel)]="invoice.creationDate"
                    name="creationDate" [disabled]="true">
          </date-row>
          <date-row labelKey="invoice.shared.data.issueDate" [(ngModel)]="invoice.issueDate"
                    name="issueDate" [disabled]="true">
          </date-row>
          <date-row *ngIf="categoryId !== InvoiceCategory.BROKER" labelKey="invoice.shared.data.dueDate"
                    [(ngModel)]="invoice.dueDate"
                    name="dueDate" [disabled]="editionBlocked()">
          </date-row>
          <date-row labelKey="invoice.shared.data.dateFrom" [(ngModel)]="invoice.dateFrom" name="dateFrom"
                    disabled="true"></date-row>
          <date-row labelKey="invoice.shared.data.dateTo" [(ngModel)]="invoice.dateTo" name="dateTo"
                    disabled="true"></date-row>
          <date-row *ngIf="categoryId !== InvoiceCategory.BROKER || appProperties[AppProperty.INVOICE_BROKER_NOTE_PAYMENTS]"
                    labelKey="invoice.shared.data.clearingDate" [(ngModel)]="invoice.clearingDate" name="clearingDate"
                    disabled="true"></date-row>
          <dict-row *ngIf="this.categoryId == InvoiceCategory.INVOICE && appProperties[AppProperty.INVOICE_PAYMENT_TYPE]" label="Payment type" [(ngModel)]="invoice.paymentType"
                    dictionary="InvoicePaymentType" name="paymentType" itemLabel="name" [disabled]="editionBlocked()"></dict-row>
          <checkbox-row *ngIf="showIntermediaryCollectsPremium"
                        labelKey="invoice.shared.data.intermediaryCollectsPremium"
                        [(ngModel)]="invoice.intermediaryCollectsPremium"
                        (changeState)="intermediaryCollectionDateChanged($event)"
                        name="intermediaryCollectsPremium"></checkbox-row>
          <date-row *ngIf="invoice.intermediaryCollectsPremium" labelKey="invoice.shared.data.intermediaryCollectionDate"
                    [(ngModel)]="invoice.intermediaryCollectionDate" name="intermediaryCollectionDate"></date-row>
          <input-row labelKey="invoice.shared.data.contractNumber" [(ngModel)]="invoice.contractNumber"
                     name="contractNumber" disabled="true"></input-row>
          <input-row labelKey="invoice.shared.data.businessObjectNumber" [(ngModel)]="invoice.businessObjectNumber"
                     name="businessObjectNumber" disabled="true"></input-row>
          <checkbox-row *ngIf="categoryId === InvoiceCategory.INVOICE && loggedUserService.hasRight(ElementaryRight.ACCESS_MODULE_INTEREST)" labelKey="invoice.shared.data.calculateInterests"
                        [(ngModel)]="invoice.calculateInterests"
                        name="calculateInterests"
                        [disabled]="invoice.numberingBag && !invoice.numberingBag.calculateInterestsOverride"></checkbox-row>
          <checkbox-row *ngIf="categoryId === InvoiceCategory.INVOICE"
                        labelKey="invoice.shared.data.calculateBrokerProvision"
                        [(ngModel)]="invoice.calculateBrokerProvision"
                        name="calculateBrokerProvision" [disabled]="!serviceEditionInDraft()"></checkbox-row>
          <form-row *ngIf="!invoice.validatedBy" labelKey="invoice.shared.data.createdBy">
            <span class="bon-input">{{invoice.createdBy?.fullName}} - {{invoice.source?.name}}</span>
          </form-row>
          <form-row *ngIf="invoice.validatedBy" labelKey="invoice.shared.data.validatedBy">
            <span class="bon-input">{{invoice.validatedBy?.fullName}} - {{invoice.source?.name}}</span>
          </form-row>

        </div>
      </div>
    </bon-section>

    <bon-section labelKey="invoice.shared.data.positions">
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <a-table [items]="invoice.invoicePositions" [editable]="!editionBlocked()"
                   [formModel]="ngForm.form" groupName="positionsTable" [showAllErrors]="showErrors" (selectItem)="onSelectInvoicePosition($event)"
                   [addButton]="!editionBlocked()" (addUpdateDelete)="onAddUpdateDelete($event)"
                   (addItem)="onAddInvoicePosition($event)" (deleteButtonClick)="onDeleteInvoicePosition($event)"
                   [emitDeleteButton]="true" [deleteButton]="!editionBlocked()">
            <column labelKey="invoice.shared.data.position.number" property="positionNumber" required [editable]="false"></column>
            <column labelKey ="invoice.shared.data.position.title" property="title" required [editable]="!editionBlocked()"></column>
            <column labelKey="invoice.shared.data.position.netAmount" property="netAmount" required type="number" (cellChange)="onUnitPriceChange($event)"
                    [editable]="false"></column>
            <ng-container *ngFor="let taxCategory of taxCategories">
              <column [title]="taxCategory.name + ' ' + ('invoice.shared.data.position.taxRate' | translate)" [editable]="false">
                <ng-template let-item="item" let-edition="edition">{{formatTax(item.taxes[taxCategory.id])}}</ng-template>
              </column>
              <column [title]="taxCategory.name + ' ' + ('invoice.shared.data.position.taxAmount' | translate)" [editable]="false">
                <ng-template let-item="item" let-edition="edition">{{formatTaxAmount(item, taxCategory)}}</ng-template>
              </column>
            </ng-container>
          </a-table>
        </div>
      </div>
    </bon-section>

    <bon-section labelKey="invoice.shared.data.items" *ngIf="chosenInvoicePosition">
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <a-table [items]="chosenInvoicePosition.invoiceItems" [editable]="!editionBlocked()"
                   [addButton]="false" [deleteButton]="!editionBlocked()"
                   [formModel]="form" groupName="invoiceItemsTable" [showAllErrors]="showErrors"
                   (addUpdateDelete)="onItemAddUpdateDelete($event)"
                   [emitDeleteButton]="true" (deleteButtonClick)="onDeleteInvoiceItem($event)"
                   (selectItem)="onSelectInvoiceItem($event)">
            <column labelKey="invoice.shared.data.item.title" property="title" [link]="true" [editable]="!editionBlocked()"
                    (linkClick)="router.toInvoiceItemDetails(categoryId, $event.id)" cellWidth=""></column>
            <column labelKey="invoice.shared.data.item.client" property="client.registrationName" [link]="true"
                    (linkClick)="router.toCompanyPreview($event.client.id)" cellWidth=""></column>
            <column labelKey="invoice.shared.data.item.invoice" property="invoicePosition.invoice.number" cellWidth=""></column>
            <column labelKey="invoice.shared.data.item.businessObjectTypeAndId" (linkClick)="goToBusinessObject($event)" [link]="true">
              <ng-template let-item="item">
                {{item.businessObject?.relatedTo?.name}} / {{item.businessObject?.description}}
              </ng-template>
            </column>
            <column labelKey="invoice.shared.data.item.subtype" property="subtype" dictionary="InvoiceItemSubtype" [editable]="!editionBlocked()"></column>
            <column labelKey="invoice.shared.data.item.buyer.registrationName" property="buyer.registrationName" [link]="true"
                    (linkClick)="router.toCompanyPreview($event.buyer.id)"></column>
            <column labelKey="invoice.shared.data.item.buyer.id" property="buyer.id" [link]="true"
                    (linkClick)="router.toCompanyPreview($event.buyer.id)"></column>
            <column labelKey="invoice.shared.data.item.buyer.country" property="buyer.address.country.code"></column>
            <column labelKey="invoice.shared.data.item.netAmount" property="netAmount" type="number" [editable]="!editionBlocked()"></column>
            <ng-container *ngFor="let taxCategory of taxCategories">
              <column [title]="taxCategory.name + ' ' + ('invoice.shared.data.item.taxType' | translate)" [editable]="false">
                <ng-template let-item="item" let-edition="edition">{{formatTaxType(item, taxCategory)}}</ng-template>
              </column>
              <column [title]="taxCategory.name + ' ' + ('invoice.shared.data.item.taxRate' | translate)" [editable]="false">
                <ng-template let-item="item" let-edition="edition">{{formatTax(item.taxes[taxCategory.id])}}</ng-template>
              </column>
            </ng-container>
          </a-table>
        </div>
      </div>
    </bon-section>

    <bon-section labelKey="invoice.shared.data.history.section">
      <div *ngIf="!invoice.correctedInvoice" class="bon-card-group">
        <div class="bon-card-inner">
          <a-table [items]="invoice.correctingInvoices" [formModel]="ngForm.form" groupName="historyInvoicesTable"
                   [editable]="false" [showAllErrors]="showErrors" [footer]="true">
            <column labelKey="invoice.shared.data.history.number" sort="number" [link]="true" (linkClick)="router.toInvoiceDetails(categoryId, $event.id)">
              <ng-template let-item="item" let-edition="false">{{item.number ? item.number : 'invoice.shared.data.history.empty' | translate}}</ng-template>
            </column>
            <column labelKey="invoice.shared.data.history.correctionNumber" property="correctionNumber" initialSort="up"></column>
            <column labelKey="invoice.shared.data.history.netAmount" property="netAmount" required type="number" [sumInFooter]="true"></column>
          </a-table>
        </div>
      </div>
      <div *ngIf="invoice.invoiceType.id === InvoiceType.CREDIT_NOTE" class="bon-card-group">
        <div class="bon-card-inner">
          <a-table [items]="invoice.siblingCorrectingInvoices" [formModel]="ngForm.form" groupName="historyInvoicesTable"
                   [editable]="false" [showAllErrors]="showErrors" [footer]="true">
            <column labelKey="invoice.shared.data.history.number" sort="number" [link]="true" (linkClick)="router.toInvoiceDetails(categoryId, $event.id)">
              <ng-template let-item="item" let-edition="false">{{item.number ? item.number : 'invoice.shared.data.history.empty' | translate}}</ng-template>
            </column>
            <column labelKey="invoice.shared.data.history.correctionNumber" property="correctionNumber" initialSort="up"></column>
            <column labelKey="invoice.shared.data.history.netAmount" property="netAmount" required type="number" [sumInFooter]="true"></column>
          </a-table>
        </div>
      </div>
    </bon-section>

    <bon-section labelKey="invoice.shared.data.documents">
      <div class="bon-card-group" *ngIf="invoice.id">
        <div class="bon-card-inner">
          <document-list [relatedToId]="invoice.id" [relatedToType]="'invoice'" [excludedTypesIds]="[27000105]"
                         [documentLink]="!editionBlocked()" [hiddenColumns]="['relatedTo']"></document-list>
        </div>
      </div>
    </bon-section>

    <bon-section labelKey="invoice.shared.data.payments"
                 *ngIf="categoryId !== InvoiceCategory.BROKER || appProperties[AppProperty.INVOICE_BROKER_NOTE_PAYMENTS]">
      <div class="bon-card-group">
        <div class="bon-card-inner">
          <a-table [items]="filteredPayments" [formModel]="ngForm.form" groupName="paymentsTable"
                   [editable]="true" [addButton]="!appService.kuke" [deleteButton]="!appService.kuke"
                   [showAllErrors]="showErrors" [footer]="true"
                   (addItem)="onAddPayment($event)"
                   (deleteButtonClick)="onDeletePayment($event)" [emitDeleteButton]="true">
            <column labelKey="invoice.shared.data.payment.operationType" property="operationType" dictionary="InvoicePaymentOperationType" [editable]="!appService.kuke"></column>
            <column labelKey="invoice.shared.data.payment.financialSystemDate" property="financialSystemDate" type="date" required [editable]="!appService.kuke"></column>
            <column labelKey="invoice.shared.data.payment.correctedDate" property="correctedDate"  type="date"></column>
            <column labelKey="invoice.shared.data.payment.amount" property="amount" type="number" required [sumInFooter]="true" [allowNegativeNumbers]="true" [editable]="!appService.kuke"></column>
          </a-table>
        </div>
      </div>
    </bon-section>

  </div>
</form>
