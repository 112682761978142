import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  AppConfigService,
  ATableComponent,
  ComboItem,
  DeclarationDto,
  DeclarationEntryDto,
  DeclarationEntryNNDto,
  DeclarationEntryStubDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  DictionaryService,
  GrowlService,
  LoggedUserService,
  SearchResult,
} from '../../../../bonding_shared';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {CustomButton, CustomButtonEvent} from '../../../../bonding_shared/components/aku-table/table.component';
import {Currency, Frequency, PolicyContractType} from '../../../../bonding_shared/model/dictionary-ids';
import * as moment from 'moment';
import {ControlContainer, NgForm} from '@angular/forms';
import {DeclarationService} from '../../../../bonding_shared/services/declaration.service';
import {TranslateService} from '@ngx-translate/core';
import {DeclarationAbstractSection} from './declaration-abstract-section';

export class SessionEntries {
  // entries managed by user in current 'session'
  // {entry.id, entry}
  persistedMap: Map<number, DeclarationEntryStubDto>;
  // entry.id is undefined => {limitDecision.id, {currency.id (or undefined), entry}}
  transientMap: Map<number, Map<number, DeclarationEntryStubDto>>;

  generalNNs: DeclarationEntryNNDto[];
  domesticNNs: DeclarationEntryNNDto[];
  exportNNs: DeclarationEntryNNDto[];

  private warning = false;

  constructor() {
    this.persistedMap = new Map<number, DeclarationEntryStubDto>();
    this.transientMap = new Map<number, Map<number, DeclarationEntryStubDto>>();
  }

  public isWarningRequired() {
    return this.warning;
  }
}

@Component({
  selector: 'declaration-section',
  templateUrl: './declaration-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class DeclarationSectionComponent extends DeclarationAbstractSection implements OnInit {
  @ViewChild('declarationEntriesTable') declarationEntriesTable: ATableComponent<DeclarationEntryDto>;
  @Output() pageChange = new EventEmitter<PageChangedEvent>();

  sessionEntries: SessionEntries;

  pce: PageChangedEvent = <PageChangedEvent>{page: 1, itemsPerPage: 20};
  pageSize = '20';
  pageSizes: ComboItem[] = [new ComboItem('20', '20'), new ComboItem('50', '50'), new ComboItem('100', '100')];
  totalCount = 0;

  currencyMap: Map<number, DictionaryBaseDto>;
  valueEditableMap: Map<string, boolean>;
  currencyEditableMap: Map<string, boolean>;

  customButtons: CustomButton[];

  @Input() set declarationSearchResult(decSR: SearchResult<DeclarationDto>) {
    this.selectedCurrency = undefined;
    if (decSR && decSR.result) {
      const mergedEntries: DeclarationEntryDto[] = []; // entries currently shown on screen and managed by user
      const transientEntriesAlreadyMappedByLDId = new Set(this.sessionEntries.transientMap.keys());
      const alreadyMergedLimits = new Set<number>();
      this.setDeclaration(decSR.result[0]);

      // usunięcie możliwości duplikwania wierszy dla korekt
      // - zdaje się, że reguła była błednie dodana z zadaniem IKI-1694
      // i nie powinna obowiązywać dla intranetu dla produktów z multiwalutowością

      // if (this.declaration && this.declaration.amendment) {
      //   this.customButtons = [];
      // } else {
      this.initializeCustomButtons();
      // }
      this.totalCount = decSR.size;

      // 'initial run' => merge all transient entries for witch limit decisions were previously mapped
      this.declaration.entries.forEach((decEntry) => {
        const decEntryLdKey = decEntry.limitDecision.id;
        if (transientEntriesAlreadyMappedByLDId.has(decEntryLdKey)) {
          // delete from set - avoids merging transient entries several times when there is more than one entry same limit decision id
          transientEntriesAlreadyMappedByLDId.delete(decEntryLdKey);
          alreadyMergedLimits.add(decEntryLdKey);
          // all currencies for one limitDecision.id
          this.sessionEntries.transientMap
            .get(decEntryLdKey)
            .forEach((elem) => mergedEntries.push(this.createEntryBasedOnExistingEntry(decEntry, elem)));
        }
      });

      // 'main run' => merge all entries from backend (+ their values from map) and put them into map (transient or persisted)
      this.declaration.entries.forEach((decEntry) => {
        const simpleEntry = DeclarationSectionComponent.entryToSimpleEntry(decEntry);
        const persisted = decEntry.id;
        const decEntryLdKey = decEntry.limitDecision.id;
        const decEntryCurrKey = decEntry.currency ? decEntry.currency.id : undefined;

        if (persisted) {
          // persisted entries
          if (!this.sessionEntries.persistedMap.has(decEntry.id)) {
            // no entry for entry.id in persisted map => put into map and push into merged entries
            this.sessionEntries.persistedMap.set(decEntry.id, simpleEntry);
            mergedEntries.push(decEntry);
          } else {
            // there already is entry for entry.id in persisted map => push into merged entries entry with overridden values
            mergedEntries.push(this.overrideEntryParams(decEntry, this.sessionEntries.persistedMap.get(decEntry.id)));
          }
        } else {
          // transient entries
          if (!this.sessionEntries.transientMap.has(decEntryLdKey)) {
            // no entry for ld.id in transient map => put into transient map (if currency) and push into merged entries
            if (decEntryCurrKey) {
              this.sessionEntries.transientMap.set(decEntryLdKey, new Map<number, DeclarationEntryStubDto>());
              this.sessionEntries.transientMap.get(decEntryLdKey).set(decEntryCurrKey, simpleEntry);
            }
            mergedEntries.push(decEntry);
          } else if (!alreadyMergedLimits.has(decEntryLdKey) && decEntryCurrKey) {
            // there is entry for ld.id in transient map AND it wasn't alreadyMerged in 'initial run' AND has currency
            // => put into transient map and push into merged entries
            this.sessionEntries.transientMap.get(decEntryLdKey).set(decEntryCurrKey, simpleEntry);
            mergedEntries.push(decEntry);
          }
        }
      });
      this.declaration.entries = mergedEntries.sort((e1, e2) => DeclarationSectionComponent.entriesComparator(e1, e2));
      this.initializeSessionNNEntries(decSR.result[0]);
      this.updateEditableMaps();
    } else {
      this.setDeclaration(undefined);
    }
  }

  @Input() set policyContractTypeId(type: PolicyContractType) {
    this._policyContractTypeId = type;
    if (type) {
      this.declarationService.isProductDependentOnPremiumRate(type).subscribe({
        next: (dependent) => (this.productDependentOnPremiumRate = dependent),
      });
    } else {
      this.productDependentOnPremiumRate = false;
    }
  }

  get policyContractTypeId(): PolicyContractType {
    return this._policyContractTypeId;
  }

  _policyContractTypeId: PolicyContractType;
  productDependentOnPremiumRate: boolean;

  readonly Frequency = Frequency;

  private static entriesComparator(e1: DeclarationEntryDto, e2: DeclarationEntryDto): -1 | 0 | 1 {
    if (this.onlyOneIsNull(e1.buyer, e2.buyer)) {
      return this.nullsLast(e1.buyer, e2.buyer);
    }
    if (e1.buyer.countryCode > e2.buyer.countryCode) {
      return 1;
    }
    if (e1.buyer.countryCode < e2.buyer.countryCode) {
      return -1;
    }
    if (e1.buyer.id > e2.buyer.id) {
      return 1;
    }
    if (e1.buyer.id < e2.buyer.id) {
      return -1;
    }
    if (this.onlyOneIsNull(e1.currency, e2.currency)) {
      return this.nullsLast(e1.currency, e2.currency);
    }
    if (e1.currency.id > e2.currency.id) {
      return 1;
    }
    if (e1.currency.id <= e2.currency.id) {
      return 1;
    }
    if (e1.premiumRateFrom > e2.premiumRateFrom) {
      return 1;
    }
    if (e1.premiumRateFrom < e2.premiumRateFrom) {
      return -1;
    }
    return 0;
  }

  private static onlyOneIsNull(first: any, second: any): boolean {
    return (first && !second) || (!first && second);
  }

  private static nullsLast(first: any, second: any): -1 | 0 | 1 {
    if (!first) {
      return !second ? 0 : -1;
    } else if (!second) {
      return 1;
    }
  }

  constructor(
    protected dictService: DictionaryBaseService,
    protected dictGlobalService: DictionaryService,
    protected growlService: GrowlService,
    protected appService: AppConfigService,
    protected loggedUserService: LoggedUserService,
    protected translateService: TranslateService,
    protected declarationService: DeclarationService
  ) {
    super(
      dictService,
      dictGlobalService,
      growlService,
      appService,
      loggedUserService,
      translateService,
      declarationService
    );
    this.currencyMap = new Map<number, DictionaryBaseDto>();
    this.dictService.getDictionaryBase('Currency').subscribe((currencies) => {
      currencies.forEach((currency) => {
        this.currencyMap.set(currency.id, currency);
      });
    });
    this.initializeCustomButtons();
  }

  private static entryToSimpleEntry(entry: DeclarationEntryDto): DeclarationEntryStubDto {
    return <DeclarationEntryStubDto>{
      id: entry.id,
      version: entry.version,
      buyerId: entry.buyer ? entry.buyer.id : undefined,
      limitDecisionId: entry.limitDecision ? entry.limitDecision.id : undefined,
      premiumRateFrom: entry.premiumRateFrom,
      value: entry.value,
      maxDeclaredValue: entry.maxDeclaredValue,
      currencyId: entry.currency ? entry.currency.id : undefined,
      maxPaymentTermDays: entry.maxPaymentTermDays,
      paymentTermDays: entry.paymentTermDays,
    };
  }

  ngOnInit(): void {
    this.declarationService.getValidNNRoles().subscribe({
      next: (validNNRoles) => (this.validNNRoles = validNNRoles),
    });
  }

  get outstandings(): boolean {
    return this.policyContractTypeId === PolicyContractType.KUKE_KOM_SP;
  }

  initializeCustomButtons() {
    this.customButtons = [
      <CustomButton>{
        title: this.appService.kuke ? 'Duplikuj deklarację pod inną walutę' : 'Duplicate declaration',
        class: 'bon-btn-warning fa fa-clone',
      },
    ];
  }

  duplicateDeclarationEntry(event: CustomButtonEvent<DeclarationEntryDto>) {
    const clone = <DeclarationEntryDto>{
      version: 0,
      buyer: event.item.buyer,
      buyerClientNumber: event.item.buyerClientNumber,
      limitDecision: event.item.limitDecision,
      premiumRateFrom: event.item.premiumRateFrom,
      maxPaymentTermDays: event.item.maxPaymentTermDays !== undefined ? event.item.maxPaymentTermDays : undefined,
      duplicatedEntryTransientFlag: true,
    };
    this.declarationEntriesTable.insertItem(
      clone,
      this.declaration.entries.findIndex((entry) => entry.limitDecision.id === clone.limitDecision.id)
    );
  }

  entryDeletionVisible(decEntry: DeclarationEntryDto): boolean {
    return decEntry?.duplicatedEntryTransientFlag && !decEntry?.value && !decEntry?.currency;
  }

  anyRowElementEditable(decEntry: DeclarationEntryDto): boolean {
    return this.rowValueEditable(decEntry) || this.rowCurrencyEditable(decEntry);
  }

  private overrideEntryParams(entry: DeclarationEntryDto, valueFromMap: DeclarationEntryStubDto) {
    const newEntry: DeclarationEntryDto = <DeclarationEntryDto>{
      id: entry.id,
      version: entry.version,
      limitDecision: entry.limitDecision,
      premiumRateFrom: entry.premiumRateFrom,
      buyer: entry.buyer,
      buyerClientNumber: entry.buyerClientNumber,
      updateDate: entry.updateDate,
      maxPaymentTermDays: entry.maxPaymentTermDays,
      paymentTermDays: entry.paymentTermDays,
    };

    const currId = valueFromMap.currencyId;
    newEntry.value = valueFromMap.value;
    newEntry.maxDeclaredValue = valueFromMap.maxDeclaredValue;
    newEntry.currency = <DictionaryBaseDto>{
      id: currId,
      code: currId ? this.currencyMap.get(currId).code : undefined,
    };
    return newEntry;
  }

  private createEntryBasedOnExistingEntry(entry: DeclarationEntryDto, valueFromMap: DeclarationEntryStubDto) {
    const newEntry: DeclarationEntryDto = <DeclarationEntryDto>{
      id: undefined,
      version: entry.version ? entry.version : 0,
      limitDecision: entry.limitDecision,
      premiumRateFrom: entry.premiumRateFrom,
      buyer: entry.buyer,
      buyerClientNumber: entry.buyerClientNumber,
      updateDate: entry.updateDate,
      maxPaymentTermDays: entry.maxPaymentTermDays,
      paymentTermDays: entry.paymentTermDays,
    };

    const currId = valueFromMap.currencyId;
    newEntry.value = valueFromMap.value;
    newEntry.maxDeclaredValue = valueFromMap.maxDeclaredValue;
    newEntry.currency = <DictionaryBaseDto>{
      id: currId,
      code: currId ? this.currencyMap.get(currId).code : undefined,
    };
    return newEntry;
  }

  cancelRowEdition() {
    this.blockPagination = false;
    this.declarationEntryUnselect.emit();
    this.blockDomesticNNEntriesEdition = false;
    this.blockExportNNEntriesEdition = false;
    this.blockGeneralNNEntriesEdition = false;
  }

  onDone(decEntry: DeclarationEntryDto) {
    if (!decEntry.currency && !decEntry.duplicatedEntryTransientFlag) {
      // nie wykonuj metody onDone dla błednego wpisu!
      // za to wykonaj dla takiego który pochodzi z duplikacji wpisu
      // serwer sobie takiego śmiecia ogarnie
      // -- brak waluty => wróci error
      // -- brak waluty i kwoty => usunie się taki wpis
      return;
    }
    const sameEntriesCount = this.declaration.entries.filter(
      (entry) =>
        entry.limitDecision.id === decEntry.limitDecision.id &&
        entry.currency &&
        decEntry.currency &&
        entry.currency.id === decEntry.currency.id &&
        this.appService.kuke
    ).length;

    if (sameEntriesCount > 1) {
      if (this.appService.kuke) {
        // prevents insertion of two entries for the same limit decision with the same currency
        this.growlService.error('Declaration entry with currency ' + decEntry.currency.name + ' already provided!');
        // in such case currently edited entry currency will be set to previously selected currency
        decEntry.currency = this.selectedCurrency;
      }
    } else {
      if (!decEntry.id) {
        // change any parameter of NOT persisted entry
        if (!this.sessionEntries.transientMap.has(decEntry.limitDecision.id)) {
          // initialize inner map
          this.sessionEntries.transientMap.set(decEntry.limitDecision.id, new Map<number, DeclarationEntryStubDto>());
        }
        const transientEntryInnerMap = this.sessionEntries.transientMap.get(decEntry.limitDecision.id);
        if (transientEntryInnerMap.has(this.selectedCurrency && this.selectedCurrency.id)) {
          // if currency changed, delete entry already mapped by old currency
          transientEntryInnerMap.delete(this.selectedCurrency && this.selectedCurrency.id);
        }
        transientEntryInnerMap.set(
          decEntry.currency && decEntry.currency.id,
          DeclarationSectionComponent.entryToSimpleEntry(decEntry)
        );
      }
    }
    if (decEntry.id) {
      // set entry when any parameter of already persisted entry changed
      this.sessionEntries.persistedMap.set(decEntry.id, DeclarationSectionComponent.entryToSimpleEntry(decEntry));
    }
    this.blockPagination = false;
    this.declarationEntryUnselect.emit();
    this.blockGeneralNNEntriesEdition = false;
    this.blockDomesticNNEntriesEdition = false;
    this.blockExportNNEntriesEdition = false;
  }

  onPageChange(event: PageChangedEvent) {
    this.selectedCurrency = undefined;
    this.pce.page = event.page; // do not rewrite itemsPerPage due it is sometimes items total count!
    this.pageChange.emit(this.pce);
  }

  onChangePageSize(event: any) {
    this.resetPageToFirst(true);
  }

  resetPageToFirst(reloadDeclaration = false) {
    // if reloadDeclaration == true then declaration will by reloaded by backend call
    this.selectedCurrency = undefined;
    this.pce = <PageChangedEvent>{page: 1, itemsPerPage: parseInt(this.pageSize, 10)};
    if (this.declarationEntriesTable) {
      this.declarationEntriesTable.forceChangePage(this.pce);
    }
    if (reloadDeclaration) {
      this.pageChange.emit(this.pce);
    }
  }

  onSelectDeclarationEntry(entry: DeclarationEntryDto) {
    if (
      entry &&
      !this.preview &&
      !this.blockEntriesEdition &&
      (this.rowValueEditable(entry) || this.rowCurrencyEditable(entry))
    ) {
      this.declarationEntrySelect.emit(entry);
      this.selectedCurrency = entry ? entry.currency : undefined;
      this.blockPagination = true;
      this.blockGeneralNNEntriesEdition = true;
      this.blockDomesticNNEntriesEdition = true;
      this.blockExportNNEntriesEdition = true;
    }
  }

  protected updateEditableMaps() {
    super.updateEditableMaps();
    if (this.declaration && this.declaration.entries) {
      this.valueEditableMap = new Map();
      this.currencyEditableMap = new Map();
      this.declaration.entries.forEach((de) => {
        const ldCuKey = this.stringifyDeclarationEntry(de);
        this.valueEditableMap.set(ldCuKey, this.valueEditable(de));
        this.currencyEditableMap.set(ldCuKey, this.currencyEditable(de));
      });
    } else {
      this.valueEditableMap = new Map();
      this.currencyEditableMap = new Map();
    }
  }

  private stringifyDeclarationEntry(de: DeclarationEntryDto) {
    return JSON.stringify({
      ldId: de.limitDecision ? de.limitDecision.id : undefined,
      cuId: de.currency ? de.currency.id : undefined,
    });
  }

  private valueEditable(decEntry: DeclarationEntryDto): boolean {
    return !this.editionErrorMsg(decEntry);
  }

  currencyRequired(decEntry: DeclarationEntryDto): boolean {
    return decEntry.duplicatedEntryTransientFlag !== true;
  }

  private editionErrorMsg(decEntry: DeclarationEntryDto): string {
    // ten warunek trzeba zmienić
    let errorMsg;
    const editable =
      decEntry.duplicatedEntryTransientFlag ||
      !this.portal ||
      (this.portal &&
        this.declaration !== undefined &&
        this.paymentDateInFuture(decEntry) &&
        ((!this.declaration.amendment && !this.preview) || (this.declaration.amendment && !!decEntry.value)));
    if (!editable) {
      if (!decEntry.value) {
        errorMsg = 'declaration.section.emptyValue';
      } else if (!this.paymentDateInFuture(decEntry)) {
        this.translateService
          .get('declaration.section.paymentDateInFuture', {
            paymentDate: this.paymentDate(decEntry).toLocaleDateString(),
          })
          .subscribe((transMsg) => (errorMsg = transMsg));
      }
    }
    return errorMsg;
  }

  protected currencyEditable(decEntry: DeclarationEntryDto): boolean {
    return (
      decEntry.duplicatedEntryTransientFlag ||
      !this.portal ||
      (this.portal &&
        decEntry.maxDeclaredValue === undefined &&
        this.paymentDateInFuture(decEntry) &&
        (this.declaration === undefined || (this.declaration && !this.declaration.amendment)))
    );
  }

  rowValueEditable(decEntry: DeclarationEntryDto): boolean {
    if (!this.portal || (decEntry && decEntry.duplicatedEntryTransientFlag)) {
      return true;
    }
    if (this.declaration.amendment) {
      // !(zero or null value) AND decEntry value was initially editable
      return decEntry && !!decEntry.value && this.valueEditableMap.get(this.stringifyDeclarationEntry(decEntry));
    } else {
      return true;
    }
  }

  customButtonVisible(decEntry: DeclarationEntryDto): boolean {
    if (!this.multiCurrencyDeclaration) {
      return false;
    }
    if (!this.portal || decEntry.duplicatedEntryTransientFlag) {
      return true;
    }
    if (this.declaration.amendment) {
      // !(zero or null value) AND decEntry currency was initially editable
      return !!decEntry.value && this.valueEditableMap.get(this.stringifyDeclarationEntry(decEntry));
    } else {
      return true;
    }
  }

  rowCurrencyEditable(decEntry: DeclarationEntryDto): boolean {
    if (!this.multiCurrencyDeclaration) {
      return false;
    }
    if (!this.portal || decEntry.duplicatedEntryTransientFlag) {
      return true;
    }
    if (this.declaration.amendment) {
      // !(zero or null value) AND decEntry currency was initially editable
      return !!decEntry.value && this.currencyEditableMap.get(this.stringifyDeclarationEntry(decEntry));
    } else {
      return true;
    }
  }

  private paymentMoment(decEntry: DeclarationEntryDto) {
    let turnoverNotificationDays;
    switch (this.declaration.reportingPeriod.policyContractData.turnoverNotification.id) {
      case Frequency.MONTHLY:
        turnoverNotificationDays = 30;
        break;
      case Frequency.QUARTERLY:
        turnoverNotificationDays = 90;
        break;
      case Frequency.HALF_A_YEAR:
        turnoverNotificationDays = 180;
        break;
      case Frequency.YEARLY:
        turnoverNotificationDays = 365;
        break;
      default:
        turnoverNotificationDays = 0;
        break;
    }

    return moment(this.declaration.reportingPeriod.reportingTo).add(
      decEntry.maxPaymentTermDays
        ? decEntry.maxPaymentTermDays + turnoverNotificationDays
        : 0 + turnoverNotificationDays,
      'days'
    );
  }
  paymentDate(decEntry: DeclarationEntryDto): Date {
    return this.paymentMoment(decEntry).toDate();
  }
  paymentDateString(decEntry: DeclarationEntryDto): string {
    return this.paymentMoment(decEntry).toDate().toLocaleDateString();
  }
  paymentDateInFuture(decEntry: DeclarationEntryDto): boolean {
    return this.paymentMoment(decEntry).add(1, 'day').isAfter(new Date());
  }

  tooltip(decEntry: DeclarationEntryDto): string {
    return this.editionErrorMsg(decEntry);
  }

  tooltipVisible(decEntry: DeclarationEntryDto): boolean {
    return !this.declaration?.id && !this.rowValueEditable(decEntry);
  }

  public set blockPagination(block: boolean) {
    if (this.declarationEntriesTable) {
      this.declarationEntriesTable.inProgress = block;
    }
  }

  private initializeSessionNNEntries(source: DeclarationDto) {
    if (source.nn) {
      if (this.isValidNNRole(this.DOMESTIC_NN)) {
        if (source.domesticNNs.length > 0 && this.sessionEntries.domesticNNs === undefined) {
          this.sessionEntries.domesticNNs = Array.from(source.domesticNNs);
        }
        if (this.sessionEntries.domesticNNs === undefined) {
          this.sessionEntries.domesticNNs = [];
        }
      }
      if (this.isValidNNRole(this.EXPORT_NN)) {
        if (source.exportNNs.length > 0 && this.sessionEntries.exportNNs === undefined) {
          this.sessionEntries.exportNNs = Array.from(source.exportNNs);
        }
        if (this.sessionEntries.exportNNs === undefined) {
          this.sessionEntries.exportNNs = [];
        }
      }
      if (this.isValidNNRole(this.GENERAL_NN)) {
        if (source.generalNNs.length > 0 && this.sessionEntries.generalNNs === undefined) {
          this.sessionEntries.generalNNs = Array.from(source.generalNNs);
        }
        if (this.sessionEntries.generalNNs === undefined) {
          this.sessionEntries.generalNNs = [];
        }
      }
    }
  }

  getGeneralNNs(): DeclarationEntryNNDto[] {
    return this.sessionEntries.generalNNs;
  }
  getDomesticNNs(): DeclarationEntryNNDto[] {
    return this.sessionEntries.domesticNNs;
  }
  getExportNNs(): DeclarationEntryNNDto[] {
    return this.sessionEntries.exportNNs;
  }
}
