import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  CompanyGroupVersionDto,
  AppConfigService,
  CompanyWithExposureDto,
  RouterService,
  CompanySimpleDto,
  ConfirmDialogComponent,
  CompanyBaseDto,
} from '../../../bonding_shared';
import {Subject} from 'rxjs';
import {BusinessUtils} from '../../../bonding_shared/utils/business-utils';

@Component({
  selector: 'group-member-list',
  templateUrl: 'group-member-list.component.html',
})
export class GroupMemberListComponent {
  @Input() group: CompanyGroupVersionDto;
  @Input() deleteButton = false;
  @Input() groupEdition = false;

  @Input() addMemberEventEmitter: Subject<boolean>;

  @ViewChild('memberRemovalConfirm', {static: true}) memberRemovalConfirmDialog: ConfirmDialogComponent;

  self = this;

  public get credendo(): boolean {
    return this.appConfigService.credendo;
  }

  public get kuke(): boolean {
    return this.appConfigService.kuke;
  }

  constructor(private routerService: RouterService, private appConfigService: AppConfigService) {}

  gotoCompanyPreview(groupCompany: CompanyWithExposureDto) {
    this.routerService.toCompanyPreview(groupCompany.id);
  }

  rowDeletable(item: CompanyWithExposureDto): boolean {
    return !item.parentOfBranch;
  }

  onDeleteItem(item: CompanyWithExposureDto) {
    const companiesFromTree = this.group.groupCompanies.filter((c) => this.getRoot(c).id === item.id).map((c) => c.id);
    if (companiesFromTree.length > 1) {
      this.memberRemovalConfirmDialog.openAndExecuteOnConfirm(
        'group.details.removingCompanyPopup.title',
        'group.details.removingCompanyPopup.message',
        this.onDeleteItemConfirmed.bind(this),
        undefined,
        undefined,
        [item]
      );
    } else {
      this.onDeleteItemConfirmed([item]);
    }
  }

  onDeleteItemConfirmed(items: CompanyWithExposureDto[]) {
    // here items has always one element
    const root = items[0];
    const companiesFromTree = this.group.groupCompanies.filter((c) => this.getRoot(c).id === root.id).map((c) => c.id);
    const remainingCompanies = this.group.groupCompanies.filter((c) => !companiesFromTree.find((id) => id === c.id));
    this.group.groupCompanies.length = 0; // clear array
    remainingCompanies.forEach((c) => this.group.groupCompanies.push(c));

    if (this.group.groupHead && !this.group.groupCompanies.find((c) => c.id === this.group.groupHead.id)) {
      this.group.groupHead = undefined;
    }
  }

  getRoot(c: CompanySimpleDto | CompanyWithExposureDto | CompanyBaseDto) {
    return !c.parentOfBranch ? c : this.getRoot(c.parentOfBranch);
  }

  public prettyCompanyAddress(company: CompanyWithExposureDto): string {
    return BusinessUtils.prettyAddress(company);
  }
}
