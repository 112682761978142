import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService} from '../../../../bonding_shared/services';
import {TranslateService} from '@ngx-translate/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {DictionaryBaseDto} from '../../../../bonding_shared';
import {DictionaryProfile, FieldValueType, LimitedLiability} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'indemnification-section',
  templateUrl: './indemnification-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class IndemnificationSectionComponent extends Section {
  self = this;
  readonly DictionaryProfile = DictionaryProfile;
  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected translateService: TranslateService
  ) {
    super(policyContractViewService, appService);
  }

  updateRiskThresholdValidity() {
    if (this.policyForm.get('riskThreshold')) {
      this.policyForm.get('riskThreshold').updateValueAndValidity();
    }
  }

  globalLOLAmount(): boolean {
    return this.policy.globalLOLType && this.policy.globalLOLType.id === FieldValueType.AMOUNT;
  }

  globalLOLTypeChanged() {
    if (this.globalLOLAmount()) {
      this.policy.globalLOLMultiplicity = undefined;
    } else {
      this.policy.globalLOL = undefined;
      this.policy.globalLOLCurrency = undefined;
    }
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }

  public get ecg(): boolean {
    return this.appService.ecg;
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }
}
